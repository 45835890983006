import React, { Component } from 'react';

import { flatten } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';

import LinksList from '../LinksList';
import ActionButton from 'components/action/ActionButton';

export const LinksTab = (props: any) => {
	const { t } = useTranslation();

	const targetLinks =
		props.selection && props.stakeholders
			? flatten(props.stakeholders.map((c) => c.links)).filter((l) => {
				return l.targetId == props.selection.id;
			})
			: [];

	return (
		<>
			<div className="d-flex flex-column flex-grow mt-3">
				<div>
					<div className="text-left">
						{t('models.item.fields.links.outcoming.name')}
					</div>
					<LinksList
						onDelete={
							props.scenario
								? null
								: (id: string) =>
									props.onDeleteStakeholderLink(
										props.selection.id,
										id
									)
						}
						onCycleStrength={
							props.scenario
								? null
								: props.onChangeStakeholderLinkStrength
						}
						items={
							props.indexedStakeholders &&
								props.indexedStakeholders[props.selection.id]
								? props.indexedStakeholders[
									props.selection.id
								].links.map((l: any) => {
									return {
										...l,
										name: props.indexedStakeholders[
											l.targetId
										].name
									};
								})
								: []
						}
					></LinksList>
					{props.indexedStakeholders &&
						props.indexedStakeholders[props.selection.id]
						? props.indexedStakeholders[props.selection.id].links
							.length == 0 && (
							<div
								style={{ color: 'rgba(46, 160, 223, 0.3)' }}
							>
								{t('models.item_link.no_x')}
							</div>
						)
						: null}

					<div className="text-left mt-4">
						{t('models.item.fields.links.incoming.name')}
					</div>
					<LinksList
						onCycleStrength={
							props.scenario
								? null
								: props.onChangeStakeholderLinkStrength
						}
						items={targetLinks.map((l: any) => {
							return {
								...l,
								name: props.indexedStakeholders[l.originId].name
							};
						})}
					></LinksList>

					{targetLinks.length == 0 && (
						<div style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
							{t('models.item_link.no_x')}
						</div>
					)}
				</div>

				{!props.scenario && (
					<div className="Actions mt-4">
						<ActionButton
							tooltip={t('models.item_link.actions.create_new')}
							className="createStakeholderLink small"
							onClick={props.onCreateLink}
						>
							<FontAwesomeIcon icon={faLink} />
						</ActionButton>
					</div>
				)}
			</div>
		</>
	);
};

export default LinksTab;
