export type IndicatorModel = {
	id: string;
	name: string;
	reference: string;
	description: string;
	weight: number;
	trend: string;
	createdAt: string;
	evaluatedAt: string;
};

export const defaults = {
	__typename: 'Indicator',
	reference: '',
	weight: 1,
	trend: '',
	description: '',
	evaluatedAt: '',
	scenarios: [],
	createdAt: '2025-02-11T14:26:13.594Z'
};
