import { useTranslation } from 'react-i18next';

import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Tooltip } from 'antd';
import { FishboneBranchPopover } from './FishboneBranchPopover';
import { FishbonePoint } from './FishbonePoint';
import { sumBy, range } from 'lodash';


import { colorToPercent, getScoreColor } from 'utils/ranking';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import './FishboneBranch.sass';

dayjs.extend(relativeTime)

export const FishboneBranchContent = (props: any) => {

	const { t } = useTranslation();

	const startY = props.position == 'bottom' ? props.height : 0;
	const startX = 0;
	const pinRadius = 16;

	const angle = Math.atan(props.height / props.width);

	const lineModifierY = !props.odd ? 0 : props.position == "bottom" ? startY : -startY;

	// Compute lineModifierX based on lineModifierY and angle
	const lineModifierX = (props.position == "bottom" ? -1 : 1) * Math.cos(angle) * lineModifierY;

	const y = startY;

	const branchLength = Math.sqrt(
		(props.height - y) * (props.height - y) + props.width * props.width
	);

	const allPoints = props.points || [];
	//sortBy(props.points || [], (p) => (p && p.completion) || 0).reverse()
	const segmentSize = branchLength / allPoints.length;

	//props.debug && console.log('segmentSize', segmentSize);

	let pointsWeight = 0;
	allPoints.forEach((p) => (pointsWeight += p.weight || 1));

	const vulnerability =
		allPoints && allPoints.length > 0
			? sumBy(
				allPoints,
				(p) =>
					colorToPercent(p.trend !== null ? p.trend : '50') *
					(p.weight || 1)
			) / pointsWeight
			: 50;

	//console.log('FishboneBranch pointsWeight', pointsWeight);
	//console.log('FishboneBranch vulnerability', vulnerability);

	const points = allPoints.map((p, index) => {

		const segmentWidth = props.width / allPoints.length;
		const segmentHeight = props.height / allPoints.length;

		const startY = props.position == 'bottom' ? props.height : 0;
		const posX = segmentWidth * index;
		const posY = (props.position == 'bottom' ? -1 : 1) * (segmentHeight * index)

		return (
			<FishbonePoint
				key={p.id}
				{...p}
				x={posX}
				y={posY + startY}
				isMatching={props.matchingIndexedItems[p.id]}

				size={0.15 * segmentSize}
				onUpdate={props.onUpdateItem}
				onEdit={props.onEditItem}
			></FishbonePoint>
		);
	});

	return (
		<g
			className="FishboneBranchContent"
		>
			{false && <rect x={0} y={0} width={props.width} height={props.height} fill="none" stroke="#FFFF0088"></rect>}

			<g className="points">{points}</g>


		</g>
	);
};
