import { RiskIndicatorImpactModel } from './RiskIndicatorImpactModel';
import { RiskScenarionSimulationModel } from './RiskScenarioSimulation';

export type RiskModel = {
	id: string;
	name: string;
	description: string;
	impact: number;
	likelihood: number;
	mitigation: string;
	createdAt: Date;
	updatedAt: Date;
	scenarios: RiskScenarionSimulationModel[];

	impactedIndicators: RiskIndicatorImpactModel[];
};

export const defaults = {
	__typename: 'Risk',
	reference: '',
	impact: 1,
	likelihood: 1,
	riskCategoryId: '',
	matchingOpportunityId: '',
	isOpportunity: false,
	description: '',
	evaluatedAt: ''
};
