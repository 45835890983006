import React from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';

// Cette fonction wrapping supprime l'avertissement pour defaultProps
export const FixedImpulseSpinner = ({
  size = 30,
  frontColor = "#00ff89",
  backColor = "#4b4c56",
  loading = true,
  ...props
}) => {
  if (!loading) return null;
  
  return (
    <ImpulseSpinner 
      size={size}
      frontColor={frontColor}
      backColor={backColor}
      loading={loading}
      {...props}
    />
  );
};

export default FixedImpulseSpinner;