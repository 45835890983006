import React, { Component, useEffect, useState } from 'react';

import { keys, isObject } from 'lodash';

import { Modal, Button, Form, Input, Slider, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const ConstraintEditionModal = (props: any) => {
	const [form] = Form.useForm();
	const [areTooltipVisibles, setAreTooltipVisibles] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setTimeout(() => {
			setAreTooltipVisibles(true);
		}, 400)
	}, []);

	const handleFormSubmit = () => {
		form.validateFields()
			.then((values) => {
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			{props.onDelete && (
				<Button
					onClick={() => {
						props.onDelete(props);
					}}
					danger={true}
					style={{ marginRight: 'auto' }}
				>
					Delete
				</Button>
			)}
			<Button
				onClick={() => {
					props.onHide();
				}}
				style={{ marginRight: 'auto' }}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			open={true}
			width={props.width || "70%"}
			title={props.id ? 'Indicator edition' : 'Create a new indicator'}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={props.values}
			>
				<div className='d-flex w-100'>
					<Form.Item
						name="reference"
						label="Reference"
						className="mr-1"
						style={{ width: '20%' }}
						rules={[
							{
								max: 5,
								message:
									t("messages.form.max_length_exceeded_error", { length: 5 })
							}
						]}
					>
						<Input
							autoComplete='off'
							count={{
								show: true,
								max: 5,
							}}></Input>
					</Form.Item>
					<Form.Item
						name="name"
						label="Name"
						required={true}
						className='mr-1'
						style={{ width: '60%' }}
						rules={[
							{
								max: 35,
								message:
									t("messages.form.max_length_exceeded_error", { length: 35 })
							},
							{
								required: true,
								message: t("messages.form.required_error")
							}
						]}
					>
						<Input
							autoComplete='off'
							count={{
								show: true,
								max: 35,
							}}></Input>
					</Form.Item>
					<Form.Item
						name="evaluatedAt"
						label={t("models.constraint.fields.evaluated_at")}
						required={false}
						style={{ width: '20%' }}
					>
						<DatePicker
							style={{ zIndex: '2000' }}
							format={'DD/MM/YYYY HH:mm'}
							className='w-100'
							showTime={{
								format: 'HH:mm',
								minuteStep: 15
							}}></DatePicker>
					</Form.Item>
				</div>
				<Form.Item name="description" label="Description" rules={[
					{
						max: 300,
						message:
							t("messages.form.max_length_exceeded_error", { length: 300 })
					}
				]}>
					<Input.TextArea
						rows={5}
						count={{
							show: true,
							max: 300,
						}}></Input.TextArea>
				</Form.Item>
				<Form.Item name="weight" label="Weight">
					<Slider
						tooltip={{ placement: 'right', open: areTooltipVisibles }}
						dots={true}
						min={1}
						max={3}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ConstraintEditionModal;
