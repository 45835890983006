import {
	findIndex,
	pick,
	keys,
	isFunction,
	keyBy,
	isArray,
	differenceBy
} from 'lodash';
import { defaults as allDefaults } from 'model';
import {
	useCreateStakeholderConstraintImpactMutation,
	useCreateStakeholderIndicatorImpactMutation,
	useCreateStakeholderMutation,
	useCreateStakeholderRiskImpactMutation,
	useDeleteStakeholderConstraintImpactMutation,
	useDeleteStakeholderIndicatorImpactMutation,
	useDeleteStakeholderMutation,
	useDeleteStakeholderRiskImpactMutation,
	useUpdateStakeholderConstraintImpactStrengthMutation,
	useUpdateStakeholderIndicatorImpactStrengthMutation,
	useUpdateStakeholderMutation,
	useUpdateStakeholderRiskImpactStrengthMutation,
	useUpdateStakeholderScenarioMutation,
	useCreateStakeholderLinkMutation,
	useDeleteStakeholderLinkMutation,
	useUpdateStakeholderLinkStrengthMutation,
	useCreateTaggingMutation,
	useDeleteTaggingMutation
} from 'graph/dist/generated';
import {
	getFieldFunctions,
	getFragmentFields,
	optimisticlyUpdateScenario
} from 'utils/graphql';
import { useXImpactController } from './useXImpact';
import { gql } from '@apollo/client';
import { StakeholderModel } from 'model/StakeholderModel';

export const useStakeholderController = () => {
	const [createStakeholder] = useCreateStakeholderMutation();
	const [updateStakeholder] = useUpdateStakeholderMutation();
	const [deleteStakeholder] = useDeleteStakeholderMutation();
	const [updateStakeholderScenario] = useUpdateStakeholderScenarioMutation();

	// Stakeholder links
	const [createStakeholderLink] = useCreateStakeholderLinkMutation();
	const [deleteStakeholderLink] = useDeleteStakeholderLinkMutation();
	const [updateStakeholderLinkStrength] =
		useUpdateStakeholderLinkStrengthMutation();

	// Tagging
	const [createTagging] = useCreateTaggingMutation();
	const [deleteTagging] = useDeleteTaggingMutation();

	const stakeholderImpactController = useXImpactController();
	const [createStakeholderRiskImpact] =
		useCreateStakeholderRiskImpactMutation();
	const [deleteStakeholderRiskImpact] =
		useDeleteStakeholderRiskImpactMutation();
	const [updateStakeholderRiskImpactStrength] =
		useUpdateStakeholderRiskImpactStrengthMutation();
	const [createStakeholderIndicatorImpact] =
		useCreateStakeholderIndicatorImpactMutation();
	const [deleteStakeholderIndicatorImpact] =
		useDeleteStakeholderIndicatorImpactMutation();
	const [updateStakeholderIndicatorImpactStrength] =
		useUpdateStakeholderIndicatorImpactStrengthMutation();
	const [createStakeholderConstraintImpact] =
		useCreateStakeholderConstraintImpactMutation();
	const [deleteStakeholderConstraintImpact] =
		useDeleteStakeholderConstraintImpactMutation();
	const [updateStakeholderConstraintImpactStrength] =
		useUpdateStakeholderConstraintImpactStrengthMutation();

	const handleCreateStakeholder = async (
		projectId: string,
		stakeholders: StakeholderModel[],
		values: Object,
		defaults = {}
	) => {
		let appliedDefaults = {
			...allDefaults.Stakeholder,
			...defaults
		};

		const variables = {
			projectId: projectId,
			...appliedDefaults,
			...values
		};

		const result = await createStakeholder({
			variables,

			update(cache, { data: { createStakeholder } }) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						stakeholders(existingStakeholders = []) {
							const newStakeholderRef = cache.writeFragment({
								data: createStakeholder,
								fragment: gql`
									fragment NewStakeholder on Stakeholder {
										${getFragmentFields(variables)}
									}
								`
							});
							return [...existingStakeholders, newStakeholderRef];
						}
					}
				});
			}
		});

		return result;
	};

	const handleUpdateStakeholder = async (
		id,
		stakeholders,
		scenario,
		values
	) => {
		let index = findIndex(stakeholders, (s: any) => s.id === id);

		const newValues = {
			...pick(values, [
				'name',
				'description',
				'icon',
				'impact',
				'trend',
				'x',
				'y'
			])
		};

		let newStakeholder = {
			...pick(stakeholders[index], [keys(newValues)]),
			...newValues
		};

		if (values.evaluatedAt !== undefined)
			newStakeholder.evaluatedAt =
				!newStakeholder.evaluatedAt ||
				!isFunction(newStakeholder.evaluatedAt?.toISOString)
					? ''
					: newStakeholder.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let stakeholder = stakeholders[index];
			let scenarioValues =
				stakeholder.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['trend', 'impact', 'x', 'y'])
			};

			await optimisticlyUpdateScenario(
				stakeholder,
				'Stakeholder',
				scenarioId,
				scenarioValues,
				updateStakeholderScenario
			);
		} else {
			const result = await updateStakeholder({
				variables: {
					id: id,
					...newStakeholder
				},
				update(cache) {
					cache.modify({
						id: `Stakeholder:${id}`,
						fields: getFieldFunctions(newValues)
					});
				},
				optimisticResponse: {
					updateStakeholder: true
				}
			});
		}
	};

	const handleDeleteStakeholder = async (projectId: string, id: string) => {
		await deleteStakeholder({
			variables: {
				id
			},

			update(cache) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						stakeholders(existingRefs, { readField }) {
							return existingRefs.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteStakeholder: true
			}
		});
	};

	// Stakeholder link functions
	const handleCreateStakeholderLink = async (
		projectId: string,
		origin: any,
		target: any
	) => {
		return await createStakeholderLink({
			variables: {
				projectId,
				originId: origin.id,
				targetId: target.id
			},
			update(cache, { data: { createStakeholderLink } }) {
				cache.modify({
					id: `Stakeholder:${origin.id}`,
					fields: {
						links(existingStakeholdersLinksRef, { readField }) {
							const newStakeholderLink = cache.writeFragment({
								id:
									'StakeholderLink:' +
									createStakeholderLink.id,
								data: createStakeholderLink,
								fragment: gql`
									fragment StakeholderFragment on Stakeholder {
										originId
										targetId
										createdAt
									}
								`
							});
							return [
								...existingStakeholdersLinksRef,
								newStakeholderLink
							];
						}
					}
				});
			},
			optimisticResponse: {
				createStakeholderLink: {
					__typename: 'StakeholderLink',
					id: origin.id + '-' + target.id,
					originId: origin.id,
					targetId: target.id,
					createdAt: new Date().toISOString()
				}
			}
		});
	};

	const handleDeleteStakeholderLink = async (
		projectId: string,
		stakeholderId: string,
		id: string
	) => {
		await deleteStakeholderLink({
			variables: {
				projectId,
				id
			},
			update(cache) {
				cache.modify({
					id: `Stakeholder:${stakeholderId}`,
					fields: {
						links(existingLinksRef, { readField }) {
							return existingLinksRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},
			optimisticResponse: {
				deleteStakeholderLink: true
			}
		});
	};

	const handleChangeStakeholderLinkStrength = async (
		link: any,
		strength: number
	) => {
		return updateStakeholderLinkStrength({
			variables: { id: link.id, strength },
			update(cache) {
				cache.modify({
					id: `StakeholderLink:${link.id}`,
					fields: {
						strength() {
							return strength;
						}
					}
				});
			},
			optimisticResponse: {
				updateStakeholderLinkStrength: true
			}
		});
	};

	// Tagging functions
	const handleCreateTag = async (
		projectId: string,
		stakeholderId: string,
		tagId: string
	) => {
		return await createTagging({
			variables: {
				projectId,
				objectId: stakeholderId,
				tagId,
				type: 'stakeholder'
			},
			update(cache, { data: { createTagging } }) {
				cache.modify({
					id: `Stakeholder:${stakeholderId}`,
					fields: {
						taggings(existingTaggings = []) {
							const newTaggingRef = cache.writeFragment({
								data: createTagging,
								fragment: gql`
									fragment NewTagging on Tagging {
										id
										tagId
										type
										objectId
										createdAt
									}
								`
							});
							return [...existingTaggings, newTaggingRef];
						}
					}
				});
			},
			optimisticResponse: {
				createTagging: {
					__typename: 'Tagging',
					id: stakeholderId + '-' + tagId,
					tagId,
					type: 'stakeholder',
					objectId: stakeholderId,
					createdAt: new Date().toISOString()
				}
			}
		});
	};

	const handleDeleteTag = async (
		taggingId: string,
		stakeholderId: string
	) => {
		return await deleteTagging({
			variables: {
				id: taggingId
			},
			update(cache) {
				cache.modify({
					id: `Stakeholder:${stakeholderId}`,
					fields: {
						taggings(existingTaggings = [], { readField }) {
							return existingTaggings.filter(
								(ref: string) =>
									taggingId !== readField('id', ref)
							);
						}
					}
				});
			},
			optimisticResponse: {
				deleteTagging: true
			}
		});
	};

	const handleChangeStakeholderTags = async (
		projectId: string,
		stakeholder: any,
		tagIds: string[]
	) => {
		if (!tagIds || !isArray(tagIds)) return;

		// Old tags ids
		const oldTaggings = stakeholder.taggings || [];
		const oldTagIds = oldTaggings.map((t) => t.tagId);
		const indexedOldTags = keyBy(oldTaggings, 'tagId');

		// Find which tags to add and which to remove
		const tagsToAdd = tagIds.filter((t) => !oldTagIds.includes(t));
		const tagsToRemove = oldTagIds.filter((t) => !tagIds.includes(t));

		// Execute all operations
		const operations = [
			...tagsToAdd.map((tagId) =>
				handleCreateTag(projectId, stakeholder.id, tagId)
			),
			...tagsToRemove.map((tagId) =>
				handleDeleteTag(indexedOldTags[tagId].id, stakeholder.id)
			)
		];

		return await Promise.all(operations);
	};

	return {
		createStakeholder: handleCreateStakeholder,
		updateStakeholder: handleUpdateStakeholder,
		deleteStakeholder: handleDeleteStakeholder,

		// Link operations
		createStakeholderLink: handleCreateStakeholderLink,
		deleteStakeholderLink: handleDeleteStakeholderLink,
		changeStakeholderLinkStrength: handleChangeStakeholderLinkStrength,

		// Tag operations
		createTag: handleCreateTag,
		deleteTag: handleDeleteTag,
		changeStakeholderTags: handleChangeStakeholderTags,

		createRiskImpact: async (stakeholderId: string, riskId: string) => {
			console.log('createRiskImpact', stakeholderId, riskId);
			return await stakeholderImpactController.create(
				createStakeholderRiskImpact,
				'Stakeholder',
				stakeholderId,
				'Risk',
				riskId
			);
		},
		updateRiskImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderRiskImpactStrength,
				impact,
				'Stakeholder',
				'Risk',
				strength
			);
		},
		deleteRiskImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderRiskImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Risk'
			);
		},
		createIndicatorImpact: async (
			stakeholderId: string,
			indicatorId: string
		) => {
			console.log('createIndicatorImpact', stakeholderId, indicatorId);
			return await stakeholderImpactController.create(
				createStakeholderIndicatorImpact,
				'Stakeholder',
				stakeholderId,
				'Indicator',
				indicatorId
			);
		},
		updateIndicatorImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderIndicatorImpactStrength,
				impact,
				'Stakeholder',
				'Indicator',
				strength
			);
		},
		deleteIndicatorImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderIndicatorImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Indicator'
			);
		},
		createConstraintImpact: async (
			stakeholderId: string,
			constraintId: string
		) => {
			console.log('createConstraintImpact', stakeholderId, constraintId);
			return await stakeholderImpactController.create(
				createStakeholderConstraintImpact,
				'Stakeholder',
				stakeholderId,
				'Constraint',
				constraintId
			);
		},
		updateConstraintImpactStrength: async (impact, strength) => {
			return await stakeholderImpactController.updateStrength(
				updateStakeholderConstraintImpactStrength,
				impact,
				'Stakeholder',
				'Constraint',
				strength
			);
		},
		deleteConstraintImpact: async (id: string, stakeholderId: string) => {
			return await stakeholderImpactController.delete(
				deleteStakeholderConstraintImpact,
				id,
				'Stakeholder',
				stakeholderId,
				'Constraint'
			);
		}
	};
};
