import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { Button } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileImage } from '@fortawesome/pro-light-svg-icons';
import { RiskModel } from 'model/RiskModel';
import { RiskCategory } from 'graph/dist/generated';
import * as ExcelJS from 'exceljs';
import { sortBy } from 'lodash';

const generateImage = (height) => {
    const node = document.querySelector('.Body');

    const calculatedHeight = ((height || node.clientHeight) - 70) * 2 + 30
    domtoimage.toBlob(node, {
        bgcolor: "#1C2333",
        height: calculatedHeight,
        width: (node.clientWidth - 235) * 2 * 1.2,
        quality: 1,
        style: {
            transform: "scale(" + 2 + ")",
            marginTop: -150 + "px",
            transformOrigin: `0% 0%`,

            height: calculatedHeight * 1.2,
            width: (node.clientWidth - 235) * 2 * 1.2,
            backgroundImage: "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTU3LjQgMTQuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTU3LjQgMTQuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNGRkZGRkY7fQoJLnN0MXtmb250LWZhbWlseTonQXJpYWxNVCc7fQoJLnN0Mntmb250LXNpemU6MTJweDt9Cjwvc3R5bGU+Cjx0ZXh0IHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDEgMS44MzEwNTVlLTA0IDEwLjI5OCkiIGNsYXNzPSJzdDAgc3QxIHN0MiI+R2Vyb3NpdHVzIC0gQWxsIHJpZ2h0cyByZXNlcnZlZDwvdGV4dD4KPC9zdmc+Cg==)",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '167.5px 167.5px',
            backgroundPositionX: `16px`,
            backgroundPositionY: `${calculatedHeight / 2 - 25}px`,
        }
    })
        .then(function (blob) {
            // Current date
            saveAs(blob, `Gerositus-${new Date().toLocaleDateString().replace(/\//g, '-') + '-' +
                new Date().toLocaleTimeString().replace(/:/g, '-')
                }.png`);
        });
}

const generateExcel = async (risks: RiskModel[], riskCategories: RiskCategory[]) => {

    const sortedRisks = sortBy(risks, ["reference", "name"]);

    const prepareRow = (risk: RiskModel) => {
        return [
            risk.name,
            risk.reference,
            risk.description,
            risk.impact,
            risk.likelihood,
            (risk.impact || 1) * (risk.likelihood || 1),
            risk.riskCategoryId ? riskCategories.find(c => c.id === risk.riskCategoryId)?.name : '',
        ]
    }

    const columns = [
        { header: 'Name', key: 'name', width: 50 },
        { header: 'Reference', key: 'reference', width: 16 },
        { header: 'Description', key: 'description', width: 80 },
        { header: 'Impact', key: 'impact', width: 18 },
        { header: 'Likelihood', key: 'likelihood', width: 18 },
        { header: 'Exposure', key: 'exposure', width: 18 },
        { header: 'Category', key: 'category', width: 32 },
    ];

    let preparedJson = sortedRisks.filter(r => !r.isOpportunity).map(prepareRow)
    let preparedJsonOpportunities = sortedRisks.filter(r => !!r.isOpportunity).map(prepareRow)



    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();

    const risksSheet = workbook.addWorksheet('Risks');
    risksSheet.columns = columns;
    risksSheet.addRows(preparedJson);
    risksSheet.autoFilter = {
        from: 'A1',
        to: 'G1',
    }

    const opportunitiesSheet = workbook.addWorksheet('Opportunities');
    opportunitiesSheet.columns = columns;
    opportunitiesSheet.addRows(preparedJsonOpportunities);
    opportunitiesSheet.autoFilter = {
        from: 'A1',
        to: 'G1',
    }

    // Format the header
    risksSheet.getRow(1).eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' },
        };
        cell.font = {
            bold: true,
            size: 16
        };
    });

    const buffer = await workbook.xlsx.writeBuffer();

    /*const ws = XLSX.utils.json_to_sheet(preparedJson);
    const wso = XLSX.utils.json_to_sheet(preparedJsonOpportunities);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Risks");
    XLSX.utils.book_append_sheet(wb, wso, "Opportunities");
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'array' });*/
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), 'Gerositus-Risks.xlsx');

}

export const ExportTab = ({ height, risks, riskCategories }) => {



    return (

        <div className="d-flex flex-column flex-grow mt-3">
            <Button className="mb-1" variant="outlined" onClick={() => generateExcel(risks, riskCategories)}>
                <FontAwesomeIcon icon={faFileExcel} />
                Export as Excel file
            </Button>

            <Button variant="outlined" onClick={() => generateImage(height)}>
                <FontAwesomeIcon icon={faFileImage} />
                Export visualization as image
            </Button>
        </div>
    );
}