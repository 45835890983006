import React from 'react';

import { faClone, faClose, faEdit, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ActionButton from "components/action/ActionButton";

import { useTranslation } from "react-i18next";

import "./FishboneBranchGeneralTab.sass";
import { Button, Tag, Tooltip } from 'antd';
import { find, keyBy, sortBy, sumBy, uniq } from 'lodash';
import Weight from 'components/controls/Weight';
import { colorToPercent } from 'utils/ranking';
import dayjs from 'dayjs';
import { IconPicker } from 'components/IconPicker';

interface FishboneBranchGeneralTabProps {
    selection: any;
    onEdit: Function;
    onClone: Function;
    onClose: Function;
    onDelete: Function;
    onChange: Function;
    scenario: any;

    risks: any[];
    fishboneBranchs: any[];

    indicators: any[];
    createIndicatorImpact: Function;
    deleteIndicatorImpact: Function;
    updateImpactStrength: Function;

}

export const FishboneBranchGeneralTab = (props: FishboneBranchGeneralTabProps) => {

    const { risks = [], fishboneBranchs = [] } = props;
    const { t } = useTranslation();

    const realRisks = risks.filter(r => !r.isOpportunity);
    const realOpportunities = risks.filter(r => r.isOpportunity);

    const indexedRisks = keyBy(risks, 'id');

    let pointsWeight = 0;
    props.selection?.items.forEach((p) => (pointsWeight += p.weight || 1));

    const completion =
        props.selection?.items && props.selection?.items.length > 0
            ? sumBy(props.selection?.items, (p) => (p.completion || 0) * (p.weight || 1)) /
            pointsWeight
            : 0;

    const vulnerability =
        props.selection?.items && props.selection?.items.length > 0
            ? sumBy(
                props.selection?.items,
                (p) =>
                    colorToPercent(p.trend !== null ? p.trend : '50') *
                    (p.weight || 1)
            ) / pointsWeight
            : 50;


    const allIcons: any = uniq(
        fishboneBranchs.map((i: any) => (i.icon ? i.icon : 'faCrosshairs'))
    ).sort();


    return (
        <div className="FishboneBranchGeneralTab noselection">

            <div className="d-flex flex-column flex-grow">

                <div className="d-flex flex-row align-items-start mb-1">


                    <div className="d-flex flex-row align-items-center">
                        <div
                            className="IconPicker"
                            style={{ marginRight: '1rem' }}
                        >
                            <IconPicker
                                value={
                                    props.selection?.icon ||
                                    'faCrosshairs'
                                }
                                favorites={allIcons}
                                disabled={!!props.scenario}
                                onChange={
                                    props.scenario
                                        ? () => { }
                                        : (v) =>
                                            props.onChange(
                                                props.selection
                                                    .id,
                                                { icon: v }
                                            )
                                }
                            />
                        </div>

                        <div className="w-100 text-left">
                            <div className="title">{props.selection?.name}</div>
                            <small>{t("models.fishbone_branch.name")}</small>
                        </div>
                    </div>



                    <Tooltip title={t('actions.close')} placement='left'>
                        <Button
                            shape="circle"
                            className="mini ml-auto"
                            tooltip={t('models.item.actions.edit')}
                            style={{ marginRight: '-0.9rem' }}
                            onClick={props.onClose}
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </Button></Tooltip>
                </div>

                <ul>
                    {props.selection?.responsible && <li className="EditableListItem">
                        <div style={{ width: '35%', textAlign: 'left' }}>
                            {t("models.fishbone_branch.fields.responsible")}
                        </div>
                        <div>
                            {props.selection?.responsible}
                        </div>
                    </li>}

                    <li className="EditableListItem">
                        <div style={{ width: '35%', textAlign: 'left' }}>
                            {t("models.fishbone_branch.fields.completion")}
                        </div>
                        <div>
                            {completion.toFixed(0) + "%"}
                        </div>
                    </li>

                    <li className="EditableListItem">
                        <div style={{ width: '35%', textAlign: 'left' }}>
                            {t("models.fishbone_branch.fields.trend")}
                        </div>
                        <div>
                            <div
                                className="MiniIndicatorVulnerabilityScale"
                                style={{
                                    width: '150px'
                                }}
                            >
                                <div
                                    id={'bar_marker'}
                                    style={{
                                        left: vulnerability + '%'
                                    }}
                                ></div>
                            </div>
                        </div>
                    </li>

                    <li className="EditableListItem">
                        <div style={{ width: '35%', textAlign: 'left' }}>
                            {t("models.fishbone_branch.fields.weight")}
                        </div>
                        <div>
                            <Weight onClick={() => props.onChange(props.selection?.id, { weight: props.selection?.weight > 2 ? 1 : props.selection?.weight + 1 })}>{props.selection?.weight}</Weight>
                        </div>
                    </li>

                    <li className="EditableListItem">
                        <div style={{ width: '35%', textAlign: 'left' }}>
                            {t("models.fishbone_branch.fields.dates")}
                        </div>
                        <div>
                            {
                                <small>
                                    {props.selection?.startOn ? dayjs(
                                        props.selection?.startOn
                                    ).format('DD/MM/YYYY') : t("messages.not_defined")}
                                </small>
                            }
                            {props.selection?.endOn &&
                                !props.selection?.startOn ?
                                'Until ' : props.selection?.endOn ? <small className="mr-05 ml-05">-</small> : null}
                            {props.selection?.endOn && (
                                <small>
                                    {dayjs(props.selection?.endOn).format(
                                        'DD/MM/YYYY'
                                    )}
                                </small>
                            )}
                        </div>
                    </li>


                    {props.selection?.description && (
                        <li className="EditableListItem">
                            <div>{props.selection?.description}</div>
                        </li>
                    )}
                </ul>

                {!props.scenario && (
                    <div className="Actions mt-2 mb-4 pl-1 text-center ml-auto mr-auto">

                        {props.onAddItem && <ActionButton
                            className="small ml-05"
                            tooltip={t('models.fishbone_branch.actions.add')}
                            onClick={(e) => props.onAddItem(e)}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </ActionButton>}

                        {props.onEdit && <ActionButton
                            className="small"
                            tooltip={t('models.fishbone_branch.actions.edit')}
                            onClick={() => props.onEdit(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </ActionButton>}

                        {props.onClone && <ActionButton
                            className="small"
                            tooltip={t('models.fishbone_branch.actions.clone')}
                            onClick={() => props.onClone(props.selection)}
                        >
                            <FontAwesomeIcon icon={faClone} />
                        </ActionButton>}

                        {props.onDelete && <ActionButton
                            className="small"
                            tooltip={t('models.fishbone_branch.actions.delete')}
                            onClick={() => props.onDelete(props.selection.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </ActionButton>}
                    </div>
                )}

                {false && (
                    <div className="d-flex flex-column flex-grow mt-3">
                        <div className="text-left mb-05">
                            {t('models.indicator.fields.risks.name')}
                        </div>
                        <div className="text-left">
                            {realRisks && realRisks.length ? (
                                sortBy(realRisks, 'name').map(
                                    (r: any, index) => {
                                        const impactedIndicator = r.impactedIndicators.find(ii => ii.indicatorId == props.selection?.id);
                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            r.id,
                                                            props.selection?.id,

                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            r.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    !!impactedIndicator
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >

                                                {r.name}


                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {false && (
                    <div className="d-flex flex-column flex-grow mt-4 mb-4">
                        <div className="text-left mb-05">
                            {t('models.indicator.fields.risks.name_positive')}
                        </div>
                        <div className="text-left">
                            {realOpportunities && realOpportunities.length ? (
                                sortBy(realOpportunities, 'name').map(
                                    (r: any, index) => {
                                        const impactedIndicator = r.impactedIndicators.find(ii => ii.indicatorId == props.selection?.id);

                                        return (
                                            <Tag
                                                key={index}
                                                onClick={async (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    if (
                                                        !impactedIndicator ||
                                                        !impactedIndicator.id
                                                    ) {
                                                        props.createIndicatorImpact(
                                                            r.id,
                                                            props.selection?.id,

                                                        );
                                                    } else if (
                                                        impactedIndicator.strength <
                                                        3
                                                    ) {
                                                        props.updateImpactStrength(
                                                            impactedIndicator,
                                                            impactedIndicator.strength +
                                                            1
                                                        );
                                                    } else {
                                                        props.deleteIndicatorImpact(
                                                            impactedIndicator.id,
                                                            r.id
                                                        );
                                                    }
                                                }}
                                                className={[
                                                    'ImpactedIndicator tag',
                                                    !!impactedIndicator
                                                        ? 'selected'
                                                        : 'unselected'
                                                ].join(' ')}
                                            >

                                                {r.name}


                                            </Tag>
                                        );
                                    }
                                )
                            ) : (
                                <div
                                    className="text-center"
                                    style={{ color: 'rgba(46, 160, 223, 0.3)' }}
                                >
                                    {t('models.indicator.no_x')}
                                </div>
                            )}
                        </div>
                    </div>
                )}


            </div>
        </div >
    );
}