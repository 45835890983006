import { ConstraintScenarionSimulationModel } from './ConstraintScenarioSimulation';

export type ConstraintModel = {
	id: string;
	name: string;
	description: string;
	weight: number;
	trend: string;
	reference: string;
	evaluatedAt: Date;

	scenarios: ConstraintScenarionSimulationModel[];
};

export const defaults = {
	__typename: 'Constraint',
	name: '',
	description: '',
	weight: 1,
	trend: '50',
	reference: '',
	evaluatedAt: '',
	scenarios: []
};
