import React from 'react';

import { sortBy, filter, values } from 'lodash';

import Loader from 'components/Loading/Loader';

import {
	useGetPricesQuery,
	useMakePaymentMutation
} from 'graph/dist/generated';
import { Button, Switch } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';

export const PlansList = (props: any) => {
	const [makePayment] = useMakePaymentMutation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [projectsCount, setProjectCount] = React.useState<Number>(3);
	const [billingMode, setBillingMode] = React.useState<String>('year');
	const getPricesQuery = useGetPricesQuery();

	const startPaymentProcess = async (price: string) => {
		setLoading(true);
		let result = await makePayment({
			variables: {
				price: price,
				referer: document.location.href.replace(/\/profile.*/, '')
			}
		});
		console.log('result', result.data.makePayment);
		document.location.href = result.data.makePayment;

		return true;
	};

	return (
		<div className="PlansList text-center">

			<ButtonGroup className="mr-1">
				<Button type={[billingMode == "month" ? "primary" : ""].join(" ")} onClick={() => setBillingMode("month")}>Monthly</Button>
				<Button type={[billingMode == "year" ? "primary" : ""].join(" ")} onClick={() => setBillingMode("year")}>Yearly <small style={{ color: "red" }}>(save 20%)</small></Button>
			</ButtonGroup>

			<ButtonGroup>
				<Button type={[projectsCount == 3 ? "primary" : ""].join(" ")} onClick={() => setProjectCount(3)}>3</Button>
				<Button type={[projectsCount == 6 ? "primary" : ""].join(" ")} onClick={() => setProjectCount(6)}>6</Button>
				<Button type={[projectsCount == 10 ? "primary" : ""].join(" ")} onClick={() => setProjectCount(10)}>10+</Button>
			</ButtonGroup>

			<div className="plans">


				{getPricesQuery.loading ? (
					<Loader></Loader>
				) : (
					<>
						{filter(
							sortBy(getPricesQuery.data?.getPrices, 'unit_amount'),
							(p: any) =>
								JSON.parse(p.recurring).interval == billingMode
								&& (JSON.parse(p.metadata).projects == projectsCount || (JSON.parse(p.metadata).projects == 6 && projectsCount == 10))
						).map((p, index) => {
							let metadata = JSON.parse(p.metadata);
							let recurringInfos = JSON.parse(p.recurring);
							return (
								<div className="plan" key={index}>
									<h3>{metadata.name?.split(" - ", 2)[1]}</h3>
									<p>{metadata.description.split(" - ").map(
										p => <>{p}<br /></>
									)} </p>

									{
										projectsCount < 10 ?
											<>
												<a
													className={[
														'paymentButton',
														loading ? 'disabled' : ''
													].join(' ')}
													onClick={() => startPaymentProcess(p.id)}
												>
													{!loading && 'Choose now'}
													{loading && (
														<Loader
															size={20}
															color="white"
														></Loader>
													)}
												</a>

												<div className="price">
													{(recurringInfos.interval == 'month'
														? p.unit_amount / 100
														: p.unit_amount / 1200
													).toFixed(0)}{' '}
													USD/month
												</div>
											</>
											: <>
												<a
													className={['paymentButton'].join(' ')}
													href="mailto:contact@gerositus.com"
												>
													Contact us
												</a>
												<div className="price">
													&nbsp;
												</div>
											</>
									}
								</div>
							);
						})}

					</>
				)}
			</div>
		</div>
	);
};

export default PlansList;
