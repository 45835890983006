export type ScenarioModel = {
	id: string;
	name: string;

	description: string;
	impacts: string;
};

export const defaults = {
	__typename: 'StakeholderLink',
	createdAt: '2025-02-11T14:26:13.594Z',
	name: '',
	description: '',
	impacts: []
};
