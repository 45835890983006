import { Slider } from 'antd';
import { MiniSlider } from 'components/controls/MiniSlider';

import Weight from 'components/controls/Weight';

import './FishbonePointPopover.sass';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const FishbonePointPopover = (props: any) => {
	console.log('FishbonePointPopover', props);

	return (
		<div className="FishbonePointPopover">
			<div className="d-flex mb-2">
				<div className="title noselection">Status</div>

				<MiniSlider
					value={props.trend}
					key={props?.id}
					min={0}
					max={100}
					onChange={(value) =>
						props.onUpdate(props.id, { trend: '' + value })
					}
					className="highContrast"
				></MiniSlider>
			</div>
			<div className="d-flex mb-2">
				<div className="title noselection">Completion</div>

				<Slider
					min={0}
					step={10}
					max={100}
					style={{ width: '150px' }}
					onChangeComplete={(value) =>
						props.onUpdate(props.id, { completion: value })
					}
					defaultValue={props.completion || 0}
				/>
			</div>
			<div className="d-flex">
				<div className="title noselection">Weight</div>

				<Weight
					dark={true}
					onClick={(values: any) => {
						let newWeight = (props.weight || 1) + 1;
						if (newWeight > 3) newWeight = 1;
						props.onUpdate(props.id, { weight: newWeight });
					}}
				>
					{props.weight || 1}
				</Weight>
			</div>
		</div>
	);
};
