import gql from 'graphql-tag';
import {
	findIndex,
	pick,
	differenceBy,
	keys,
	isArray,
	isFunction
} from 'lodash';

import { defaults as allDefaults } from 'model';
import {
	useCreateRiskMutation,
	useUpdateRiskMutation,
	useDeleteRiskMutation,
	useUpdateRiskScenarioMutation,
	useCreateTaggingMutation,
	useDeleteTaggingMutation,
	useCreateRiskIndicatorImpactMutation,
	useDeleteRiskIndicatorImpactMutation,
	useUpdateRiskIndicatorImpactStrengthMutation
} from 'graph/dist/generated';
import {
	getFieldFunctions,
	getFragmentFields,
	optimisticlyUpdateScenario
} from 'utils/graphql';
import { useXImpactController } from './useXImpact';

export const useRiskController = () => {
	const [createRisk] = useCreateRiskMutation();
	const [updateRisk] = useUpdateRiskMutation();
	const [deleteRisk] = useDeleteRiskMutation();
	const [updateRiskScenario] = useUpdateRiskScenarioMutation();
	const [createTagging] = useCreateTaggingMutation();
	const [deleteTagging] = useDeleteTaggingMutation();

	const riskImpactController = useXImpactController();
	const [createRiskIndicatorImpact] = useCreateRiskIndicatorImpactMutation();
	const [deleteRiskIndicatorImpact] = useDeleteRiskIndicatorImpactMutation();
	const [updateRiskIndicatorImpactStrength] =
		useUpdateRiskIndicatorImpactStrengthMutation();

	const updateTaggings = async (risk, tagIds) => {
		if (!tagIds || !isArray(tagIds)) return;

		const oldTaggings = risk.taggings || [];
		const newTaggings = tagIds.map((tag) => ({
			tagId: tag,
			objectId: risk.id
		}));

		const toDelete = differenceBy(oldTaggings, newTaggings, 'tagId');
		const toAdd = differenceBy(newTaggings, oldTaggings, 'tagId');

		toDelete.forEach(async (tagging) => {
			await deleteTagging({
				variables: {
					...tagging
				},
				update(cache, { data: { deleteTagging } }) {
					cache.modify({
						id: `Risk:${tagging.objectId}`,
						fields: {
							taggings(taggings = []) {
								return taggings.filter(
									(t) =>
										t.__ref !==
										`Tagging:${deleteTagging.id}`
								);
							}
						}
					});
				}
			});
		});

		toAdd.forEach(async (tagging) => {
			const variables = {
				projectId: risk.projectId,
				...tagging,
				type: 'risk'
			};
			await createTagging({
				variables,
				update(cache, { data: { createTagging } }) {
					cache.modify({
						id: `Risk:${tagging.objectId}`,
						fields: {
							taggings(taggings = []) {
								const newTagging = cache.writeFragment({
									id: 'Tagging:' + createTagging.id,
									data: createTagging,
									fragment: gql`
										fragment TaggingFragment on ${'Tagging'} {
											${getFragmentFields(variables)}
										}
									`
								});

								return [...taggings, newTagging];
							}
						}
					});
				}
			});
		});
	};

	const handleCreateRisk = async (
		projectId: string,
		risks,
		values: Object,
		scenario = null,
		defaults = {}
	) => {
		let appliedDefaults = {
			...allDefaults.Risk,
			...defaults
		};

		let evaluatedAt = values.evaluatedAt || appliedDefaults.evaluatedAt;
		evaluatedAt =
			!values.evaluatedAt || !isFunction(values.evaluatedAt?.toISOString)
				? ''
				: new Date(values.evaluatedAt).toISOString();

		let variables = {
			projectId: projectId,
			...appliedDefaults,
			name: values.name,
			reference: values.reference || '',
			impact: values.impact || appliedDefaults.impact,
			riskCategoryId:
				values.riskCategoryId || appliedDefaults.riskCategoryId,
			matchingOpportunityId:
				values.matchingOpportunityId ||
				appliedDefaults.matchingOpportunityId,
			isOpportunity:
				values.isOpportunity || appliedDefaults.isOpportunity,
			likelihood: values.likelihood || appliedDefaults.likelihood,
			description: values.description || appliedDefaults.description,
			taggings: [],
			scenarios: [],
			createdAt: new Date().toISOString(),
			evaluatedAt: evaluatedAt
		};

		let result = await createRisk({
			variables,
			update(cache, { data: { createRisk } }) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						risks(risks = []) {
							const newRisk = cache.writeFragment({
								id: 'Risk:' + createRisk.id,
								data: createRisk,
								fragment: gql`
									fragment RiskFragment on Risk {
										${getFragmentFields(allDefaults.Risk)}
									}
								`
							});

							return [...risks, newRisk];
						}
					}
				});
			},
			optimisticResponse: {
				createRisk: {
					id: 'temp-id',
					...appliedDefaults,
					...variables,
					impactedIndicators: []
				}
			}
		});

		await updateTaggings(result.data.createRisk, values.taggings);

		if (values.impactedIndicators !== undefined) {
			console.log(
				'updating impactedIndicators',
				values.impactedIndicators
			);
			await riskImpactController.diff(
				{
					create: createRiskIndicatorImpact,
					delete: deleteRiskIndicatorImpact
				},
				'Risk',
				result.data.createRisk,
				'Indicator',
				values.impactedIndicators
			);
		}

		// Update matching opportunities
		if (values.risksId !== undefined) {
			for (let riskId of values.risksId) {
				let oneRiskToUpdate = risks.find((r) => r.id === riskId);

				if (oneRiskToUpdate === undefined) continue;

				handleUpdateRisk(oneRiskToUpdate.id, risks, null, {
					matchingOpportunityId: result.data.createRisk.id
				});
			}
		}
		
		// Si un scénario est actif, associons les valeurs à ce scénario
		if (scenario && result.data?.createRisk?.id) {
			const risk = result.data.createRisk;
			const scenarioValues = {
				impact: values.impact || appliedDefaults.impact,
				likelihood: values.likelihood || appliedDefaults.likelihood
			};
			
			await optimisticlyUpdateScenario(
				risk,
				'Risk',
				scenario.id,
				scenarioValues,
				updateRiskScenario
			);
		}
		
		return result;
	};

	const handleUpdateRisk = async (id, risks, scenario, values) => {
		let index = findIndex(risks, (s: any) => s.id === id);

		const newValues = {
			...pick(values, [
				'description',
				'comment',
				'name',
				'impact',
				'likelihood',
				'riskCategoryId',
				'reference',
				'matchingOpportunityId',
				'isOpportunity',
				'evaluatedAt',
				'impactedIndicators'
			])
		};

		let newRisk = {
			...pick(risks[index], [keys(newValues)]),
			...newValues
		};

		if (values.evaluatedAt !== undefined)
			newRisk.evaluatedAt =
				!newRisk.evaluatedAt ||
				!isFunction(newRisk.evaluatedAt?.toISOString)
					? ''
					: newRisk.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let risk = risks[index];
			let scenarioValues =
				risk.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['likelihood', 'impact'])
			};

			await optimisticlyUpdateScenario(
				risk,
				'Risk',
				scenarioId,
				scenarioValues,
				updateRiskScenario
			);
		} else {
			const result = await updateRisk({
				variables: {
					id: id,
					...newRisk
				},
				update(cache) {
					cache.modify({
						id: `Risk:${id}`,
						fields: getFieldFunctions(values)
					});
				},
				optimisticResponse: {
					updateRisk: true
				}
			});
		}

		if (values.taggings !== undefined)
			await updateTaggings(risks[index], values.taggings);

		if (values.impactedIndicators !== undefined) {
			console.log(
				'updating impactedIndicators',
				values.impactedIndicators
			);
			await riskImpactController.diff(
				{
					create: createRiskIndicatorImpact,
					delete: deleteRiskIndicatorImpact
				},
				'Risk',
				risks[index],
				'Indicator',
				values.impactedIndicators
			);
		}

		// Update matching opportunities
		if (values.risksId !== undefined) {
			const newIds = values.risksId;
			const oldIds = risks
				.filter((r) => r.matchingOpportunityId === id)
				.map((r) => r.id);

			const idsToRemove = differenceBy(oldIds, newIds);
			const idsToAdd = differenceBy(newIds, oldIds);

			let promises = [];

			for (let riskId of idsToAdd) {
				let oneRiskToUpdate = risks.find((r) => r.id === riskId);

				if (oneRiskToUpdate === undefined) continue;

				promises.push(
					handleUpdateRisk(oneRiskToUpdate.id, risks, null, {
						matchingOpportunityId: id
					})
				);
			}

			for (let riskId of idsToRemove) {
				let oneRiskToUpdate = risks.find((r) => r.id === riskId);

				if (oneRiskToUpdate === undefined) continue;

				promises.push(
					handleUpdateRisk(oneRiskToUpdate.id, risks, null, {
						matchingOpportunityId: null
					})
				);
			}
		}
	};

	const handleDelete = async (projectId: string, id: string | undefined) => {
		console.log('deleteRisk', projectId, id);
		if (id == null) {
			return;
		}

		await deleteRisk({
			variables: { id: id },
			update(cache) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						risks(existingRisksRef, { readField }) {
							return existingRisksRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteRisk: true
			}
		});
	};

	return {
		createRisk: handleCreateRisk,
		updateRisk: handleUpdateRisk,
		deleteRisk: handleDelete,
		createIndicatorImpact: async (riskId: string, indicatorId: string) => {
			console.log('createIndicatorImpact', riskId, indicatorId);
			return await riskImpactController.create(
				createRiskIndicatorImpact,
				'Risk',
				riskId,
				'Indicator',
				indicatorId
			);
		},
		updateIndicatorImpactStrength: async (impact, strength) => {
			return await riskImpactController.updateStrength(
				updateRiskIndicatorImpactStrength,
				impact,
				'Risk',
				'Indicator',
				strength
			);
		},
		deleteIndicatorImpact: async (id: string, riskId: string) => {
			return await riskImpactController.delete(
				deleteRiskIndicatorImpact,
				id,
				'Risk',
				riskId,
				'Indicator'
			);
		}
	};
};
