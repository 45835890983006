import * as React from 'react';
import * as CSS from 'csstype';

import { fal } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconPickerItemProps {
	icon: any;
	size?: number;
	color?: string;
	onClick?: (icon: string) => void;
	containerStyles?: CSS.Properties;
}

const IconPickerItem: React.SFC<IconPickerItemProps> = ({
	icon,
	size = 24,
	color = '#000',
	onClick = (_: string) => { },
	containerStyles
}) => {
	return (
		<div
			onClick={() => !!onClick && onClick(icon)}
			style={{
				fontSize: size,
				color: color,
				padding: 2,
				...containerStyles
			}}
		>
			{icon ? <FontAwesomeIcon icon={fal[icon]}></FontAwesomeIcon> : null}
		</div>
	);
};

export { IconPickerItem };
