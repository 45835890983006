import React, { useContext } from 'react';

import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { sortBy, filter } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';

import AddButton from 'components/action/AddButton';
import IndicatorEditionModal from 'components/Modal/IndicatorEditionModal';

import { ConfirmModal } from 'components/Modal/ConfirmModal';

import { MiniSlider } from 'components/controls/MiniSlider';
import { getItemAlteredByScenario, vulnerabilityIndicators } from 'utils/rules';
import HorizontalVulnerabilityScale from 'components/Radar/HorizontalVulnerabilityScale';
import { Button, Tooltip } from 'antd';
import Weight from 'components/controls/Weight';
import Table from 'components/Table';
import UIContext from 'components/Utils/UIContext';

import { useIndicatorController } from 'hooks/useIndicatorController';
import dayjs from 'dayjs';
import SubHeaderPortal from 'layout/header/SubHeaderPortal';
import Tutorial from 'components/Tutorial/Tutorial';

import './Indicators.sass';
import { MaskableComponent } from 'components/Tutorial/MaskableComponent';


const indicatorsLimit = 20;

export const Indicators = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const uiContext = useContext(UIContext);

	const params: any = useParams();

	const { createIndicator, updateIndicator, deleteIndicator } = useIndicatorController();

	const { t } = useTranslation();

	const allScenarios = (props.scenarios || []).concat([{ id: null }]);
	let alteredIndicators = sortBy(
		props.indicators.map((c, index) => ({
			...getItemAlteredByScenario(c, props.scenario),
			index: index,
			key: c.id || `indicator-${index}` // Ajout d'une clé unique pour chaque élément
		})
		),
		'name'
	);

	let vulnerabilities = (props.scenarios || []).map((sc) => {
		return {
			id: sc.id,
			name: sc.name,
			vulnerability: vulnerabilityIndicators(
				props.indicators.map((c) => getItemAlteredByScenario(c, sc))
			)
		};
	});

	vulnerabilities.push({
		id: null,
		vulnerability: vulnerabilityIndicators(props.indicators)
	});


	// Create
	const handleCreateIndicator = async () => {
		props.pushModal(
			<IndicatorEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						reference: values.reference || '',
						evaluatedAt: values.evaluatedAt || ''
					};

					let result = await createIndicator(params.id, variables, {}, props.scenario);

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					description: '',
					reference: '',
					evaluatedAt: null
				}}
			></IndicatorEditionModal>
		);
	};

	// Update
	const handleUpdateIndicator = async (id: string, values: Object) => {
		updateIndicator(id, props.indicators || [], props.scenario, values);
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.indicator.messages.delete_confirmation')}
				t={t}
				onConfirm={() => deleteIndicator(params.id, id)}
				message={t(
					'models.indicator.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const columns = [
		{
			title: '',
			dataIndex: 'edit',
			key: 'edit',
			width: 50,
			render: (text, record) => (
				<Tooltip title='Edit'>
					<Button
						shape="circle"
						disabled={false}
						style={{ opacity: 1 }}
						onClick={
							props.disabled
								? () => { }
								: () => {
									setEditedItem(record);
								}
						}
					>
						<FontAwesomeIcon
							icon={faEdit}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial',
								opacity: props.disabled ? 0.5 : 1
							}}
						/>
					</Button>
				</Tooltip>
			)
		},
		{
			title: '#',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			width: 60,
			sortable: (a, b) => {
				const scoreA = (a.reference || ("S" + (a.index + 1))).toLocaleLowerCase()
				const scoreB = (b.reference || ("S" + (b.index + 1))).toLocaleLowerCase()

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, record) => {
				return record.reference || ("S" + ((record.index + 1 + "").padStart(2, '0')))
			}
		},
		{
			title: t("models.indicator.fields.evaluated_at"),
			dataIndex: 'evaluatedAt',
			key: 'evaluatedAt',
			className: 'nowrap',
			width: 140,
			sortable: (a, b) => {
				if (!b.evaluatedAt) return -1
				if (!a.evaluatedAt) return 1
				if (a.evaluatedAt && b.evaluatedAt && a.evaluatedAt < b.evaluatedAt) return -1;
				if (a.evaluatedAt && b.evaluatedAt > b.evaluatedAt) return 1;
				return 0
			},
			render: (text, record, index) => {

				return <Tooltip title={record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY HH[h]mm') : ''}>
					<div>
						{record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''}
					</div>
				</Tooltip>
				return record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''
			}
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			className: 'w-50',
			sortable: true,
			ellipsis: true,
			render: (text, record) => {
				return (
					<div className="name">
						{record.name}
						{record.description ? (
							<div className="description">
								{record.description}
							</div>
						) : (
							''
						)}
					</div>
				);
			}
		},
		{
			title: 'Status',
			dataIndex: 'trend',
			key: 'trend',
			width: 200,
			sortable: (a, b) => {
				const scoreA = parseInt(a.trend)
				const scoreB = parseInt(b.trend)

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, item) => {
				return (
					<div>
						<MiniSlider
							className="ml-auto mr-2"
							key={item.id}
							value={item.trend}
							min={0}
							max={100}
							style={{ width: '100%', flexShrink: 0 }}
							onChange={(val) =>
								handleUpdateIndicator(item.id, { trend: val })
							}
							additionalMarkers={(allScenarios).map((sc) => {
								return {
									id: sc.id,
									name: sc.name || t("models.scenario.baseline"),
									value: getItemAlteredByScenario(item, sc).trend
								};
							})}
						></MiniSlider>
					</div>
				);
			}
		},
		{
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',
			sortable: true,
			width: 100,
			render: (text, record) => {
				return (
					<Weight
						style={{ marginRight: '0.5rem' }}
						onClick={(values: any) => {
							let newWeight = record.weight
								? record.weight + 1
								: 1;
							if (newWeight > 3) newWeight = 0;
							handleUpdateIndicator(record.id, {
								...record,
								weight: newWeight
							});
						}}
						disabled={false}
					>
						{record.weight}
					</Weight>
				);
			}
		}
	];

	return (
		<>
			<SubHeaderPortal>
				<Tutorial
					type="stakes"
					context={{ ...props }}
					executeAction={props.executeAction} projectId={params.id} />
			</SubHeaderPortal>


			<MaskableComponent masked={uiContext.maskedZones['main']}>
				<div className={"Indicators pt-1 pb-3"}>

					<h2>{t('pages.indicators.title')}</h2>

					<h3 className="mb-4">{t('pages.indicators.subtitle')}</h3>


					<HorizontalVulnerabilityScale
						t={t}
						vulnerabilities={vulnerabilities}
						scenarios={props.scenarios}
						scenario={props.scenario}
					></HorizontalVulnerabilityScale>


					<div className="mb-3 mt-4 w-100" style={{ maxWidth: '1200px' }}>
						<Table
							pagination={false}
							dataSource={alteredIndicators}
							columns={columns}
							size="small"
							rowKey="id"
						/>
					</div>

					{editedItem && (
						<IndicatorEditionModal
							onHide={() => {
								setEditedItem(null);
							}}
							onDelete={() => {
								askDeleteConfirmation(editedItem.id);
								setEditedItem(null);
							}}
							onSave={(values: Object) => {
								handleUpdateIndicator(editedItem.id, values);
								setEditedItem(null);
							}}
							id={editedItem?.id}
							values={{
								name: editedItem?.name,
								weight: editedItem?.weight || 1,
								description: editedItem?.description || '',
								reference: editedItem?.reference,
								evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null,
							}}
						></IndicatorEditionModal>
					)}

					<div className="w-100 d-flex justify-content-center align-items-center">
						<div
							className="w-100 d-flex justify-content-center align-items-center"
						>
							{props.indicators?.length < indicatorsLimit && (
								<AddButton
									onClick={handleCreateIndicator}
								></AddButton>
							)}
						</div>

					</div>
				</div >
			</MaskableComponent>
		</>
	);
};
