import React from 'react';
//import { useAuth0 } from './react-auth0-spa';
import useAsyncEffect from 'use-async-effect';
import { ApolloClient, InMemoryCache, ApolloLink, fromPromise } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { createAppSyncHybridLink } from './utils/graphql/appSyncHybridLink';
import { onError } from '@apollo/client/link/error';

import AppSyncConfig from './app-sync-config.json';
import Loader from './components/Loading/Loader';

// @ts-ignore
let subdomain: string = document.location.hostname.replace(/\.gerositus.*/, '');
const config: any =
	import.meta.env.VITE_APP_MODE === 'corporate'
		? AppSyncConfig[import.meta.env.VITE_APP_MODE][import.meta.env.VITE_APP_CLIENT || subdomain][
		import.meta.env.VITE_APP_ENV
		]
		: AppSyncConfig[import.meta.env.VITE_APP_MODE][
		import.meta.env.VITE_APP_ENV
		];

interface IProps {
	children: any;
	// any other props that come into the component
}

const getJwtToken = async () => {
	let url = new URL(window.location.href);
	let token = url.searchParams && url.searchParams.get('token');

	if (!token && localStorage.getItem('token')) {
		token = localStorage.getItem('token');
	}

	if (token) {
		localStorage.setItem('token', token);
	}
	return token;
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {


	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) =>
			console.error(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			)
		);
	}

	if (networkError) {
		console.error(`[Network error]: ${networkError}`);

		// If the network error is due to an expired token, refresh and retry
		if (networkError.statusCode === 401) {
			return fromPromise(
				getJwtToken().then((newToken) => {
					operation.setContext({
						headers: {
							...operation.getContext().headers,
							Authorization: `Bearer ${newToken}`,
						},
					});
				})
			).flatMap(() => forward(operation));
		}
	}
});

export const AuthorizedApolloProvider: any = ({ children }: IProps) => {
	const [token, setToken] = React.useState<string>('');
	const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
	const [client, setClient] = React.useState<any>(null);
	//const { loading, getIdTokenClaims } = useAuth0();

	useAsyncEffect(async () => {
		//if (loading) return <Loader></Loader>;

		const getClient = async () => {
			return new ApolloClient({
				link: ApolloLink.from([
					errorLink,
					await createAppSyncHybridLink({
						appSyncApiUrl: config.ApiUrl,
						getJwtToken
					})
				]),
				cache: new InMemoryCache({ resultCaching: false })
			});
		};

		const finalClient = await getClient();
		setClient(finalClient);
	}, []);

	return client ? (
		<ApolloProvider client={client as any}>{children}</ApolloProvider>
	) : null;
};
