// @ts-nocheck
import React, { Component, useEffect, useState } from 'react';

import UIContext from './UIContext';
import { set, slice } from 'lodash';
import { useAskBotQuery } from 'graph/dist/generated';
import { askBot, subscribeToMessages } from 'graph/queries';
import { gql, useApolloClient } from '@apollo/client';

let content = []
const defaultPreferences = {
	global: {},
	pages: {
		navigator: {
			links: false
		},
		risks: {
			sortOrder: 'ascend',
			sortBy: 'id',
			columns: {
				evaluatedAt: true,
			}
		},
		indicators: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		constraints: {
			sortOrder: 'ascend',
			sortBy: 'id'
		},
		fishbone: {

		}
	}
};

const UIProvider = ({ children }) => {

	const [ready, setReady] = useState(false);
	const apolloClient = useApolloClient();

	// AI
	const [botThreadId, setBotThreadId] = useState(null);
	const [botThreadMessages, setBotThreadMessages] = useState([]);

	const [maskedZones, setMaskedZones] = useState({});
	const [isTutorialMode, setIsTutorialMode] = useState(false);

	const [preferences, setPreferences] = useState({
		...defaultPreferences
	});

	const [selection, setSelection] = useState(null);

	const addBotMessage = async (projectId, scenarioId, screen, message, callback) => {

		const newMessages = [
			...botThreadMessages,
			{
				role: "user", content: message
			}, {
				role: "assistant",
				loading: true,
				content: "",
			}];
		await setBotThreadMessages(newMessages);

		const variables = {
			projectId,
			question: message,
			threadId: botThreadId || null,
			scenarioId: scenarioId || null,
			screen: screen || null
		};

		if (!variables.projectId) delete variables.projectId;
		if (!variables.scenarioId) delete variables.scenarioId;

		const result = await apolloClient.query({
			query: askBot,
			variables
		});

		if (callback) callback();
		return;

		setBotThreadMessages(
			[
				...newMessages,
				{
					role: "assistant",
					...result.data.askBot
				}
			]
		);
		if (result.data.askBot.threadId) {
			setBotThreadId(result.data.askBot.threadId);
		}
		if (callback) callback();
	}

	const resetBotThread = () => {
		setBotThreadMessages([]);
		setBotThreadId(null);
	}

	const addEmptyAssistantMessage = () => {
		setBotThreadMessages([...botThreadMessages, {
			role: "assistant",
			loading: true,
			content: "",
		}]);
	}

	const getLastMessage = () => {
		return botThreadMessages[botThreadMessages.length - 1];
	}

	const getMessages = () => {
		return botThreadMessages;
	}

	const onReceiveProjectMessage = (data) => {

		if (!data) return;
		const message = JSON.parse(data.message);

		if (message?.threadId) setBotThreadId(message?.threadId);
		let lastMessage = getLastMessage();

		if (message.event?.data?.type == "message_creation") {
			// If last message is from the user, add a new message
			if (!lastMessage || lastMessage.role == "user") {
				addEmptyAssistantMessage();
			}
		} else if (message.event?.event == "thread.message.delta") {

			if (lastMessage?.role == "assistant") {
				content[message?.event?.counter] = message?.event?.data?.delta?.content.map(c => c.text.value).join('')

				lastMessage.content = content.join("")
				// Remove sources "【...】" ni message
				lastMessage.content = lastMessage.content.replace(/\【.*?\】/g, '');

				lastMessage.loading = false;

				const newMessages = [...slice(getMessages(), 0, getMessages().length - 1), { ...lastMessage }];
				setBotThreadMessages(newMessages);
			}
		} else if (message.event?.event == "thread.run.completed") {
			content = []
		}
	}

	useEffect(() => {
		const storedPreferences = localStorage.getItem('gerositus.preferences');
		let parsedPreferences = {}
		try {
			parsedPreferences = storedPreferences ? JSON.parse(storedPreferences) : {};
		} catch (e) {
			console.error('Error parsing preferences', e);
			localStorage.removeItem('gerositus.preferences');
		}

		setPreferences(
			{
				...defaultPreferences,
				...parsedPreferences
			}
		);
		setReady(true);
	}, []);

	const setSpecificPreferences = async (type, preference, value) => {
		const newPreferences = set(preferences, `${type}.${preference}`, value);
		setPreferences({ ...newPreferences });
		localStorage.setItem('gerositus.preferences', JSON.stringify(newPreferences));
	};

	const select = async (obj, callback) => {
		await setSelection(obj);
		callback && callback();
	};

	const deselect = async (callback) => {
		await setSelection(null);
		callback && callback();
	};

	return <UIContext.Provider value={{
		selection,
		select: select,
		deselect: setSelection,
		preferences,
		setPreferences: setSpecificPreferences,
		botThread: {
			id: botThreadId,
			messages: botThreadMessages
		},

		setBotThreadId,
		addBotMessage,
		resetBotThread,
		onReceiveProjectMessage,

		maskedZones,
		setMaskedZones

	}}>
		{ready ? children : null}
	</UIContext.Provider >


}

export default UIProvider;