//@ts-ignore
import * as React from 'react';
import { vulnerability } from 'utils/ranking';
import {
	getItemAlteredByScenario,
	isItemInactive,
	isItemNeutral
} from 'utils/rules';

import './RadialVulnerabilityGauge.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMeh } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';
import { find, findIndex, groupBy, values, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

const RadialVulnerabilityGauge = (props) => {
	let originalStakeholders = (props.stakeholders || []).map((s) => s.o || s);
	let alteredStakeholders = (props.stakeholders || []).map((s) =>
		getItemAlteredByScenario(s, props.scenario)
	);

	const { t } = useTranslation();

	const currentVulnerability = vulnerability(alteredStakeholders);
	const arcLength = 2 * Math.PI * 20;

	let countPositive = alteredStakeholders.filter(
		(s) => s.y > 5 && !isItemInactive(s)
	).length;
	let countNeutral = alteredStakeholders.filter((s) =>
		isItemNeutral(s)
	).length;
	let countNegative = alteredStakeholders.filter(
		(s) => s.y < -5 && !isItemInactive(s)
	).length;

	let vulnerabilities = (props.scenarios || [])
		.map((sc) => {
			return {
				id: sc.id,
				name: sc.name,
				vulnerability: vulnerability(
					originalStakeholders.map((s) =>
						getItemAlteredByScenario(s, sc)
					)
				)
			};
		})
		.filter((vs) => originalStakeholders.length > 0);

	if (originalStakeholders.length > 0)
		vulnerabilities.unshift({
			id: null,
			vulnerability: vulnerability(originalStakeholders)
		});

	return (
		<div>
			<svg
				className="RadialVulnerabilityGauge noselection"
				viewBox="0 0 100 50"
			>
				<defs>
					<linearGradient
						id="gradient"
						x1="0%"
						y1="0%"
						x2="100%"
						y2="0%"
					>
						<stop offset="0%" stopColor="#E70549" />
						<stop offset="50%" stopColor="#FFFFFF" />
						<stop offset="100%" stopColor="#00B0A9" />
					</linearGradient>
				</defs>
				<path
					d="M 10 25 A 15 15 0 0 1 90 25"
					stroke="grey"
					strokeWidth="12"
					fill="none"
				></path>
				{originalStakeholders.length > 0 && (
					<path
						d="M 10 25 A 15 15 0 0 1 90 25"
						stroke={'url(#gradient)'}
						strokeDasharray={
							Math.max((100 - currentVulnerability) / 100, 0.01) *
							arcLength +
							' ' +
							arcLength
						}
						strokeWidth="12"
						fill="#FFFFFF00"
						className="arc"
					></path>
				)}

				{values(groupBy(vulnerabilities, 'vulnerability')).map(
					(vs, i) => {
						// Display a line for each scenario, with an angle from 0 to 180 degrees based on the vulnerability

						let angle = 180 - (vs[0].vulnerability / 100) * 180;

						//console.log("vs[0].vulnerability", vs[0].vulnerability);

						//console.assert(`${angle}`!="NaN")

						return (
							<g key={vs[0].id} transform={`translate(50 25)`}>
								<g transform={`rotate(${angle})`}>
									<g transform={`translate(-50 0)`}>
										<line
											x1="-4"
											y1="0"
											x2={2}
											y2={0}
											stroke={
												vs[0].id === null
													? '#FFFFFF'
													: '#FFFFFF66'
											}
											strokeWidth={'1'}
										></line>

										<g transform={`translate(-11 2)`}>
											<g transform={`rotate(${-angle})`}>
												<Tooltip
													title={vs
														.map((v) =>
															v.id
																? v.name
																: t("models.scenario.baseline")
														)
														.join(', ')}
												>
													<text
														dx={
															vs[0]
																.vulnerability <=
																50
																? '-1'
																: '3'
														}
														dy={
															vs[0]
																.vulnerability <=
																50
																? '4'
																: '1'
														}
														textAnchor={
															vs[0]
																.vulnerability <=
																50
																? 'start'
																: 'end'
														}
														fontSize="6"
														fill={
															!!find(
																vs,
																(v) =>
																	v.id ===
																	null
															)
																? '#FFFFFF'
																: '#FFFFFF66'
														}
													>
														{sortBy(
															vs.map((v) =>
																find(
																	vulnerabilities,
																	(ov) =>
																		v.id ==
																		ov.id
																).id != null
																	? 'S' +
																	findIndex(
																		vulnerabilities,
																		(
																			ov
																		) =>
																			v.id ==
																			ov.id
																	)
																	: 'B'
															)
														).join(', ')}
													</text>
												</Tooltip>
											</g>
										</g>
									</g>
								</g>
							</g>
						);
					}
				)}

				<g transform="translate(0 -30)">
					<text
						x="50"
						y="44"
						textAnchor="middle"
						fill="#999999"
						fontSize="8"
					>
						Stakeholder
					</text>
					<text
						x="50"
						y="55"
						textAnchor="middle"
						fill="#999999"
						fontSize="8"
					>
						alignment
					</text>
					<g transform="translate(0 90)">
						<foreignObject>
							<FontAwesomeIcon
								color="white"
								icon={faMeh}
							></FontAwesomeIcon>
						</foreignObject>

						<g transform="scale(0.25) translate(-55 -90)">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fal"
								data-icon="frown"
								className="svg-inline--fa fa-frown fa-w-16 negativeIcon"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 496 512"
								color="white"
							>
								<path
									fill="#E70549"
									d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm0-152c-44.4 0-86.2 19.6-114.8 53.8-5.7 6.8-4.8 16.9 2 22.5 6.8 5.7 16.9 4.8 22.5-2 22.4-26.8 55.3-42.2 90.2-42.2s67.8 15.4 90.2 42.2c5.3 6.4 15.4 8 22.5 2 6.8-5.7 7.7-15.8 2-22.5C334.2 339.6 292.4 320 248 320zm-80-80c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
								></path>
							</svg>
							<text fontSize="40" dy="40" dx="95" fill="#E70549">
								{countNegative}
							</text>
						</g>
						<g transform="scale(0.25) translate(125 -90)">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fal"
								data-icon="meh"
								className="svg-inline--fa fa-meh fa-w-16 "
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 496 512"
								color="white"
							>
								<path
									fill="#FFFFFF99"
									d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm-80-232c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm16 160H152c-8.8 0-16 7.2-16 16s7.2 16 16 16h192c8.8 0 16-7.2 16-16s-7.2-16-16-16z"
								></path>
							</svg>
							<text
								fontSize="40"
								dy="40"
								dx="95"
								fill="#FFFFFF99"
							>
								{countNeutral}
							</text>
						</g>
						<g transform="scale(0.25) translate(300 -90)">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fal"
								data-icon="smile"
								className="svg-inline--fa fa-smile fa-w-16 positiveIcon"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 496 512"
								color="white"
							>
								<path
									fill="#00B0A9"
									d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
								></path>
							</svg>
							<text fontSize="40" dy="40" dx="95" fill="#00B0A9">
								{countPositive}
							</text>
						</g>
					</g>
				</g>
			</svg>
		</div>
	);
};

export default RadialVulnerabilityGauge;
