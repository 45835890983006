import gql from 'graphql-tag';
import { findIndex, isFunction, pick } from 'lodash';

import {
	useCreateIndicatorMutation,
	useUpdateIndicatorMutation,
	useUpdateIndicatorScenarioMutation,
	useDeleteIndicatorMutation
} from 'graph/dist/generated';
import {
	getFieldFunctions,
	getFragmentFields,
	optimisticlyUpdateScenario
} from 'utils/graphql';

export const useIndicatorController = () => {
	const [createIndicator] = useCreateIndicatorMutation();
	const [updateIndicator] = useUpdateIndicatorMutation();
	const [updateIndicatorScenario] = useUpdateIndicatorScenarioMutation();
	const [deleteIndicator] = useDeleteIndicatorMutation();

	const handleCreateIndicator = async (
		projectId: string,
		values: Object,
		defaults = {},
		scenario = null
	) => {
		let baseDefaults = {
			weight: 1,
			trend: '50',
			description: '',
			reference: '',
			evaluatedAt: ''
		};

		let appliedDefaults = {
			...baseDefaults,
			...defaults
		};

		let variables = {
			projectId: projectId,
			name: values.name,
			weight: values.weight || appliedDefaults.weight,
			trend:
				values.trend !== null && values.trend !== undefined
					? values.trend
					: appliedDefaults.trend,
			description: values.description || appliedDefaults.description,
			reference: values.reference || appliedDefaults.reference,
			evaluatedAt: values.evaluatedAt || appliedDefaults.evaluatedAt
		};

		console.log('values', values);
		console.log('variables', variables);

		let result = await createIndicator({
			variables,
			update(cache, { data: { createIndicator } }) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						indicators(indicators = []) {
							const newIndicator = cache.writeFragment({
								id: 'Indicator:' + createIndicator.id,
								data: createIndicator,
								fragment: gql`
									fragment IndicatorFragment on Indicator {
										${getFragmentFields(variables)}
									}
								`
							});

							return [...indicators, newIndicator];
						}
					}
				});
			},
			optimisticResponse: {
				createIndicator: {
					id: 'temp-id',
					...variables,
					createdAt: new Date().toISOString(),
					scenarios: []
				}
			}
		});
		
		// Si un scénario est actif, associons les valeurs à ce scénario
		if (scenario && result.data?.createIndicator?.id) {
			const indicator = result.data.createIndicator;
			const scenarioValues = {
				trend: variables.trend || '50'
			};
			
			await optimisticlyUpdateScenario(
				indicator,
				'Indicator',
				scenario.id,
				scenarioValues,
				updateIndicatorScenario
			);
		}
		
		return result;
	};

	const handleUpdateIndicator = async (id, indicators, scenario, values) => {
		console.log('handleUpdateIndicator', id, indicators, scenario, values);

		let index = findIndex(indicators, (s: any) => s.id === id);

		let newIndicator = {
			...pick(indicators[index], [
				'name',
				'reference',
				'weight',
				'trend',
				'description',
				'reference',
				'evaluatedAt'
			]),
			...pick(values, [
				'name',
				'reference',
				'weight',
				'trend',
				'description',
				'reference',
				'evaluatedAt'
			])
		};

		if (values.evaluatedAt !== undefined)
			newIndicator.evaluatedAt =
				newIndicator.evaluatedAt === null ||
				!isFunction(newIndicator.evaluatedAt?.toISOString)
					? ''
					: newIndicator.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let risk = indicators[index];
			let scenarioValues =
				risk.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['trend'])
			};

			await optimisticlyUpdateScenario(
				risk,
				'Indicator',
				scenarioId,
				scenarioValues,
				updateIndicatorScenario
			);
		} else {
			const result = await updateIndicator({
				variables: {
					id: id,
					...newIndicator
				},
				update(cache, { data: { updateIndicator } }) {
					cache.modify({
						id: `Indicator:${id}`,
						fields: getFieldFunctions(values)
					});
				},
				optimisticResponse: {
					updateIndicator: true
				}
			});
		}
	};

	const handleDelete = async (projectId: string, id: string | undefined) => {
		if (id == null) {
			return;
		}

		await deleteIndicator({
			variables: { id: id },
			update(cache) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						indicators(existingIndicatorsRef, { readField }) {
							return existingIndicatorsRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteIndicator: true
			}
		});
	};

	return {
		createIndicator: handleCreateIndicator,
		updateIndicator: handleUpdateIndicator,
		deleteIndicator: handleDelete
	};
};
