import gql from 'graphql-tag';
import { isFunction, isObject, keys, pick } from 'lodash';

export const cloneScenarioElements = (
	project,
	newScenario,
	referenceScenario,
	hooks
) => {
	console.group('cloneScenarioElements');
	let impactedIndicators = project.indicators;
	let impactedConstraints = project.constraints;
	let impactedStakeholders = project.stakeholders;
	let impactedRisks = project.risks;

	console.log('referenceScenario', referenceScenario);

	let waiting = [];

	// Indicators
	impactedIndicators.forEach((i) => {
		let values = pick(i, ['trend']);

		if (referenceScenario) {
			let scenarioSimulationIndex = i.scenarios.findIndex(
				(sc) => sc.scenarioId === referenceScenario.id
			);

			values = {
				...values,
				...pick(i.scenarios[scenarioSimulationIndex], ['trend'])
			};
		}

		waiting.push(
			optimisticlyUpdateScenario(
				i,
				'Indicator',
				newScenario.id,
				values,
				hooks.updateIndicatorScenario
			)
		);
	});

	// Constraints
	impactedConstraints.forEach((c) => {
		let values = pick(c, ['trend']);

		if (referenceScenario) {
			let scenarioSimulationIndex = c.scenarios.findIndex(
				(sc) => sc.scenarioId === referenceScenario.id
			);

			if (scenarioSimulationIndex > -1)
				values = {
					...values,
					...pick(c.scenarios[scenarioSimulationIndex], ['trend'])
				};
		}

		waiting.push(
			optimisticlyUpdateScenario(
				c,
				'Constraint',
				newScenario.id,
				values,
				hooks.updateConstraintScenario
			)
		);
	});

	// Stakeholders
	impactedStakeholders.forEach((s) => {
		let values = pick(s, ['trend', 'impact', 'x', 'y']);

		if (referenceScenario) {
			let scenarioSimulationIndex = s.scenarios.findIndex(
				(sc) => sc.scenarioId === referenceScenario.id
			);

			if (scenarioSimulationIndex > -1) {
				values = {
					...values,
					...pick(s.scenarios[scenarioSimulationIndex], [
						'trend',
						'impact',
						'x',
						'y'
					])
				};
			}
		}

		waiting.push(
			optimisticlyUpdateScenario(
				s,
				'Stakeholder',
				newScenario.id,
				values,
				hooks.updateStakeholderScenario
			)
		);
	});

	// Risks
	impactedRisks.forEach((r) => {
		let values = pick(r, ['likelihood', 'impact']);

		if (referenceScenario) {
			let scenarioSimulationIndex = r.scenarios.findIndex(
				(sc) => sc.scenarioId === referenceScenario.id
			);

			if (scenarioSimulationIndex > -1) {
				values = {
					...values,
					...pick(r.scenarios[scenarioSimulationIndex], [
						'likelihood',
						'impact'
					])
				};
			}
		}

		waiting.push(
			optimisticlyUpdateScenario(
				r,
				'Risk',
				newScenario.id,
				values,
				hooks.updateRiskScenario
			)
		);
	});
	console.groupEnd();
	return Promise.all(waiting);
};

export const cloneScenarioElementsToBaseline = async (
	project: {
		indicators: any[];
		constraints: any[];
		stakeholders: any[];
		risks: any[];
	},
	originScenario,
	hooks: {
		updateIndicator: Function;
		updateConstraint: Function;
		updateStakeholder: Function;
		updateRisk: Function;
	}
) => {
	console.log('cloneScenarioElementsToBaseline', project, hooks);

	if (!originScenario) return;

	console.log('originScenario', originScenario);

	let waiting = [];

	// Indicators
	project.indicators.forEach((i) => {
		console.log('Indicator', i);
		let values = pick(i, ['trend']);

		let scenarioSimulationIndex = i.scenarios.findIndex(
			(sc) => sc.scenarioId === originScenario.id
		);

		values = {
			...values,
			...pick(i.scenarios[scenarioSimulationIndex], ['trend'])
		};

		console.log('updateIndicator', i.id, project.indicators, null, values);

		waiting.push(
			hooks.updateIndicator(i.id, project.indicators, null, values)
		);
	});

	// Constraints
	project.constraints.forEach((c) => {
		let values = pick(c, ['trend']);

		let scenarioSimulationIndex = c.scenarios.findIndex(
			(sc) => sc.scenarioId === originScenario.id
		);

		if (scenarioSimulationIndex > -1)
			values = {
				...values,
				...pick(c.scenarios[scenarioSimulationIndex], ['trend'])
			};

		waiting.push(
			hooks.updateConstraint(c.id, project.constraints, null, values)
		);
	});

	// Stakeholders
	project.stakeholders.forEach((s) => {
		let values = pick(s, ['trend', 'impact', 'x', 'y']);

		let scenarioSimulationIndex = s.scenarios.findIndex(
			(sc) => sc.scenarioId === originScenario.id
		);

		if (scenarioSimulationIndex > -1)
			values = {
				...values,
				...pick(s.scenarios[scenarioSimulationIndex], [
					'trend',
					'impact',
					'x',
					'y'
				])
			};

		waiting.push(
			hooks.updateStakeholder(s.id, project.stakeholders, null, values)
		);
	});

	// Risks
	project.risks.forEach((r) => {
		let values = pick(r, ['likelihood', 'impact']);

		let scenarioSimulationIndex = r.scenarios.findIndex(
			(sc) => sc.scenarioId === originScenario.id
		);

		if (scenarioSimulationIndex > -1)
			values = {
				...values,
				...pick(r.scenarios[scenarioSimulationIndex], [
					'likelihood',
					'impact'
				])
			};

		waiting.push(hooks.updateRisk(r.id, project.risks, null, values));
	});
	//console.groupEnd();
	await Promise.all(waiting);
};

export const getFragmentFields = (values) => {
	return keys(values)
		.filter((v) => v != 'projectId')
		.join('\n');
};

export const getFieldFunctions = (values) => {
	let fields = {};
	for (let key in values) {
		if (values[key] !== undefined) {
			fields[key] = () =>
				isObject(values[key]) && isFunction(values[key].format)
					? values[key].format('YYYY-MM-DD')
					: values[key];
		}
	}
	return fields;
};

export const getUpdatedObject = (object, scenario, changes) => {
	if (!object) return null;

	let updatedObject = {
		...object
	};

	if (scenario) {
		updatedObject.scenarios = object.scenarios.map((s) => {
			if (s.id === scenario.id) {
				return {
					...s,
					...changes
				};
			}
			return s;
		});
	}
	updatedObject = {
		...object,
		...changes
	};

	return updatedObject;
};

export const optimisticlyUpdateScenario = async (
	objectDetails: Object,
	objectType: string,
	scenarioId: string,
	values: object,
	method: Function
) => {
	const { id: objectId } = objectDetails;

	const filteredValues = {
		...pick(
			{
				...objectDetails,
				...values
			},
			['trend', 'x', 'y', 'impact', 'likelihood', 'completion']
		)
	};

	if (objectType == 'Stakeholder') {
		filteredValues.x = filteredValues.x || 0;
		filteredValues.y = filteredValues.y || 0;
		filteredValues.trend = filteredValues.trend || 'neutral';
		filteredValues.impact = filteredValues.impact || 'low';
	}

	if (objectType == 'Risk') {
		filteredValues.impact = filteredValues.impact || 1;
		filteredValues.likelihood = filteredValues.likelihood || 1;
	}

	if (objectType == 'Indicator') {
		filteredValues.trend = filteredValues.trend;
	}

	if (objectType == 'Constraint') {
		filteredValues.trend = filteredValues.trend;
	}

	await method({
		variables: { id: objectId, scenarioId, ...filteredValues },
		async update(cache) {
			const cacheKey = `${objectType}ScenarioSimulation:${objectId}-${scenarioId}`;

			let existingFragment = cache.readFragment({
				id: cacheKey,
				fragment: gql`
				fragment ${objectType}ScenarioSimulationFragment on ${objectType}ScenarioSimulation {
					id
				}
				`
			});

			if (!existingFragment) {
				await cache.modify({
					id: `${objectType}:${objectId}`,
					fields: {
						scenarios(existingScenarios = [], { readField }) {
							const newScenarioSimulation = cache.writeFragment({
								id: cacheKey,
								data: {
									id: `${objectId}-${scenarioId}`,
									scenarioId,
									...filteredValues,
									__typename: `${objectType}ScenarioSimulation`
								},
								fragment: gql`
									fragment ${objectType}ScenarioSimulationFragment on ${objectType}ScenarioSimulation {
										scenarioId
										${objectType == 'Stakeholder' ? 'x' : ''}
										${objectType == 'Stakeholder' ? 'y' : ''}
										${objectType !== 'Risk' && filteredValues.trend !== undefined ? 'trend' : ''}
										${objectType === 'FishboneItem' && filteredValues.completion !== undefined ? 'completion' : ''}
										${filteredValues.impact !== undefined ? 'impact' : ''}
										${objectType === 'Risk' ? 'likelihood' : ''}
									}
								`
							});

							//console.log("after fragment", cache.data.data[cacheKey], existingScenarios.concat(newScenarioSimulation));
							return [
								...existingScenarios,
								newScenarioSimulation
							];
						}
					}
				});
			} else {
				await cache.modify({
					id: cacheKey,
					fields: getFieldFunctions(values)
				});
			}

			//console.log("after", `${objectType}:${objectId}`, JSON.stringify(cache.data.data[`${objectType}:${objectId}`], null, "\t"));

			console.groupEnd();
		},

		optimisticResponse: {
			[`update${objectType}Scenario`]: true
		},

		broadcast: true
	});
};
