// @ts-nocheck
import React from 'react';

import { Slider as SliderAntd, Tooltip } from 'antd';
import './MiniSlider.sass';
import { keys, range, sortBy } from 'lodash';

let changingTimer = null;
let initialChangeValue = null;
let changeInitialTime = null;

export const MiniSlider = (props: any) => {

	const [isChanging, setIsChanging] = React.useState(false);
	const sliderRef = React.useRef(null);

	let marks = {
		0: ' ',
		16: ' ',
		33: ' ',
		50: ' ',
		66: ' ',
		83: ' ',
		100: ' '
	};

	const min = props.min || 0
	const max = props.max || 100

	if (props.step) {
		marks = {}
		range(1, props.max + props.step, props.step).forEach((i) => {
			marks[i] = ' ';
		});
	}

	let marksCount = keys(marks).length;
	let markWidth = 100 / (marksCount);

	const dynamicProps = {
		...(props.readOnly ? { disabled: true } : {}),
		...(!isChanging || props.readOnly ? { value: props.value || 0 } : {}),
	}

	return (
		<div
			className={
				props.className +
				' MiniSliderWrapper ' +
				(props.readOnly ? 'readonly' : '')
			}
			onClick={props.onClick}
			style={props.style}
		>
			{!props.readOnly && sortBy(keys(marks), (v => parseInt(v))).map((key, index) => {
				return (
					<div
						key={index}
						onClick={(e) => {
							props.onChange && props.onChange(key);
						}}
						data-value={key}
						className="hiddenMark"
						style={{ left: (index * markWidth) + '%', width: "16%" }}
					></div>
				);
			})}

			{
				props.additionalMarkers &&
				props.additionalMarkers.map((m, index) => {

					return (
						<Tooltip key={index}
							title={m.name}
							zIndex={3000}
						>
							<div
								className="marker"
								onClick={() => {
									if (props.onChange && !props.readOnly) props.onChange(m.value);
								}}
								style={{ left: props.max ? (((m.value - min) / (max - min) * 100) + '%') : ((m.value) + '%') }}
							></div>
						</Tooltip>
					);
				})
			}

			{
				props.readOnly ? (
					<SliderAntd
						keyboard={false}

						{...dynamicProps}

						marks={marks}
						step={props.step || null}
						tooltip={{
							open: false
						}}
						min={props.step ? 1 : 0}
						max={props.max || 100}
					/>
				) :
					(
						<SliderAntd
							keyboard={false}
							ref={
								sliderRef
							}
							onFocus={() => {
								sliderRef.current?.blur();
							}}
							defaultValue={props.value || 0}

							{...dynamicProps}

							className={isChanging ? 'changing' : ''}
							onChangeComplete={(value) => {
								sliderRef.current?.blur();

								setIsChanging(false);
								if (props.onChange) props.onChange(value);
							}}
							key={"main"}
							onChange={(value) => {
								if (isChanging) return;

								setIsChanging(true);
								initialChangeValue = value;
							}}

							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								return false;
							}}

							tooltip={{ open: false }}
							included={true}
							marks={marks}
							step={props.step || null}
							min={props.step ? 1 : 0}
							max={props.max || 100}
						/>
					)
			}
		</div>
	);
};

