import { Any } from '@@react-spring/web/types';
import React from 'react';
import FixedImpulseSpinner from './FixedSpinner';

function Loader(props: Any) {
	return (
		<div
			style={{ minHeight: '300px', ...(props.style || {}) }}
			className="Loader w-100 d-flex justify-content-center align-items-center flex-grow-1"
		>
			<FixedImpulseSpinner
				size={props.size || 60}
				frontColor={props.color || '#2EA0DF'}
			/>
		</div>
	);
}

export default Loader;
