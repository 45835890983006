import React, { useContext } from 'react';

import _, {
	findIndex,
	keyBy,
	pick,
	uniq,
	sortBy,
	omit,
	compact,
	values,
	head
} from 'lodash';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Tag } from 'antd';

import { faMinus, faPlus, fal } from '@fortawesome/pro-light-svg-icons';
import { FoldableTagList } from 'components/List';

import { IconPicker } from 'components/IconPicker';
import MainRadar from 'components/Radar/MainRadar';
import AddButton from 'components/action/AddButton';
import StakeholderModal from 'components/Modal/StakeholderModal';
import { SummaryColumn } from './SummaryColumn/SummaryColumn';

import Weight from 'components/controls/Weight';
import LinksTab from './Tabs/LinksTab';
import CommentsTab from './Tabs/CommentsTab';
import GeneralTab from './Tabs/GeneralTab';

import { ConfirmModal } from 'components/Modal/ConfirmModal';

import './Navigator.sass';

import useScreenSize from 'hooks/useScreenSize';
import { remainingSpace } from 'utils/layoutUtils';

import { StakeholderModel } from 'model/StakeholderModel';
import BotTab from 'components/tabs/BotTab';

import { filterStakeholders } from './Navigator.utils';
import Tutorial from 'components/Tutorial/Tutorial';
import SubHeaderPortal from 'layout/header/SubHeaderPortal';

import UIContext from 'components/Utils/UIContext';
import { MaskableComponent } from 'components/Tutorial/MaskableComponent';
import { SideMenu } from 'layout/SideMenu';
import { ExportTab } from './Tabs/ExportTab';

// Props
interface NavigatorProps {
	stakeholders: Array<any>;
	scenario: any;
	scenarios: Array<any>;
	selection: any;
	searchTerm: String;
	setSearchTerm: Function;
	select: Function;
	deselect: Function;
	refetch: Function;
	getProject: any;
	portalContent: any;
	expanded: any;
	features: any;
	tags: any;
	aiConsent: string | null;

	indicators: any;
	constraints: any;
	risks: any;

	displayConstraints: boolean;
	displayIndicators: boolean;
	displayRisks: boolean;
	onCreateTag: (name: string, callback: (tagId: string) => void) => void;

	pushModal: Function;
	popModal: Function;

	preferences: any;
	setPreferences: any;

	botThread: any;
	recentCreations: any;
	recentUpdates: any;

	executeAction: Function;

	// New handler props
	onCreateStakeholder: (values: any, defaults: any) => Promise<any>;
	onDeleteStakeholder: (id: string) => Promise<void>;
	onCreateStakeholderLink: (origin: any, target: any) => Promise<any>;
	onDeleteStakeholderLink: (stakeholderId: string, id: string) => Promise<void>;
	onChangeStakeholderLinkStrength: (link: any, strength: number) => any;
	onChangeStakeholder: (id: string, values: any) => Promise<void>;
	onUpdateStakeholderTags: (stakeholder: any, tags: any[]) => Promise<void>;
	resetBotThread?: Function;
	addBotMessage?: Function;
}

export const Navigator = (props: NavigatorProps) => {
	const params: any = useParams();
	const location = useLocation();
	const { t } = useTranslation();

	const { stakeholders = [], indicators = [], constraints = [], risks = [], tags = [] } = props;

	const [editedStakeholder, setEditedStakeholder] = React.useState<
		any | null
	>(null);
	const [mode, setMode] = React.useState<any | null>({ name: 'initial' });
	const [isTransitioning, setIsTransitioning] = React.useState<any | null>(
		false
	);

	const areAllLinksVisible = props.preferences?.pages?.navigator?.links || false
	const areTagsVisible = props.preferences?.pages?.navigator?.tags || false
	const areConstraintsVisible = props.preferences?.pages?.navigator?.constraints || false
	const areIndicatorsVisible = props.preferences?.pages?.navigator?.indicators || false
	const areRisksVisible = props.preferences?.pages?.navigator?.risks || false
	const areOpportunitiesVisible = props.preferences?.pages?.navigator?.opportunities || false

	const uiContext = useContext(UIContext);

	const [tagFilter, setTagFilter] = React.useState<any | null>([]);
	const [iconFilter, setIconFilter] = React.useState<any | null>(null);
	const [indicatorFilter, setIndicatorFilter] = React.useState<any | null>(
		null
	);
	const [constraintFilter, setConstraintFilter] = React.useState<any | null>(
		null
	);

	const [riskFilter, setRiskFilter] = React.useState<any | null>(location?.state?.riskFilter || null);

	let indexedRisks = keyBy(risks, 'id');

	// Usage
	let usedRisks = {};
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedRisks) {
			s.impactedRisks.forEach((r) => {
				if (indexedRisks[r.riskId] && !indexedRisks[r.riskId].isOpportunity)
					usedRisks[r.riskId] = true;
			})
		}
	})

	let usedOpportunities = {};
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedRisks) {
			s.impactedRisks.forEach((r) => {
				if (indexedRisks[r.riskId] && indexedRisks[r.riskId].isOpportunity)
					usedOpportunities[r.riskId] = true;
			})
		}
	})


	let usedTags = {};
	const indexedTags = keyBy(tags, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.taggings) {
			s.taggings.forEach((t) => {
				if (indexedTags[t.tagId]) {
					usedTags[t.tagId] = true;
				}
			})
		}
	})

	let usedIndicators = {};
	const indexedIndicators = keyBy(indicators, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedIndicators) {
			s.impactedIndicators.forEach((i) => {
				if (indexedIndicators[i.indicatorId]) {
					usedIndicators[i.indicatorId] = true;
				}

			})
		}
	})

	let usedConstraints = {};
	const indexedConstraints = keyBy(constraints, 'id');
	stakeholders.forEach((s: StakeholderModel) => {
		if (s.impactedConstraints) {
			s.impactedConstraints.forEach((c) => {
				if (indexedConstraints[c.constraintId]) {
					usedConstraints[c.constraintId] = true;
				}
			})
		}
	})

	// Removed state variables as FoldableTagList manages its own showAll state internally
	// const [showAllConstraints, setShowAllConstraints] = React.useState<any | null>(false);
	// const [showAllIndicators, setShowAllIndicators] = React.useState<any | null>(false);
	const [showAllRisks, setShowAllRisks] = React.useState<any | null>(false);
	// const [showAllOpportunities, setShowAllOpportunities] = React.useState<any | null>(false);

	const [hasRadarLegend, setHasRadarLegend] = React.useState<true | false>(
		true
	);
	const isFullMode = props.features?.fishbone;

	const allRisks = sortBy(risks, 'name').filter(r => !r.isOpportunity);
	const allOpportunities = sortBy(risks, 'name').filter(r => r.isOpportunity);

	const filteredStakeholders = filterStakeholders(
		stakeholders,
		{
			name: props.searchTerm,
			tags: tagFilter,
			icon: iconFilter,
			indicator: indicatorFilter,
			constraint: constraintFilter,
			risk: riskFilter
		},
		props.scenario
	);

	const hasFilters =
		props.searchTerm !== '' ||
		tagFilter.length > 0 ||
		!!iconFilter ||
		!!indicatorFilter ||
		!!constraintFilter;

	// Timer for animation
	const updateTimer = React.useRef(null);
	React.useEffect(() => {
		if (!updateTimer.current) {
			setIsTransitioning(true);
		}
	}, [props.scenario]);

	const screenSize = useScreenSize();
	let blocks = compact([
		{ id: 'header', verticalAlign: 'top', height: 70 },
		{ id: 'summary', horizontalAlign: 'left', width: 320 },
		{ id: 'right', horizontalAlign: 'right', width: 310 },
		{ id: 'bottom', verticalAlign: 'bottom', height: 10 }
	]);

	let remainingSpaceForCenter = remainingSpace({
		...screenSize,
		blocks
	});

	// Enable / Disable left text close to radar
	let maxWidth = Math.min(
		remainingSpaceForCenter.width,
		remainingSpaceForCenter.height
	);
	let offsetX =
		(remainingSpaceForCenter.width - maxWidth) / 2 +
		remainingSpaceForCenter.x1;
	let offsetY = remainingSpaceForCenter.y1;

	if (
		remainingSpaceForCenter.width < remainingSpaceForCenter.height ||
		offsetX < 350
	) {
		if (hasRadarLegend) {
			setHasRadarLegend(false);
		}
	} else {
		if (!hasRadarLegend) {
			setHasRadarLegend(true);
		}
	}

	const allTags: any = sortBy(tags, 'name');

	const allIcons: any = uniq(
		stakeholders.map((i: any) => (i.icon ? i.icon : 'faUser'))
	).sort();

	const indexedStakeholders = keyBy(stakeholders, 'id');

	// Create
	const handleCreateStakeholder = async (values, defaults) => {
		await props.onCreateStakeholder(values, defaults);
		if (defaults?.id) {
			if (props.selection)
				props.select(defaults);
		}
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.item.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDeleteStakeholder(id)}
				message={t(
					'models.item.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	// Delete
	const handleDeleteStakeholder = async (id) => {
		await props.onDeleteStakeholder(id);
	};

	// Edit
	const handleEditStakeholder = async (id) => {
		let index = findIndex(stakeholders, (s: any) => s.id === id);
		setEditedStakeholder(stakeholders[index]);
	};

	const handleChange = (action: string, value: string) => {
		if (!props.selection) return;

		if (action == 'trend') {
			props.onChangeStakeholder(props.selection?.id, { trend: value });
		} else if (action == 'impact') {
			props.onChangeStakeholder(props.selection?.id, { impact: value });
		} else if (action == 'edit') {
			setEditedStakeholder(props.selection);
		} else if (action == 'link') {
			setMode({ name: 'linking', origin: props.selection });
		}
	};

	const select = async (item, callback) => {
		if (mode.name == 'initial') {
			props.select(item, callback);
		} else if (mode.name == 'linking') {
			if (
				item &&
				item.id &&
				mode.origin &&
				mode.origin.id &&
				item.id !== mode.origin.id
			) {
				await props.onCreateStakeholderLink(mode.origin, item);
				await props.select(item);
			}

			setMode({ name: 'initial' });
		}
	};

	return (
		<div
			id="Navigator"
			className={
				'Navigator ' +
				mode.name +
				' ' +
				(props.expanded ? 'expanded' : '')
			}
		>
			<SubHeaderPortal>
				<Tutorial
					type="stakeholders"
					context={{ ...props, areAllLinksVisible }}
					executeAction={props.executeAction} projectId={params.id} />
			</SubHeaderPortal>

			<SummaryColumn
				isMasked={uiContext.maskedZones['summary']}
				isFiltered={hasFilters}
				onIndicatorClick={(i: any) => {
					indicatorFilter == i.id
						? setIndicatorFilter(null)
						: setIndicatorFilter(i.id);
					props.select(null);
				}}
				onConstraintClick={(i: any) => {
					constraintFilter == i.id
						? setConstraintFilter(null)
						: setConstraintFilter(i.id);
					props.select(null);
				}}
				indicatorFilter={indicatorFilter}
				constraintFilter={constraintFilter}

				{...props}

				stakeholders={filteredStakeholders}
				scenario={props.scenario}
				scenarios={props.scenarios}
				pushModal={props.pushModal}
				advanced={props.displayConstraints || props.displayIndicators}
				popModal={props.popModal}

				likelihoods={props.likelihoods}
				impacts={props.impacts}

				areConstraintsVisible={areConstraintsVisible}
				areIndicatorsVisible={areIndicatorsVisible}
				areRisksVisible={areRisksVisible}
				areOpportunitiesVisible={areOpportunitiesVisible}
				setPreferences={props.setPreferences}
			>

			</SummaryColumn>


			<div
				style={{
					position: 'absolute',
					top: remainingSpaceForCenter.y1 + 'px',
					left: offsetX + 'px',
					width: maxWidth + 'px',
					height: maxWidth + 'px',
					zIndex: 0
				}}
			></div>

			<div
				className={`Body fw${remainingSpaceForCenter.width} x1_${remainingSpaceForCenter.x1} w${maxWidth} h${maxWidth} oX${offsetX} oY${offsetY}`}
				style={{
					position: 'absolute',
					width: `${maxWidth}px`,
					height: `${maxWidth}px`,
					top: remainingSpaceForCenter.y1 + 'px',
					left: offsetX + 'px'
				}}
			>
				<MaskableComponent masked={uiContext.maskedZones['main']}>
					{maxWidth && (
						<MainRadar
							offsetX={offsetX}
							offsetY={offsetY}
							width={maxWidth}
							height={maxWidth}
							selection={props.selection}
							select={select}
							mode={mode}
							scenario={props.scenario}
							deselect={props.deselect}
							onClickLink={props.onChangeStakeholderLinkStrength}
							onChange={handleChange}

							onUpdateItem={props.onChangeStakeholder}
							displayAllLinks={areAllLinksVisible}
							isTransitioning={isTransitioning}
							isLegendVisible={hasRadarLegend}
							recentCreations={props.recentCreations}
						>{filteredStakeholders}</MainRadar>
					)}</MaskableComponent>
			</div>

			<div
				className="GaugeWrapper"
				style={{
					width: isFullMode ? maxWidth / 6 : maxWidth / 4 + 'px',
					marginTop: maxWidth / 4 / 2 + 'px'
				}}
			></div>

			<MaskableComponent masked={uiContext.maskedZones['add']}>
				<AddButton
					id="AddStakeholder"
					tooltip={t('models.item.actions.create_new')}
					onClick={() => {
						setEditedStakeholder({});
					}}
					className={'opened'}
				></AddButton>
			</MaskableComponent>

			<SideMenu defaultTab={props.selection ? "general" : "filters"} tabs={[
				props.selection && {
					id: 'general',
					title: t('pages.navigator.tabs.general.title')
				},
				props.selection && {
					id: 'links',
					title: t('pages.navigator.tabs.links.title')
				},
				props.selection && {
					id: 'comments',
					title: t('pages.navigator.tabs.comments.title')
				},
				!props.selection && {
					id: 'filters',
					title: t('pages.navigator.tabs.filters.title')
				},
				!props.selection && {
					id: 'bot',
					title: t('pages.navigator.tabs.bot.title')
				},
				{
					id: 'export',
					title: t('pages.navigator.tabs.export.title')
				},
			]}>
				{function (selectedTab) {
					return <>
						{
							selectedTab == "export" ?
								<ExportTab>

								</ExportTab> :
								props.selection ? (
									<>
										<div className="d-flex flex-row align-items-center">
											<div
												className="IconPicker"
												style={{ marginRight: '1rem' }}
											>
												<IconPicker
													value={
														props.selection?.icon ||
														'faUser'
													}
													favorites={allIcons}
													disabled={!!props.scenario}
													onChange={
														props.scenario
															? () => { }
															: (v) =>
																props.onChangeStakeholder(
																	props.selection.id,
																	{ icon: v }
																)
													}
												/>
											</div>

											<div>{props.selection?.name}</div>
										</div>

										{selectedTab == 'general' && (
											<GeneralTab
												selection={
													indexedStakeholders[
													props.selection?.id
													]
												}
												indicators={indicators}
												constraints={constraints}
												onEdit={handleEditStakeholder}
												onClone={(defaults) =>
													handleCreateStakeholder(
														{},
														defaults
													)
												}
												onDelete={askDeleteConfirmation}
												scenario={props.scenario}
												displayConstraints={
													props.displayConstraints
												}
												displayIndicators={
													props.displayIndicators
												}
												stakeholders={stakeholders}
												indexedStakeholders={indexedStakeholders}
												onDeleteStakeholderLink={
													props.onDeleteStakeholderLink
												}
												onChangeStakeholderLinkStrength={
													props.onChangeStakeholderLinkStrength
												}
												onCreateLink={() =>
													handleChange('link')
												}
												select={props.select}
												risks={props.risks}

												tags={allTags}
												onUpdateStakeholderTags={props.onUpdateStakeholderTags}
												onCreateTag={props.onCreateTag}

												areTagsVisible={areTagsVisible}
												areConstraintsVisible={areConstraintsVisible}
												areIndicatorsVisible={areIndicatorsVisible}
												areRisksVisible={areRisksVisible}
												areOpportunitiesVisible={areOpportunitiesVisible}
												setPreferences={props.setPreferences}
											></GeneralTab>
										)}

										{selectedTab == 'links' && (
											<LinksTab
												indexedStakeholders={
													indexedStakeholders
												}
												stakeholders={stakeholders}
												onDeleteStakeholderLink={
													props.onDeleteStakeholderLink
												}
												onChangeStakeholderLinkStrength={
													props.onChangeStakeholderLinkStrength
												}
												onCreateLink={() =>
													handleChange('link')
												}
												selection={props.selection}
												scenario={props.scenario}
											></LinksTab>
										)}

										{selectedTab == 'comments' && (
											<CommentsTab
												indexedStakeholders={
													indexedStakeholders
												}
												selection={props.selection}
												pushModal={props.pushModal}
												popModal={props.popModal}
												scenario={props.scenario}
											></CommentsTab>
										)}
									</>
								) : selectedTab == "bot" ? <>
									<BotTab
										consent={props.aiConsent}
										projectId={params.id}
										messages={props.botThread?.messages || []}
										onResetThread={props.resetBotThread}
										onSendMessage={(message, callback) => props.addBotMessage(params.id, props.aiConsent === "FULL" ? props.scenario?.id : null, "stakeholders", message, callback)}
									>

									</BotTab>
								</> :
									(
										<div className='noselection'>
											<Input
												onChange={(e) => {
													props.setSearchTerm(e.target.value);
												}}
												value={props.searchTerm}
												style={{
													marginLeft: 'auto',
													marginRight: '1em'
												}}
												placeholder="Search"
												className="SearchInput mt-2"
											></Input>

											<ul>
												<li className="EditableListItem nomargin mt-2">
													<div style={{ textAlign: 'left' }}>
														{t('pages.navigator.links')}
													</div>
													<Weight
														count={1}
														onClick={() => {
															props.setPreferences(
																'pages',
																'navigator.links',
																!areAllLinksVisible
															);
														}}
													>
														{areAllLinksVisible ? 1 : 0}
													</Weight>
												</li>
											</ul>

											<FoldableTagList
												title={t('pages.navigator.all_icons')}
												showAllTags={true}
												tags={allIcons.map(icon => ({
													id: icon, name: '', content: (
														<FontAwesomeIcon
															style={{ fontSize: '0.9rem' }}
															icon={fal[icon]}
														/>
													)
												}))}
												usedTags={keyBy(allIcons)}
												selectedTags={iconFilter ? [iconFilter] : []}
												emptyMessage={t('pages.navigator.no_icon')}
												onTagClick={(iconId) => {
													iconFilter === iconId
														? setIconFilter(null)
														: setIconFilter(iconId);
												}}
												className="icon-list"
											/>

											<FoldableTagList
												title={t('pages.navigator.all_tags')}
												tags={allTags}
												usedTags={usedTags}
												selectedTags={tagFilter}
												emptyMessage={t('pages.navigator.no_tag')}
												onTagClick={(tagId) => {
													// Add filter if not present, remove it if already present
													tagFilter.includes(tagId)
														? setTagFilter(tagFilter.filter(tag => tag !== tagId))
														: setTagFilter([...tagFilter, tagId]);
												}}
											/>

											{props.displayConstraints && (
												<FoldableTagList
													title={t('pages.navigator.all_constraints')}
													tags={sortBy(constraints, 'name')}
													usedTags={usedConstraints}
													selectedTags={constraintFilter ? [constraintFilter] : []}
													emptyMessage={t('models.constraint.no_x')}
													className="ImpactedConstraint"
													onTagClick={(constraintId) => {
														constraintFilter === constraintId
															? setConstraintFilter(null)
															: setConstraintFilter(constraintId);
														props.select(null);
													}}
												/>
											)}

											{props.displayIndicators && (
												<FoldableTagList
													title={t('pages.navigator.all_indicators')}
													tags={sortBy(indicators, 'name')}
													usedTags={usedIndicators}
													selectedTags={indicatorFilter ? [indicatorFilter] : []}
													emptyMessage={t('models.indicator.no_x')}
													className="ImpactedIndicator"
													onTagClick={(indicatorId) => {
														indicatorFilter === indicatorId
															? setIndicatorFilter(null)
															: setIndicatorFilter(indicatorId);
														props.select(null);
													}}
												/>
											)}

											{props.displayRisks && (
												<>
													<FoldableTagList
														title={t('pages.navigator.all_risks')}
														tags={allRisks}
														usedTags={usedRisks}
														selectedTags={riskFilter ? [riskFilter] : []}
														emptyMessage={t('models.risk.messages.no_x')}
														className="ImpactedRisk"
														onTagClick={(riskId) => {
															riskFilter === riskId
																? setRiskFilter(null)
																: setRiskFilter(riskId);
															props.select(null);
														}}
													/>

													<FoldableTagList
														title={t('pages.navigator.all_opportunities')}
														tags={allOpportunities}
														usedTags={usedOpportunities}
														selectedTags={riskFilter ? [riskFilter] : []}
														emptyMessage={t('models.risk.messages.no_x_positive')}
														className="ImpactedRisk"
														onTagClick={(riskId) => {
															riskFilter === riskId
																? setRiskFilter(null)
																: setRiskFilter(riskId);
															props.select(null);
														}}
													/>
												</>
											)}
										</div>
									)
						}
					</>
				}}
			</SideMenu>

			{
				editedStakeholder && (
					<StakeholderModal
						onHide={() => {
							setEditedStakeholder(null);
						}}
						onSave={(values: Object) => {
							editedStakeholder.id
								? props.onChangeStakeholder(editedStakeholder.id, {
									...pick(editedStakeholder, [
										'name',
										'description',
										'impact',
										'trend',
										'icon'
									]),
									...values
								})
								: handleCreateStakeholder(
									{
										...values
									},
									{}
								);
							setEditedStakeholder(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(editedStakeholder.id);
							setEditedStakeholder(null);
						}}
						id={editedStakeholder?.id}
						values={{
							name: editedStakeholder?.name,
							description: editedStakeholder?.description,
							impact: editedStakeholder?.impact
						}}
					></StakeholderModal>
				)
			}
		</div >
	);
};

export default Navigator;