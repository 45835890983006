import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router';
import { useStakeholderController } from 'hooks/useStakeholderController';
import { getUpdatedObject } from 'utils/graphql';
import { Navigator } from './Navigator';
import { keyBy } from 'lodash';
interface NavigatorContainerProps {
  stakeholders: Array<any>;
  scenario: any;
  scenarios: Array<any>;
  selection: any;
  searchTerm: String;
  setSearchTerm: Function;
  select: Function;
  deselect: Function;
  refetch: Function;
  getProject: any;
  portalContent: any;
  expanded: any;
  features: any;
  tags: any;
  aiConsent: string | null;

  indicators: any;
  constraints: any;
  risks: any;

  displayConstraints: boolean;
  displayIndicators: boolean;
  displayRisks: boolean;
  onCreateTag: (name: string, callback: (tagId: string) => void) => void;

  pushModal: Function;
  popModal: Function;

  preferences: any;
  setPreferences: any;

  botThread: any;
  recentCreations: any;
  recentUpdates: any;

  executeAction: Function;
  resetBotThread?: Function;
  addBotMessage?: Function;
}

const NavigatorContainer = (props: NavigatorContainerProps) => {
  const params: any = useParams();
  const location = useLocation();
  const {
    createStakeholder,
    updateStakeholder,
    deleteStakeholder,
    createTag,
    changeStakeholderTags,
    createStakeholderLink,
    deleteStakeholderLink,
    changeStakeholderLinkStrength
  } = useStakeholderController();

  // Using functions from useStakeholderController

  // Handler functions
  const handleCreateStakeholder = async (values, defaults) => {
    const result = await createStakeholder(params.id, props.stakeholders, values, defaults);
    if (defaults?.id) {
      if (props.selection)
        props.select(result);
    }
    return result;
  };

  const handleDeleteStakeholder = async (id) => {
    await deleteStakeholder(params.id, id);
    props.deselect();
    props.refetch();
  };

  const handleCreateStakeholderLink = async (origin, target) => {
    return await createStakeholderLink(params.id, origin, target);
  };

  const handleDeleteStakeholderLink = async (stakeholderId: string, id: string) => {
    await deleteStakeholderLink(params.id, stakeholderId, id);
  };

  const handleChangeStakeholderLinkStrength = (link: any, strength: number) => {
    return changeStakeholderLinkStrength(link, strength);
  };

  const handleChangeStakeholder = async (id: string, values: any) => {
    await updateStakeholder(id, props.stakeholders, props.scenario, values);
    if (props.selection) props.select(getUpdatedObject(props.selection, props.scenario, values));
  };

  const handleCreateTag = async (name: string) => {
    await props.onCreateTag(name, async (tagId) => {
      await createTag(params.id, props.selection.id, tagId);

      props.refetch();
      setTimeout(() => {
        const indexedStakeholders = keyBy(props.stakeholders, 'id');
        const stakeholder = indexedStakeholders[props.selection.id];

        props.select({
          ...stakeholder,
          taggings: [
            ...(stakeholder.taggings || []),
            {
              __typename: 'Tagging',
              id: props.selection.id + '-' + tagId,
              tagId,
              objectId: props.selection.id
            }
          ]
        });
      }, 200);
    });
  };

  const handleChangeStakeholderTags = async (stakeholder, tags) => {
    // Extract tag IDs from the tags array
    const newTagIds = tags.map((t) => t.tagId || t.id);

    // Use the controller's changeStakeholderTags function
    await changeStakeholderTags(params.id, stakeholder, newTagIds);

    // Format the tags for the UI update
    const allTaggings = newTagIds.map(tagId => ({
      id: stakeholder.id + '-' + tagId,
      tagId: tagId,
      objectId: stakeholder.id,
      type: 'stakeholder',
    }));

    // Update the selection in the UI
    if (props.selection)
      props.select({ ...props.selection, taggings: allTaggings });

    await props.refetch();
  };

  return (
    <Navigator
      {...props}
      onCreateStakeholder={handleCreateStakeholder}
      onDeleteStakeholder={handleDeleteStakeholder}
      onCreateStakeholderLink={handleCreateStakeholderLink}
      onDeleteStakeholderLink={handleDeleteStakeholderLink}
      onChangeStakeholderLinkStrength={handleChangeStakeholderLinkStrength}
      onChangeStakeholder={handleChangeStakeholder}
      onCreateTag={handleCreateTag}
      onUpdateStakeholderTags={handleChangeStakeholderTags}
      resetBotThread={props.resetBotThread}
      addBotMessage={props.addBotMessage}
    />
  );
};

export default NavigatorContainer;