import React from 'react';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClone,
	faEdit,
	faHouseFlag
} from '@fortawesome/pro-light-svg-icons';

import AddButton from 'components/action/AddButton';
import ScenarioEditionModal from 'components/Modal/ScenarioEditionModal';
import HeaderPortal from 'layout/header/HeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

// Help imports removed

import Nav from './Nav';

import { Button, Tabs, Tooltip } from 'antd';
import { scenarioScore } from 'utils/ranking';
import { ScenarioResult } from './ScenarioResult';

import { SynthesisGraph } from '../Navigator/SummaryColumn/SynthesisGraph';
import { useScenarioController } from '../../../hooks/useScenarioController';
import Table from 'components/Table';
import { keyBy, keys, sortBy } from 'lodash';
import { useProjectController } from 'hooks/useProjectController';

import './Scenarios.sass';
import PromoteAsBaselineModal from 'components/Modal/PromoteAsBaselineModal';

export const Scenarios = (props: any) => {

	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const params: any = useParams();

	const { createScenario, updateScenario, deleteScenario, promoteScenarioAsBaseline } = useScenarioController();
	const { updateProject } = useProjectController();

	const { scenarios = [] } = props;

	const { t } = useTranslation();

	// Help section removed

	let scores = [...scenarios.map((sc) => {
		return {
			id: sc.id,
			score: scenarioScore(props, sc)
		};
	}),
	{
		id: null,
		score: scenarioScore(props, null)
	}
	];

	const indexedScenarioScores = keyBy(scores, "id");

	// Create
	const handleAskToCreateScenario = async (referenceScenario: any) => {
		props.pushModal(
			<ScenarioEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) =>
					createScenario(params.id, props, referenceScenario, values, () => {
						props.popModal();
					})
				}
				id={false}
				values={{
					name:
						referenceScenario && referenceScenario.name
							? referenceScenario.name + ' - Copy'
							: '',
					description:
						referenceScenario && referenceScenario.description
							? referenceScenario.description
							: '',
					impacts:
						referenceScenario && referenceScenario.impacts
							? referenceScenario.impacts
							: ''
				}}
			></ScenarioEditionModal>
		);
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.indicator.messages.delete_confirmation')}
				t={t}
				onConfirm={async () => {

					if (id == null) {
						return;
					}
					props.onGoToPreviousScenario();
					await deleteScenario(params.id, id);

				}}
				message={
					t(
						'models.indicator.messages.delete_confirmation_explanation'
					)
				}
			></ConfirmModal >
		);
	};

	const askPromoteAsBaselineConfirmation = async (scenario) => {

		props.pushModal(
			<PromoteAsBaselineModal
				t={t}
				onSave={async (values) => {
					await promoteScenarioAsBaseline(props, scenario, values.keepCurrentBaseline, values.name, values.keepScenario);
					setEditedItem(null);
					props.onGoToScenario(null);
				}}
				onCancel={() => {
					setEditedItem(null);
				}}
				message={t(
					'models.scenario.messages.promote_as_baseline_explanation'
				)}
				values={{
					keepCurrentBaseline: true,
					keepScenario: true
				}}
				scenario={scenario}
			></PromoteAsBaselineModal>
		);
	};

	const columns = [
		{
			title: '',
			dataIndex: 'edit',
			key: 'edit',
			width: '125px',
			className: 'nowrap',
			render: (text, record) => (
				<>
					{<Tooltip title={t("models.scenario.actions.edit")}>
						<Button
							shape="circle"
							onClick={
								props.disabled
									? () => { }
									: () => {
										setEditedItem(record);
									}
							}
						>
							<FontAwesomeIcon
								icon={faEdit}
								style={{
									cursor: props.disabled
										? 'not-allowed'
										: 'initial',
									opacity: props.disabled ? 0.5 : 1
								}}
							/>
						</Button>
					</Tooltip>}
					<Tooltip title={t("models.scenario.actions.clone")}>

						<Button
							shape="circle"
							style={{ marginLeft: '0.5rem' }}
							onClick={() => {
								handleAskToCreateScenario(record);
							}}
						>
							<FontAwesomeIcon
								icon={faClone}
								style={{
									cursor: props.disabled
										? 'not-allowed'
										: 'initial',
									opacity: props.disabled ? 0.5 : 1
								}}
							/>
						</Button>

					</Tooltip>

					{record.id && (
						<Tooltip title={t('models.scenario.messages.promote_as_baseline.title')}>
							<Button
								shape="circle"
								className="ml-05"
								onClick={() => {
									askPromoteAsBaselineConfirmation(record);
									setEditedItem(null);
								}}
							>
								<FontAwesomeIcon
									icon={faHouseFlag}
								></FontAwesomeIcon>
							</Button>{' '}
						</Tooltip>
					)}
				</>
			)
		},
		{
			title: t("models.scenario.fields.name"),
			dataIndex: 'name',
			key: 'name',
			width: 'auto',
			sortable: true,
			ellipsis: true,
			render: (text, record) => {
				return (
					<div className="name">
						{record.name}
						{record.id && record.description ? (
							<div className="description">
								{record.description}
							</div>
						) : (!record.id ?
							<div className="description">
								{props?.baselineScenarioDescription}
							</div>
							:
							"")}
					</div>
				);
			}
		},
		{
			title: t("models.scenario.fields.score"),
			dataIndex: 'scenario_score',
			key: 'scenario_score',
			width: '15%',
			sortable: (a: object, b: object) => {
				const scoreA = indexedScenarioScores[a.id]?.score;
				const scoreB = indexedScenarioScores[b.id]?.score;

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, record) => {
				const score = indexedScenarioScores[record.id]?.score?.toFixed(0);

				return <div className="ml-auto">{score}</div>;
			}
		}
	];

	return (
		<div className="Scenarios pt-1 pb-3">
			<HeaderPortal>
				{props.portalContent}
			</HeaderPortal>

			<h2>{t('pages.scenarios.title')}</h2>

			{editedItem && (
				<ScenarioEditionModal
					onHide={() => {
						setEditedItem(null);
					}}
					onDelete={() => {
						askDeleteConfirmation(editedItem.id);
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						let variables = {}
						if (values.description !== undefined) variables['baselineScenarioDescription'] = values.description
						if (values.impacts !== undefined) variables['baselineScenarioImpacts'] = values.impacts

						if (!editedItem.id) {
							if (keys(variables).length > 0) {
								updateProject(params.id, variables);
							}

						} else {
							updateScenario(editedItem.id, scenarios, values);
						}

						setEditedItem(null);

					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.id ? editedItem?.description || '' : props.baselineScenarioDescription || '',
						impacts: editedItem?.id ? editedItem?.impacts || '' : props.baselineScenarioImpacts || ''
					}}
				></ScenarioEditionModal>
			)
			}

			<Tabs
				type="card"
				className="m-auto w-100"
				style={{ width: '100%', maxWidth: '1150px', overflowX: 'hidden' }}
				items={[
					{
						key: "1",
						label: t("pages.scenarios.tabs.list"),
						children: (
							<>
								<h3 className="mb-4">{t('pages.scenarios.subtitle')}</h3>

								<div
									className="mb-3 m-auto w-100"
									style={{ maxWidth: '1000px' }}
								>
									<Table
										pagination={false}
										dataSource={[
											{
												id: null,
												name: t("models.scenario.baseline"),
												key: 'baseline',
												description: '',
												impacts: ''
											}
										].concat(scenarios).map(s => ({
											...s,
											key: s.id
										}))}
										columns={columns}
										size="small"
									/>
								</div>

								<div className="w-100 d-flex justify-content-center align-items-center">
									<div
										className="w-100 d-flex justify-content-center align-items-center"
									>
										{
											<AddButton
												onClick={() => handleAskToCreateScenario()}
											/>
										}
									</div>
								</div>
							</>
						)
					},
					{
						key: "3",
						label: t("pages.scenarios.tabs.details"),
						children: <ScenarioResult {...{ ...props, scenarios }} />
					},
					...(props.features?.indicators && props.type !== 'stakeholders' ? [
						{
							key: "2",
							label: t("pages.scenarios.tabs.visualization"),
							children: (
								<>
									<Nav
										fullWidth
										count={scenarios.length}
										options={scenarios}
										onGoToPreviousScenario={props.onGoToPreviousScenario}
										onGoToNextScenario={props.onGoToNextScenario}
									>
										<h2 title={scenarios ? props.scenario?.id : ''}>
											{props.scenario?.name || t("models.scenario.baseline")}{' '}
										</h2>
									</Nav>
									<div
										style={{
											width: '700px',
											height: '700px',
											margin: 'auto'
										}}
									>
										<SynthesisGraph
											indicators={props.indicators}
											constraints={props.constraints}
											stakeholders={props.stakeholders}
											scenario={props.scenario}
											scenarios={scenarios}
											risks={props.risks}
											fishboneBranchs={props.fishboneBranchs}
											likelihoods={props.likelihoods}
											impacts={props.impacts}
											features={props.features}
											score={indexedScenarioScores[props.scenario?.id || null]?.score}
											indexedScores={indexedScenarioScores}
											onClick={(index: number) => {
												props.onGoToScenario(index);
											}}
										/>
									</div>
								</>
							)
						}
					] : [])
				]}
			/>
		</div >
	);
};