// @ts-nocheck
import React from 'react';
import { Tooltip } from 'antd';
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from 'react-with-gesture';

import './ActionButton.sass';

export const ActionButton = (props: any) => {
	const [bind, { down }] = useGesture();
	const { x, size } = useSpring({
		size: down ? 1.1 : 1,
		immediate: (name: string) => down && name === 'x'
	});

	const body = (
		<div className={props.className} id={props.id} style={props.style}>
			<animated.div
				{...bind()}
				style={{
					transform: size.to(s => `scale(${s})`)
				}}
			>
				{props.popover ? (
					React.cloneElement(props.popover, {
						children: (
							<div
								onClick={props.onClick}
								className="ActionButton"
							>
								{props.children}
							</div>
						)
					})
				) : (
					<div onClick={props.onClick} className="ActionButton">
						{props.children}
					</div>
				)}
			</animated.div>
		</div>
	);

	return props.tooltip ? (
		<Tooltip title={props.tooltip}>{body}</Tooltip>
	) : (
		body
	);
};

export default ActionButton;
