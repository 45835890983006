import { isArray, keyBy } from 'lodash';
import {
	colorToPercent,
	getIndicatorOrConstraintScore,
	getIndividualRiskScore,
	getRiskScore
} from 'utils/ranking';

export const getItemAlteredByScenario = (item, scenario) => {
	if (!scenario?.id) return item?.o || item;

	let originalItem = item?.o || item;

	//console.group('getItemAlteredByScenario');

	//console.log('item', item, scenario);

	if (
		originalItem &&
		originalItem.scenarios &&
		isArray(originalItem.scenarios)
	) {
		const indexedScenarios = isArray(originalItem.scenarios)
			? keyBy(originalItem.scenarios, 'scenarioId')
			: {};
		let attributes = indexedScenarios[scenario?.id]
			? indexedScenarios[scenario?.id]
			: {};

		//console.log('attributes', attributes);

		let impact =
			attributes?.impact !== undefined
				? attributes?.impact
				: originalItem?.impact;
		if (originalItem.__typename === 'Risk') {
			impact = attributes?.impact || originalItem?.impact;
		}

		//console.groupEnd('getoriginalItemAlteredByScenario');
		return {
			...originalItem,
			x: attributes.x !== undefined ? attributes.x : originalItem.x,
			y: attributes.y !== undefined ? attributes.y : originalItem.y,
			trend:
				attributes.trend !== undefined
					? attributes.trend
					: originalItem.trend,
			likelihood: attributes.likelihood || originalItem.likelihood,
			impact: impact,
			completion: attributes.completion || originalItem.completion,
			o: {
				...(originalItem.o || originalItem)
			}
		};
	}
	if (originalItem && typeof originalItem.scenarios === 'string') {
		let sc = originalItem.scenarios
			? JSON.parse(originalItem.scenarios)
			: {};
		let attributes = sc && sc[scenario?.id] ? sc[scenario?.id] : {};

		//console.groupEnd('getoriginalItemAlteredByScenario');

		return {
			...originalItem,
			x: attributes.x !== undefined ? attributes.x : originalItem.x,
			y: attributes.y !== undefined ? attributes.y : originalItem.y,
			trend:
				attributes.trend !== undefined
					? attributes.trend
					: originalItem.trend,
			impact:
				attributes?.impact !== undefined
					? attributes?.impact
					: originalItem?.impact,
			o: {
				...originalItem
			}
		};
	}
};

export const vulnerabilityConstraints = function (constraints) {
	//console.log('-------- vulnerability', items);

	if (!constraints || constraints.length == 0) {
		return null;
	}

	var total_weight = 0;
	var total = 0;

	for (var i = 0; i < constraints.length; i++) {
		if (constraints[i].weight !== 0)
			total_weight += constraints[i].weight || 1;

		if (constraints[i].weight !== 0)
			total +=
				colorToPercent(constraints[i].trend) *
				(constraints[i].weight || 1);
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50;

	return currentPercentage;
};

export const vulnerabilityIndicators = function (indicators) {
	//console.log('-------- vulnerability', indicators)

	if (!indicators || indicators.length == 0) {
		return null;
	}

	var total_weight = 0;
	var total = 0;

	for (var i = 0; i < indicators.length; i++) {
		if (indicators[i].weight !== 0)
			total_weight += indicators[i].weight || 1;

		if (indicators[i].weight !== 0)
			total +=
				colorToPercent(indicators[i].trend) *
				(indicators[i].weight || 1);
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50;

	return currentPercentage;
};

export const isItemInactive = (item) => {
	if (!item) return false;

	const distanceFromCenter = Math.sqrt(
		Math.pow(item.x, 2) + Math.pow(item.y, 2)
	);
	const isOutside = distanceFromCenter > 100;

	return isOutside;
};

export const isItemNeutral = (item) => {
	if (!item) return false;

	if (item.y <= 5 && item.y >= -5) return true;

	return false;
};

export const isRiskOrOpportunityWorse = (risk) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? getIndividualRiskScore(risk) < getIndividualRiskScore(risk.o)
		: getIndividualRiskScore(risk) > getIndividualRiskScore(risk.o);
};

export const isRiskOrOpportunityBetter = (risk) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? getIndividualRiskScore(risk) > getIndividualRiskScore(risk.o)
		: getIndividualRiskScore(risk) < getIndividualRiskScore(risk.o);
};

export const isRiskOrOpportunityFieldBetter = (risk, field) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? risk[field] > risk.o[field]
		: risk[field] < risk.o[field];
};

export const isRiskOrOpportunityFieldWorse = (risk, field) => {
	if (!risk) return false;
	if (!risk.o) return false;

	return risk.isOpportunity
		? risk[field] < risk.o[field]
		: risk[field] > risk.o[field];
};

export const isRiskOrOpportunityImpactBetter = (risk) => {
	return isRiskOrOpportunityFieldBetter(risk, 'impact');
};

export const isRiskOrOpportunityImpactWorse = (risk) => {
	return isRiskOrOpportunityFieldWorse(risk, 'impact');
};

export const isRiskOrOpportunityLikelihoodBetter = (risk) => {
	return isRiskOrOpportunityFieldBetter(risk, 'likelihood');
};

export const isRiskOrOpportunityLikelihoodWorse = (risk) => {
	return isRiskOrOpportunityFieldWorse(risk, 'likelihood');
};

export const isIndicatorOrConstraintWorse = (element) => {
	if (!element) return false;
	if (!element.o) return false;

	return (
		getIndicatorOrConstraintScore([element]) <
		getIndicatorOrConstraintScore([element.o])
	);
};

export const isIndicatorOrConstraintBetter = (element) => {
	if (!element) return false;
	if (!element.o) return false;

	return (
		getIndicatorOrConstraintScore([element]) >
		getIndicatorOrConstraintScore([element.o])
	);
};
