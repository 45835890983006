import { useTranslation } from 'react-i18next';

import gql from 'graphql-tag';
import { keyBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import ActionButton from 'components/action/ActionButton';

import {
	useCreateStakeholderCommentMutation,
	useUpdateStakeholderCommentMutation,
	useDeleteStakeholderCommentMutation
} from 'graph/dist/generated';
import CommentsList from '../CommentsList';
import StakeholderCommentModal from 'components/Modal/StakeholderCommentModal';
import { getFragmentFields } from 'utils/graphql';

export const CommentsTab = (props: any) => {
	const { t } = useTranslation();

	const [createStakeholderComment] = useCreateStakeholderCommentMutation();
	const [updateStakeholderComment] = useUpdateStakeholderCommentMutation();
	const [deleteStakeholderComment] = useDeleteStakeholderCommentMutation();

	const comments =
		props.indexedStakeholders &&
			props.indexedStakeholders[props.selection.id]
			? props.indexedStakeholders[props.selection.id].comments.map(
				(c: any) => {
					return {
						...c,
						name: c.description
					};
				}
			)
			: [];
	const indexedComments = keyBy(comments, 'id');

	// Create
	const handleCreateStakeholderComment = async (
		stakeholderId: string,
		description: string
	) => {
		props.pushModal(
			<StakeholderCommentModal
				onSave={async (values) => {
					console.log('onChange test', values);

					let variables = {
						stakeholderId,
						description: values.description,
						commentedAt: values.commentedAt
					};
					let result = await createStakeholderComment({
						variables,
						update(cache, { data: { createStakeholderComment } }) {
							cache.modify({
								id: `Stakeholder:${stakeholderId}`,
								fields: {
									comments(
										existingStakeholdersLinksRef,
										{ readField }
									) {
										const newStakeholderComment =
											cache.writeFragment({
												id:
													'StakeholderComment:' +
													createStakeholderComment.id,
												data: createStakeholderComment,
												fragment: gql`
													fragment StakeholderFragment on Stakeholder {
														${getFragmentFields(variables)}
													}
												`
											});
										return [
											...existingStakeholdersLinksRef,
											newStakeholderComment
										];
									}
								}
							});
						},

						optimisticResponse: {
							createStakeholderComment: {
								__typename: 'StakeholderComment',
								id: 'temp-id',
								createdAt: new Date().toISOString(),
								...variables
							}
						}
					});
				}}
			></StakeholderCommentModal>
		);
	};

	// Create
	const handleEditStakeholderComment = async (
		stakeholderId: string,
		id: string
	) => {
		console.log('Comment', indexedComments[id]);

		props.pushModal(
			<StakeholderCommentModal
				values={indexedComments[id] || {}}
				onDelete={() =>
					handleDeleteStakeholderComment(stakeholderId, id)
				}
				onSave={async (values) => {
					console.log('onChange test', values);

					let variables = {
						stakeholderId,
						id,
						description: values.description,
						commentedAt: values.commentedAt.format('YYYY-MM-DD')
					};
					let result = await updateStakeholderComment({
						variables,
						update(cache) {
							cache.modify({
								id: `StakeholderComment:${id}`,
								fields: {
									description() {
										return variables.description;
									},
									commentedAt() {
										return variables.commentedAt;
									}
								}
							});
						},

						optimisticResponse: {
							updateStakeholderComment: true
						}
					});
				}}
			></StakeholderCommentModal>
		);
	};

	// Delete
	const handleDeleteStakeholderComment = async (
		stakeholderId: string,
		id: string
	) => {
		console.log('handleDeleteStakeholderComment', id);

		await deleteStakeholderComment({
			variables: {
				stakeholderId,
				id
			},

			update(cache, { data: { deleteStakeholderComment } }) {
				cache.modify({
					id: `Stakeholder:${stakeholderId}`,
					fields: {
						comments(existingCommentsRef, { readField }) {
							return existingCommentsRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteStakeholderComment: true
			}
		});
	};

	//onDelete={(id: string) => handleDeleteStakeholderComment(props.selection.id, id)}
	return (
		<>
			<div className="d-flex flex-column flex-grow mt-3">
				{props &&
					(!props.indexedStakeholders[props.selection.id].comments ||
						props.indexedStakeholders[props.selection.id].comments
							.length == 0) && (
						<div style={{ color: 'rgba(46, 160, 223, 0.25)' }}>
							{t('models.item_comment.no_x')}
						</div>
					)}

				<CommentsList
					onEdit={(id: string) =>
						handleEditStakeholderComment(props.selection.id, id)
					}
					items={comments}
				></CommentsList>

				{!props.scenario && (
					<div className="Actions mt-4">
						<ActionButton
							className="small"
							tooltip={t('models.item.actions.edit')}
							onClick={() =>
								handleCreateStakeholderComment(
									props.selection.id,
									'test'
								)
							}
						>
							<FontAwesomeIcon icon={faPlus} />
						</ActionButton>
					</div>
				)}
			</div>
		</>
	);
};

export default CommentsTab;
