import { useState, useContext } from 'react';

import UIContext from 'components/Utils/UIContext';
import { MaskableComponent } from 'components/Tutorial/MaskableComponent';

import './SideMenu.sass';
import { compact, isFunction } from 'lodash';

export const SideMenu = ({ tabs, children, defaultTab }) => {
    const uiContext = useContext(UIContext);
    const [selectedTab, setSelectedTab] = useState<string>(defaultTab);

    const availableTabs = compact(tabs)
    let effectiveTab = selectedTab;
    const hasSelectedTab = availableTabs.find((t) => t.id == selectedTab);
    if (!hasSelectedTab) {
        effectiveTab = defaultTab;
    }

    return <MaskableComponent masked={uiContext.maskedZones['sidemenu']}>
        <aside
            id="SideMenu"
            className={
                'SideMenu opened'
            }
        >
            <ul className="VerticalTabs noselection">

                {availableTabs.map((t, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => {
                                setSelectedTab(t.id);
                            }}
                            className={[
                                t.id,
                                effectiveTab == t.id
                                    ? 'selected'
                                    : ''
                            ].join(' ')}
                        >
                            {t.title}
                        </li>
                    );
                })}
            </ul>
            <div className="content h-100">
                {children && isFunction(children) ? children(effectiveTab) : null}
            </div>
        </aside>
    </MaskableComponent>
}