export default {
	title: 'Fishbone',
	tabs: {
		general: {
			title: 'General'
		},
		filters: {
			title: 'View filters'
		},
		bot: {
			title: 'AI'
		}
	},
	filters: {
		completion: {
			title: 'Completion filter',
			not_started: 'Not started',
			in_progress: 'In progress',
			completed: 'Completed'
		},
		dates: {
			title: 'Dates filter'
		},
		startOn: {
			title: 'Start date filter',
			undefined: 'No date',
			past: 'Past',
			ending_week: 'Starting in a week',
			ending_month: 'Starting in a month',
			ending_quarter: 'Starting in a quarter',
			ending_year: 'Starting in a year'
		},
		endOn: {
			title: 'End date filter',
			undefined: 'No date',
			done: 'Done',
			overdue: 'Overdue',
			ending_week: 'Ending in a week',
			ending_month: 'Ending in a month',
			ending_quarter: 'Ending in a quarter',
			ending_year: 'Ending in a year'
		}
	},

	all_icons: 'Icons filter',
	no_icon: 'No icon'
};
