export type StakeholderLinkModel = {
	id: string;
	originId: string;
	targetId: string;
	createdAt: string;
	updatedAt: string;
	strength: number;
};

export const defaults = {
	__typename: 'StakeholderLink',
	createdAt: '2025-02-11T14:26:13.594Z',
	originId: '',
	targetId: '',
	strength: 1
};
