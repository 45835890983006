import { fal } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Tag } from "antd";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";


export const FishboneFiltersTab = ({ iconFilter, setIconFilter, searchTerm, setSearchTerm, timeStartFilter, setTimeStartFilter, timeEndFilter, setTimeEndFilter, completionFilter, setCompletionFilter, fishboneBranchs = [] }) => {

    const { t } = useTranslation();

    const commonTimes = [
        {
            name: t("pages.fishbone.filters.startOn.undefined"),
            value: "undefined",
        },
        {
            name: t("pages.fishbone.filters.startOn.past"),
            value: "past"
        },
        {
            name: t("pages.fishbone.filters.endOn.overdue"),
            value: "overdue"
        }
    ]

    const completions = [
        {
            name: t("pages.fishbone.filters.completion.not_started"),
            value: "not_started"
        },
        {
            name: t("pages.fishbone.filters.completion.in_progress"),
            value: "in_progress"
        },
        {
            name: t("pages.fishbone.filters.completion.completed"),
            value: "completed"
        }
    ]

    const startOnTimes = [
        {
            name: t("pages.fishbone.filters.startOn.ending_week"),
            value: "week"
        },
        {
            name: t("pages.fishbone.filters.startOn.ending_month"),
            value: "month"
        },
        {
            name: t("pages.fishbone.filters.startOn.ending_quarter"),
            value: "quarter"
        },
        {
            name: t("pages.fishbone.filters.startOn.ending_year"),
            value: "year"
        }
    ]

    const endOnTimes = [
        {
            name: t("pages.fishbone.filters.endOn.ending_week"),
            value: "week"
        },
        {
            name: t("pages.fishbone.filters.endOn.ending_month"),
            value: "month"
        },
        {
            name: t("pages.fishbone.filters.endOn.ending_quarter"),
            value: "quarter"
        },
        {
            name: t("pages.fishbone.filters.endOn.ending_year"),
            value: "year"
        }
    ]

    const allIcons: any = uniq(
        fishboneBranchs.map((i: any) => (i.icon ? i.icon : 'faCrosshairs'))
    ).sort();

    return <>
        <Input
            onChange={(e) => {
                setSearchTerm(e.target.value);
            }}
            value={searchTerm}
            style={{
                marginLeft: 'auto',
                marginRight: '1em'
            }}
            placeholder="Search"
            className="SearchInput mt-2"
        ></Input>

        <div
            className="text-left mt-4"
            style={{ marginBottom: '0.5rem' }}
        >
            {t('pages.fishbone.all_icons')}
        </div>

        {(allIcons || []).length == 0 ? (
            <div
                style={{
                    color: 'rgba(46, 160, 223, 0.3)'
                }}
            >
                {t('pages.fishbone.no_icon')}
            </div>
        ) : (
            <div className="text-left">
                {allIcons.map((i, index) => (
                    <Tag
                        key={'i' + index}
                        onClick={() => {
                            iconFilter == i
                                ? setIconFilter(null)
                                : setIconFilter(i);
                        }}
                        className={[
                            'tag',
                            iconFilter == i
                                ? ''
                                : 'unselected'
                        ].join(' ')}
                        style={{
                            verticalAlign: 'middle',
                            paddingBottom: '0.34rem',
                            paddingTop: '0.3rem'

                        }}
                    >
                        <FontAwesomeIcon
                            style={{ fontSize: '0.9rem' }}
                            icon={fal[i]}
                        ></FontAwesomeIcon>
                    </Tag>
                ))}
            </div>
        )}

        <div
            className="text-left mt-4"
            style={{ marginBottom: '0.5rem' }}
        >
            {t('pages.fishbone.filters.completion.title')}
        </div>

        <div className="text-left mb-1">
            {completions.map((t, index) => (
                <Tag
                    key={'t' + index}
                    onClick={() => {
                        if (t.value == "not_started") {

                            if (completionFilter == "not_started") {
                                setCompletionFilter(null)
                            } else {
                                setCompletionFilter("not_started")
                            }
                            return
                        }

                        if (t.value == "in_progress") {
                            if (completionFilter == "in_progress") {
                                setCompletionFilter(null)
                            } else {
                                setCompletionFilter("in_progress")
                            }
                            return
                        }

                        if (t.value == "completed") {
                            if (completionFilter == "completed") {
                                setCompletionFilter(null)
                            } else {
                                setCompletionFilter("completed")
                            }
                            return
                        }


                    }}
                    className={[
                        'tag',
                        completionFilter == t.value
                            ? ''
                            : 'unselected'
                    ].join(' ')}
                >
                    {t.name || t.value}
                </Tag>
            ))}
        </div>

        <div
            className="text-left mt-4"
            style={{ marginBottom: '0.5rem' }}
        >
            {t('pages.fishbone.filters.dates.title')}
        </div>

        <div className="text-left mb-1">
            {commonTimes.map((t, index) => (
                <Tag
                    key={'t' + index}
                    onClick={() => {
                        if (t.value == "undefined") {

                            if (timeStartFilter == "undefined") {
                                setTimeStartFilter(null)
                                setTimeEndFilter(null)
                            } else {
                                setTimeStartFilter("undefined")
                                setTimeEndFilter(null)
                            }
                            return
                        }

                        if (t.value == "past") {
                            if (timeStartFilter == "past") {
                                setTimeStartFilter(null)
                                setTimeEndFilter(null)
                            } else {
                                setTimeStartFilter("past")
                                setTimeEndFilter(null)
                            }
                            return
                        }

                        if (t.value == "overdue") {
                            if (timeEndFilter == "overdue") {
                                setTimeStartFilter(null)
                                setTimeEndFilter(null)
                            } else {
                                setTimeStartFilter(null)
                                setTimeEndFilter("overdue")
                            }
                            return
                        }
                    }}
                    className={[
                        'tag',
                        timeStartFilter == t.value || timeEndFilter == t.value
                            ? ''
                            : 'unselected'
                    ].join(' ')}
                >
                    {t.name || t.value}
                </Tag>
            ))}
        </div>

        <div className="text-left mb-1">
            {startOnTimes.map((t, index) => (
                <Tag
                    key={'t' + index}
                    onClick={() => {
                        // Add filter if not present, remove it if already present
                        timeStartFilter == t.value
                            ? setTimeStartFilter(null)
                            : setTimeStartFilter(t.value);
                        //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                    }}
                    className={[
                        'tag',
                        timeStartFilter == t.value
                            ? ''
                            : 'unselected'
                    ].join(' ')}
                >
                    {t.name || t.value}
                </Tag>
            ))}
        </div>




        <div className="text-left">
            {endOnTimes.map((t, index) => (
                <Tag
                    key={'t' + index}
                    onClick={() => {
                        // Add filter if not present, remove it if already present
                        timeEndFilter == t.value
                            ? setTimeEndFilter(null)
                            : setTimeEndFilter(t.value);
                        //tagFilter == t ? setTagFilter(null) : setTagFilter(t)
                    }}
                    className={[
                        'tag',
                        timeEndFilter == t.value
                            ? ''
                            : 'unselected'
                    ].join(' ')}
                >
                    {t.name || t.value}
                </Tag>
            ))}
        </div>
    </>
}