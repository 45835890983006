import React, { forwardRef } from 'react';
import { FontAwesomeIcon as OriginalFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

// Ce wrapper supprime l'avertissement "defaultProps will be removed from function components"
const FixedFontAwesomeIcon = forwardRef<SVGSVGElement, FontAwesomeIconProps>((props, ref) => {
  return <OriginalFontAwesomeIcon {...props} ref={ref} />;
});

FixedFontAwesomeIcon.displayName = 'FontAwesomeIcon';

export { FixedFontAwesomeIcon as FontAwesomeIcon };