import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';
import { sumBy } from 'lodash';


import { colorToPercent } from 'utils/ranking';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import './FishboneBranch.sass';
import { FishbonePin } from './FishbonePin';
import { FishboneBranchContent } from './FishboneBranchContent';
import { fal } from '@fortawesome/pro-light-svg-icons';

dayjs.extend(relativeTime)

export const FishboneBranch = (props: any) => {

	const { t } = useTranslation();

	const lineModifierY = props.odd ? 0 : 0
	const lineModifierX = props.odd ? 0 : 0

	const titleDY = props.position == "top" ? (props.odd ? 0 : -30) : (props.odd ? -30 : 0)
	const titleDX = props.position == "top" ? (props.odd ? 0 : -30) : (props.odd ? -30 : 0)


	const allPoints = props.points || [];
	//sortBy(props.points || [], (p) => (p && p.completion) || 0).reverse()


	let pointsWeight = 0;
	allPoints.forEach((p) => (pointsWeight += p.weight || 1));

	const vulnerability =
		allPoints && allPoints.length > 0
			? sumBy(
				allPoints,
				(p) =>
					colorToPercent(p.trend !== null ? p.trend : '50') *
					(p.weight || 1)
			) / pointsWeight
			: 50;

	const completion =
		allPoints && allPoints.length > 0
			? sumBy(allPoints, (p) => (p.completion || 0) * (p.weight || 1)) /
			pointsWeight
			: 0;

	const icon = props.icon || 'faCrosshairs';
	const iconDetails = fal[icon];
	return (
		<g
			className={`FishboneBranch ${props.isMatching ? "matching" : ""}`}
			transform={'translate(' + props.leftOffset + ', ' + props.topOffset + ')'}
		>
			<g>
				<Tooltip
					overlayStyle={{ minWidth: '400px' }}
					title={
						<div>
							<div className="d-flex pb-3 ">
								<b>
									{props.name}{' '}
									<small
										style={{
											fontSize: '10px',
											marginLeft: '5px'
										}}
									>
										(click to select)
									</small>
								</b>
							</div>

							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									{t("models.fishbone_branch.fields.completion")}
								</div>
								<div>
									<b>{completion.toFixed(0)}%</b>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									{t("models.fishbone_branch.fields.trend")}
								</div>
								<div
									className="MiniIndicatorVulnerabilityScale"
									style={{
										width: '200px',
										marginTop: '10px'
									}}
								>
									<div
										id={'bar_marker'}
										style={{
											left: vulnerability + '%'
										}}
									></div>
								</div>
							</div>

							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									Dates
								</div>
								<div>
									{
										<small>
											{props.startOn ? dayjs(
												props.startOn
											).format('DD/MM/YYYY') : t("messages.not_defined")}
										</small>
									}
								</div>
								{props.startOn && props.endOn && (
									<div className="mr-1 ml-1">-</div>
								)}
								<div>
									{props.endOn &&
										!props.startOn &&
										'Until '}
									{props.endOn && (
										<small>
											{dayjs(props.endOn).format(
												'DD/MM/YYYY'
											)}
										</small>
									)}
								</div>
							</div>

							<div className="d-flex mb-2">
								<div
									style={{
										width: '110px',
										flexShrink: 0
									}}
								>
									Responsible
								</div>
								<div>
									{props.responsible &&
										props.responsible || t("messages.not_defined")}
								</div>
							</div>

						</div>
					}
				>
					<g className="FishboneBranchTitle" onClick={() => props.onSelectItem(props)}>
						<g
							transform={
								'translate(' + (-25 + titleDX) + ', ' +
								(props.position == 'bottom' ? props.height - titleDY + 20 : titleDY - 25) + ')'
							}
						>

							<FishbonePin weight={props.weight} trend={vulnerability} completion={completion} ></FishbonePin>

							{iconDetails && <svg viewBox={`0 0 ${iconDetails?.icon[0]} ${iconDetails?.icon[1]}`} fill={props.selected ? "orange" : 'white'} stroke={'none'} width={18} height={16} x={22} y={-8}>
								<path d={(iconDetails?.icon[4])} />
							</svg>
							}
						</g>

						<text
							className={'title'}
							fontSize={16}
							dx={titleDX + 20}
							dy={props.position == 'bottom' ? props.height - titleDY + 25 : titleDY - 20}

							fill={props.selected ? "orange" : 'white'}
						>

							{props.name}
						</text>
					</g>
				</Tooltip>
			</g>

			< g className="LineGroup" >
				<line
					x2={lineModifierX}
					x1={props.width}
					y2={props.position == "bottom" ? props.height + lineModifierY : lineModifierY}
					y1={props.position == "bottom" ? 0 : props.height}
					stroke="#A6B9C8"
					strokeLinecap="round"
				></line>
			</g >

			<g className="LineGroup">
				<line
					x2={lineModifierX}
					x1={props.width}
					y2={props.position == "bottom" ? props.height + lineModifierY : lineModifierY}
					y1={props.position == "bottom" ? 0 : props.height}
					stroke="#FFFFFF00"
					strokeWidth={20}
					strokeLinecap="round"
				></line>
				<g
					className="add"
					onClick={props.onCreateItem}
					transform={
						'translate(' +
						((props.width) * (0.92)) +
						' ' +
						(props.height * (props.position == "bottom" ? 0.08 : 0.92)) +
						')'
					}
				>
					<circle r={15} fill={'#CCCCCC'}></circle>
					<text fill="white" fontSize={36} dx={-10} dy={13}>
						+
					</text>
				</g>
			</g>

			<g transform={"translate(" + (props.position == "bottom" ? props.width * 0.1 : props.width * 0.1) + ", " + (props.position == "bottom" ? 0 : props.height * 0.1) + ")"}>
				<FishboneBranchContent
					{...props}
					width={props.width * 0.9}
					height={props.height * 0.9}
				></FishboneBranchContent>
			</g>


		</g >
	);
};
