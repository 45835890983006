import React, { useState } from 'react';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

import { sortBy, findIndex, pick, isObject, find } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import HeaderPortal from 'layout/header/HeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

import {
	useCreateFishboneBranchMutation,
	useDeleteFishboneBranchMutation,
	useUpdateFishboneBranchMutation
} from 'graph/dist/generated';

import { FishboneBranch } from './FishboneBranch';
import { FishboneBody } from './FishboneBody';
import { FishboneTail } from './FishboneTail';
import { FishboneHead } from './FishboneHead';
import FishboneBranchEditionModal from 'components/Modal/FishboneBranchEditionModal';
import FishboneItemEditionModal from 'components/Modal/FishboneItemEditionModal';
import FishboneHeadEditionModal from 'components/Modal/FishboneHeadEditionModal';
import { useFishboneController } from 'hooks/useFishboneController';

import dayjs from 'dayjs';
import { getItemAlteredByScenario } from 'utils/rules';
import { SideMenu } from 'layout/SideMenu';

import BotTab from 'components/tabs/BotTab';

import './Fishbone.sass';
import { FishboneBranchGeneralTab } from './Tabs/FishboneBranchGeneralTab';
import { FishboneFiltersTab } from './Tabs/FishboneFiltersTab';
import { getFragmentFields } from 'utils/graphql';


const getMatchingItems = (items, timeStartFilter, timeEndFilter, iconFilter, searchTerm, completionFilter, matches = {}) => {

	if (!items) return {};

	items.forEach(i => {
		if (
			isMatchingTerm(i, searchTerm) &&
			isMatchingTime(i, timeStartFilter, timeEndFilter) &&
			isMatchingIcon(i, iconFilter) &&
			isMatchingCompletion(i, completionFilter)
		) {
			matches[i.id] = true;
		}

		if (i.items) {
			matches = { ...matches, ...getMatchingItems(i.items, timeStartFilter, timeEndFilter, iconFilter, searchTerm, completionFilter, matches) }
		}
	})

	return matches;
}

const isMatchingTerm = (item, searchTerm) => {
	if (!searchTerm) return true
	return item.name.toLowerCase().includes(searchTerm.toLowerCase())
		|| (item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase()))
		|| (item.responsible && item.responsible.toLowerCase().includes(searchTerm.toLowerCase()))

}

const isMatchingTime = (item, timeStartFilter, timeEndFilter) => {

	if (!timeEndFilter && !timeStartFilter) return true

	if (timeStartFilter) {
		if (item.startOn && timeStartFilter == "undefined") {
			return false
		}
		if (!item.startOn && timeStartFilter && timeStartFilter !== "undefined") {
			return false
		}
		if (item.startOn && timeStartFilter == "past") {
			if (dayjs(item.startOn).isAfter(dayjs())) return false
		}
		if (item.startOn && timeStartFilter == "week") {
			if (dayjs(item.startOn).isAfter(dayjs().add(7, 'days')) || dayjs(item.startOn).isBefore(dayjs())) return false
		}

		if (item.startOn && timeStartFilter == "month") {
			if (dayjs(item.startOn).isAfter(dayjs().add(1, 'month')) || dayjs(item.startOn).isBefore(dayjs())) return false
		}

		if (item.startOn && timeStartFilter == "quarter") {
			if (dayjs(item.startOn).isAfter(dayjs().add(3, 'month')) || dayjs(item.startOn).isBefore(dayjs())) return false
		}

		if (item.startOn && timeStartFilter == "year") {
			if (dayjs(item.startOn).isAfter(dayjs().add(1, 'year')) || dayjs(item.startOn).isBefore(dayjs())) return false
		}
	}

	if (timeEndFilter) {

		if (item.endOn && timeEndFilter == "undefined") {
			return false
		}
		if (!item.endOn && timeEndFilter !== "undefined" && item.completion != 100) {
			return false
		}

		if (item.endOn && timeEndFilter == "overdue") {
			if (dayjs(item.endOn).isAfter(dayjs())) return false
		}


		if (item.endOn && timeEndFilter == "week") {
			if (dayjs(item.endOn).isAfter(dayjs().add(7, 'days')) || dayjs(item.endOn).isBefore(dayjs())) return false
		}

		if (item.endOn && timeEndFilter == "month") {
			if (dayjs(item.endOn).isAfter(dayjs().add(1, 'month')) || dayjs(item.endOn).isBefore(dayjs())) return false
		}

		if (item.endOn && timeEndFilter == "quarter") {
			if (dayjs(item.endOn).isAfter(dayjs().add(3, 'month')) || dayjs(item.endOn).isBefore(dayjs())) return false
		}

		if (item.endOn && timeEndFilter == "year") {
			if (dayjs(item.endOn).isAfter(dayjs().add(1, 'year')) || dayjs(item.endOn).isBefore(dayjs())) return false
		}
	}

	return true
}

const isMatchingIcon = (item, iconFilter) => {

	if (!iconFilter) return true

	if (iconFilter === undefined || iconFilter == null) {
		if (item.icon) return false
	}

	return item.icon == iconFilter || (!item.icon && iconFilter == "faCrosshairs")
}

const isMatchingCompletion = (item, completionFilter) => {

	if (!completionFilter) return true

	console.log('isMatchingCompletion', item.completion, completionFilter)

	if (completionFilter === "not_started") {
		return (!item.completion)
	}

	if (completionFilter === "in_progress") {
		return (item.completion > 0 && item.completion < 100)
	}

	return (item.completion && item.completion == 100)

}

export const Fishbone = (props: any) => {
	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const params: any = useParams();

	const [createFishboneBranch] = useCreateFishboneBranchMutation();
	const [updateFishboneBranch] = useUpdateFishboneBranchMutation();
	const [deleteFishboneBranch] = useDeleteFishboneBranchMutation();

	const { createFishboneItem, updateFishboneItem, deleteFishboneItem } = useFishboneController();

	const fishboneBranchs = (props.fishboneBranchs || []).map(b => {
		return {
			...b,
			completion: b.items.length > 0 ? b.items.reduce((acc, i) => acc + i.completion, 0) / b.items.length : 0,
		}
	});
	const selection = props.selection ? ([...fishboneBranchs].find(i => i.id == props.selection.id)) : null
	const { risks = [] } = props;

	const { t } = useTranslation();

	const [timeStartFilter, setTimeStartFilter] = useState(null);
	const [timeEndFilter, setTimeEndFilter] = useState(null);
	const [iconFilter, setIconFilter] = useState(null);
	const [completionFilter, setCompletionFilter] = useState(null);

	const matchingIndexedItems = getMatchingItems(fishboneBranchs, timeStartFilter, timeEndFilter, iconFilter, props.searchTerm, completionFilter);

	const availableWidth = window.innerWidth - 300 - 40;
	const availableHeight = window.innerHeight * 0.75 - 100 - 20;

	const fishboneTailWidth = 60;
	const fishboneTailHeight = 100;


	// Create
	const handleCreateBranch = async () => {
		props.pushModal(
			<FishboneBranchEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						projectId: params.id,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						description: values.description || '',
						responsible: values.responsible,
						startOn:
							values.startOn && isObject(values.startOn)
								? values.startOn.format('YYYY-MM-DD')
								: values.startOn,
						endOn:
							values.endOn && isObject(values.endOn)
								? values.endOn.format('YYYY-MM-DD')
								: values.endOn,
						icon: values.icon || "faCrosshairs"
					};

					let result = await createFishboneBranch({
						variables,
						update(cache, { data: { createFishboneBranch } }) {
							cache.modify({
								id: `Project:${params.id}`,
								fields: {
									fishboneBranchs(fishboneBranchs = []) {
										const newFishboneBranch =
											cache.writeFragment({
												id:
													'FishboneBranch:' +
													createFishboneBranch.id,
												data: createFishboneBranch,
												fragment: gql`
													fragment FishboneBranchFragment on FishboneBranch {
														${getFragmentFields(variables)}
													}
												`
											});

										return [
											...fishboneBranchs,
											newFishboneBranch
										];
									}
								}
							});
						},
						optimisticResponse: {
							createFishboneBranch: {
								id: 'temp-id',
								...variables,
								items: []
							}
						}
					});

					props.popModal();
					props.select(result.data?.createFishboneBranch);
				}}
				id={false}
				values={{
					name: '',
					description: '',
					startOn: null,
					endOn: null,
					responsible: ''
				}}
			></FishboneBranchEditionModal>
		);
	};

	// Create
	const handleCreateItem = async (fishboneBranchId, event) => {

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		props.pushModal(
			<FishboneItemEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					let variables = {
						fishboneBranchId: fishboneBranchId,
						name: values.name,
						weight: values.weight || 1,
						trend: '50',
						completion: 0,
						responsible: values.responsible,
						description: values.description || '',
						startOn:
							values.startOn && isObject(values.startOn)
								? values.startOn.format('YYYY-MM-DD')
								: values.startOn,
						endOn:
							values.endOn && isObject(values.endOn)
								? values.endOn.format('YYYY-MM-DD')
								: values.endOn
					};

					let result = await createFishboneItem(fishboneBranchId, variables);

					props.popModal();
				}}
				id={false}
				values={{
					name: '',
					weight: 1,
					trend: '',
					description: '',
					responsible: '',
					completion: 0,
					startOn: null,
					endOn: null
				}}
			></FishboneItemEditionModal>
		);
	};

	const handleUpdateBranch = async (id: string, values: Object) => {
		let index = findIndex(fishboneBranchs, (s: any) => s.id === id);

		console.log('handleUpdateBranch index', index, values);
		let newBranch = {
			...pick(fishboneBranchs[index], [
				'description',
				'name',
				'weight',
				'startOn',
				'endOn',
				'responsible',
				'icon'
			]),
			...pick(values, [
				'description',
				'name',
				'weight',
				'startOn',
				'endOn',
				'responsible',
				'icon'
			])
		};
		if (!newBranch.weight) newBranch.weight = 1;
		if (newBranch.startOn && isObject(newBranch.startOn))
			try {
				newBranch.startOn = newBranch.startOn.format('YYYY-MM-DD');
			} catch (e) { }
		if (newBranch.endOn && isObject(newBranch.endOn))
			try {
				newBranch.endOn = newBranch.endOn.format('YYYY-MM-DD');
			} catch (e) { }

		console.log('handleUpdateBranch', newBranch);

		const result = await updateFishboneBranch({
			variables: {
				id: id,
				...newBranch
			},
			update(cache) {
				cache.modify({
					id: `FishboneBranch:${id}`,
					fields: {
						name() {
							return newBranch.name;
						},
						description() {
							return newBranch.description
						},
						weight() {
							return newBranch.weight
						},
						responsible() {
							return newBranch.responsible
						},
						startOn() {
							return newBranch.startOn
						},
						endOn() {
							return newBranch.endOn
						},
						icon() {
							return newBranch.icon
						}
					}
				});
			},
			optimisticResponse: {
				updateFishboneBranch: true
			}
		});
	};

	const handleUpdateItem = async (
		fishboneBranchId: string,
		id: string,
		values: Object
	) => {
		console.log(
			'handleUpdateItem',
			fishboneBranchId,
			id,
			values,
			fishboneBranchs
		);

		let fishboneIndex = findIndex(
			fishboneBranchs,
			(s: any) => s.id === fishboneBranchId
		);
		let index = findIndex(
			fishboneBranchs[fishboneIndex].items,
			(s: any) => s.id === id
		);

		console.log('handleUpdateItem', "fishboneBranchId", fishboneBranchId, "id", id, "values", values);

		const result = await updateFishboneItem(id, fishboneBranchs[fishboneIndex].items, props.scenario, values);
	};

	const askDeleteConfirmation = async (
		id?: string,
		type: string,
		fishboneBranchId: string | undefined
	) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.' + type + '.messages.delete_confirmation')}
				t={t}
				onConfirm={() => {
					type == 'fishbone_branch'
						? handleDeleteBranch(id)
						: deleteFishboneItem(fishboneBranchId, id)

					props.deselect()
				}}
				message={
					t(
						'models.' +
						type +
						'.messages.delete_confirmation_explanation'
					)}
			></ConfirmModal >
		);
	};

	// Delete
	const handleDeleteBranch = async (id: string | undefined) => {
		if (id == null) {
			return;
		}

		await deleteFishboneBranch({
			variables: { id: id },
			update(cache, { data: { deleteFishboneBranch } }) {
				cache.modify({
					id: `Project:${params.id}`,
					fields: {
						fishboneBranchs(
							existingFishboneBranchRef,
							{ readField }
						) {
							return existingFishboneBranchRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteFishboneBranch: true
			}
		});
	};

	const handleEditHead = () => {
		props.pushModal(
			<FishboneHeadEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={(values: Object) => {
					props.onUpdateProject(params.id, { ...props, ...values });
					props.popModal();
				}}
				id={editedItem?.id}
				values={{
					description: props?.description,
					fishboneDescription: props?.fishboneDescription,
					responsible: props?.responsible,
					fishboneStartOn: props?.fishboneStartOn
						? dayjs(props?.fishboneStartOn)
						: null,
					fishboneEndOn: props?.fishboneEndOn
						? dayjs(props?.fishboneEndOn)
						: null
				}}
			></FishboneHeadEditionModal>
		);
	};

	return (
		<div className="Fishbone">
			<HeaderPortal>
				{props.portalContent}{' '}
				<FontAwesomeIcon
					style={{
						marginLeft: 'auto',
						marginRight: '0.5rem',
						cursor: 'pointer'
					}}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			<div className="Body pt-1">
				<h2 className='mb-2'>{t('pages.fishbone.title')}</h2>
				<div className="Body pt-4">


					<svg
						className="mt-4"
						width={availableWidth + 'px'}
						height={availableHeight + 'px'}
						viewBox={'0 0 ' + availableWidth + ' ' + availableHeight}
						style={{ overflow: 'visible' }}
					>
						<defs>
							<linearGradient id="redToGreen">
								<stop offset="0%" stopColor="#E70549" />
								<stop offset="100%" stopColor="#24CCB8" />
							</linearGradient>
						</defs>

						<FishboneBody
							leftOffset={fishboneTailWidth}
							width={availableWidth - fishboneTailWidth * 2}
							height={availableHeight}
							onCreateBranch={handleCreateBranch}
						>
							{sortBy(fishboneBranchs || [], [
								'startOn',
								'name'
							]).map((fb, index) => (
								<FishboneBranch
									key={fb.id}
									{...fb}
									selected={selection?.id === fb.id}
									points={fb.items.map((i) => props.scenario?.id ? getItemAlteredByScenario(i, props.scenario) : i)}
									onCreateItem={(e) => handleCreateItem(fb.id, e)}
									onUpdateItem={(id, values) =>
										handleUpdateItem(fb.id, id, values)
									}
									onEditBranch={(o) => setEditedItem(o)}
									onUpdateBranch={(id, values) =>
										handleUpdateBranch(id, values)
									}
									onEditItem={setEditedItem}
									scenario={props.scenario}
									onSelectItem={props.select}

									isMatching={matchingIndexedItems[fb.id]}
									matchingIndexedItems={matchingIndexedItems}
								></FishboneBranch>
							))}
						</FishboneBody>
						<g
							transform={
								'translate(0 ' +
								(availableHeight / 2 - fishboneTailHeight / 2) +
								')'
							}
						>
							<FishboneTail
								width={fishboneTailWidth}
								height={fishboneTailHeight}

							></FishboneTail>
						</g>
						<g
							transform={
								'translate(' +
								(availableWidth - fishboneTailWidth) +
								' ' +
								(availableHeight / 2 - (fishboneTailHeight * 0.8) / 2) +
								')'
							}
						>
							<FishboneHead
								branchs={fishboneBranchs.map(b => ({
									...b,
									items: b.items.map(i => props.scenario?.id ? getItemAlteredByScenario(i, props.scenario) : i)
								}))}

								{...props}
								width={fishboneTailWidth}
								t={t}
								height={fishboneTailHeight * 0.8}
								onClick={handleEditHead}
							></FishboneHead>
						</g>
					</svg>
				</div>
			</div>


			<SideMenu defaultTab={!selection ? "filters" : "general"} tabs={[
				!selection && {
					id: 'filters',
					title: t('pages.fishbone.tabs.filters.title')
				},
				!selection && {
					id: 'bot',
					title: t('pages.fishbone.tabs.bot.title')
				},
				selection && {
					id: 'general',
					title: t('pages.fishbone.tabs.general.title')
				}
			]}>
				{function (selectedTab: string) {

					if (selectedTab == "general" && selection?.__typename == "FishboneBranch") return <FishboneBranchGeneralTab
						onAddItem={(e) => handleCreateItem(selection.id, e)}
						onClose={() => props.deselect()}
						onEdit={() => setEditedItem(selection)}
						onDelete={(id) => askDeleteConfirmation(id, 'fishbone_branch')}
						selection={selection}
						fishboneBranchs={fishboneBranchs}
						risks={risks}
						onChange={(id, values) => handleUpdateBranch(id, values)}
					>
					</FishboneBranchGeneralTab>

					return <div className='noselection'>

						{selectedTab == "filters" && <>
							<FishboneFiltersTab
								searchTerm={props.searchTerm}
								setSearchTerm={props.setSearchTerm}
								timeEndFilter={timeEndFilter}
								setTimeEndFilter={setTimeEndFilter}
								timeStartFilter={timeStartFilter}
								setTimeStartFilter={setTimeStartFilter}
								fishboneBranchs={fishboneBranchs}
								iconFilter={iconFilter}
								setIconFilter={setIconFilter}
								completionFilter={completionFilter}
								setCompletionFilter={setCompletionFilter}
							></FishboneFiltersTab>
						</>
						}

						{selectedTab == "bot" && <>
							<BotTab
								consent={props.aiConsent}
								projectId={params.id}
								messages={props.botThread?.messages || []}
								onResetThread={props.resetBotThread}
								onSendMessage={(message, callback) => props.addBotMessage(params.id, props.aiConsent === "FULL" ? props.scenario?.id : null, "stakeholders", message, callback)}
							>

							</BotTab></>}

					</div>
				}}
			</SideMenu >

			{
				editedItem && editedItem.__typename === 'FishboneBranch' && (
					<FishboneBranchEditionModal
						onHide={() => {
							setEditedItem(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(editedItem.id, 'fishbone_branch');
							setEditedItem(null);
						}}
						onSave={(values: Object) => {
							console.log(
								'on save FishboneBranchEditionModal',
								values
							);
							handleUpdateBranch(editedItem.id, values);
							setEditedItem(null);
						}}
						minDate={props.fishboneStartOn}
						maxDate={props.fishboneEndOn}
						id={editedItem?.id}
						values={{
							name: editedItem?.name,
							weight: editedItem?.weight || 1,
							description: editedItem?.description || '',
							responsible: editedItem?.responsible,
							startOn: editedItem?.startOn
								? dayjs(editedItem?.startOn)
								: null,
							endOn: editedItem?.endOn
								? dayjs(editedItem?.endOn)
								: null
						}}
					></FishboneBranchEditionModal>
				)
			}

			{
				editedItem && editedItem.__typename === 'FishboneItem' && (
					<FishboneItemEditionModal
						onHide={() => {
							setEditedItem(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(
								editedItem.id,
								'fishbone_item',
								editedItem.fishboneBranchId
							);
							setEditedItem(null);
						}}
						onSave={function (values: Object) {
							console.log('on save FishboneItemEditionModal', values);
							handleUpdateItem(
								editedItem.fishboneBranchId,
								editedItem.id,
								values
							);
							setEditedItem(null);
						}}
						id={editedItem?.id}
						minDate={
							find(
								fishboneBranchs,
								(b) => b.id === editedItem.fishboneBranchId
							)?.startOn
						}
						maxDate={
							find(
								fishboneBranchs,
								(b) => b.id === editedItem.fishboneBranchId
							)?.endOn
						}
						values={{
							name: editedItem?.name,
							weight: editedItem?.weight || 1,
							completion: editedItem?.completion,
							trend: editedItem?.trend,
							responsible: editedItem?.responsible,
							description: editedItem?.description || '',
							startOn: editedItem?.startOn
								? dayjs(editedItem?.startOn)
								: null,
							endOn: editedItem?.endOn
								? dayjs(editedItem?.endOn)
								: null
						}}
					></FishboneItemEditionModal>
				)
			}
		</div >
	);
};
