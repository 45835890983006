import { StakeholderConstraintImpactModel } from './StakeholderConstraintImpactModel';
import { StakeholderIndicatorImpactModel } from './StakeholderIndicatorImpactModel';
import { StakeholderRiskImpactModel } from './StakeholderRiskImpactModel';

export type StakeholderModel = {
	id: string;
	name: string;
	description: string;
	x: number;
	y: number;
	trend: string;
	impact: string;
	createdAt: string;
	updatedAt: string;

	impactedRisks: StakeholderRiskImpactModel[];
	impactedIndicators: StakeholderIndicatorImpactModel[];
	impactedConstraints: StakeholderConstraintImpactModel[];
};

export const defaults = {
	__typename: 'Stakeholder',
	comments: [],
	createdAt: '2025-02-11T14:26:13.594Z',
	description: '',
	icon: null,
	impact: 'low',
	impactedConstraints: [],
	impactedIndicators: [],
	impactedRisks: [],
	links: [],
	name: 'Sample stakeholder',
	scenarios: [],
	taggings: [],
	trend: null,
	x: 0,
	y: 0
};
