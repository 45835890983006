export default {
	title: 'Risks / Opportunities',
	url: 'risks',
	subtitle: '',
	all_categories: 'Categories filter',
	all_impacts: 'Impact filter',
	all_likelihoods: 'Likelihood filter',
	all_stakeholders: 'Stakeholders filter',
	all_stakes: 'Stakes filter',
	no_stakeholder: 'No stakeholder',
	tabs: {
		filters: {
			title: 'View filters',

			displayed_columns: 'Displayed columns',
			visualization_options: 'Visualization options'
		},
		options: {
			title: 'Display',
			are_links_visible: 'Show all links',
			chart_visible_stakes: 'Show stakes',
			chart_visible_stakeholders: 'Show stakeholders',
			chart_visible_risks: 'Show risks',
			chart_visible_opportunities: 'Show opportunities'
		},
		general: {
			title: 'General'
		},
		stakeholders: {
			title: 'Stakeholders'
		},
		export: {
			title: 'Export'
		}
	}
};
