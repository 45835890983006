import React from 'react';

import { Motion, spring } from 'react-motion';
import { withTranslation } from 'react-i18next';

import Item from './Item';
import Radar from './Radar';
import { Amphi } from './Amphi';
import { keyBy, flatten } from 'lodash';
import { isItemInactive } from 'utils/rules';

import('./MainRadar.sass');

const springSetting1 = { stiffness: 180, damping: 10 };

export const radarOriginalSize = 560;
export const radarBoundariesRatio = (225 * 2) / radarOriginalSize;
export const radarHighToAverageBoundariesRatio =
	(100 * 2) / radarOriginalSize / radarBoundariesRatio;
export const radarAverageToLowBoundariesRatio =
	(165 * 2) / radarOriginalSize / radarBoundariesRatio;
export const radarLikelyToUndefinedBoundariesRatio =
	(275 * 2) / radarOriginalSize / radarBoundariesRatio;

const springSettings = { stiffness: 70, damping: 30 };

class MainRadar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			mouseXY: [0, 0],
			mouseCircleDelta: [0, 0], // difference between mouse and circle pos for x + y coords, for dragging
			lastPress: null, // key of the last pressed component
			isPressed: false,
			reload: 0,
			isResizing: false
		};

		this.radar = new Radar();
		this.startDragAt = null;
		this.resizingTimer = null;
	}

	componentDidMount() {
		window.addEventListener('touchmove', this.handleTouchMove);
		window.addEventListener('touchend', this.handleTouchEnd);
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('mouseup', this.handleMouseUp);
		window.addEventListener('dblclick', this.handleDoubleClick);
	}

	componentWillUnmount() {
		window.removeEventListener('touchmove', this.handleTouchMove);
		window.removeEventListener('touchend', this.handleTouchEnd);
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('dblclick', this.handleDoubleClick);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.scenario !== this.props.scenario) {
			this.setState({ isAmphiVisible: false });
		}

		if (this.props.width !== nextProps.width || this.props.height !== nextProps.height) {
			this.setState({ isResizing: true });

			if (this.resizingTimer) clearTimeout(this.resizingTimer);
			this.resizingTimer = setTimeout(() => {
				this.setState({ isResizing: false });
			}, 200)

		}
	}

	handleTouchStart = (key, e) => {
		if (this.state.isAmphiVisible) {
			this.setState({ isAmphiVisible: false });
		} else {
			this.handleMouseDown(key, e.touches[0]);
		}
	};

	handleTouchMove = (e) => {
		e.preventDefault();
		if (e.touches && e.touches[0])
			this.handleMouseMove({ pageX: e.touches[0].clientX, pageY: e.touches[0].clientY });
	};

	handleTouchEnd = (e) => {
		if (e.changedTouches && e.changedTouches[0])
			this.handleMouseUp({ pageX: e.changedTouches[0].clientX, pageY: e.changedTouches[0].clientY });
	}

	handleAmphiClick = (action, value) => {
		this.props.onChange(action, value);
		this.setState({ isAmphiVisible: false });
	};

	handleDoubleClick = (key) => {
		this.setState({
			isAmphiVisible:
				this.props.mode && this.props.mode.name == 'linking'
					? false
					: true,
			isPressed: false,
			lastPress: null,
			mouseCircleDelta: [0, 0],
			mouseXY: [0, 0]
		});
	}

	handleMouseUp = ({ pageX, pageY }) => {
		let timeDiff = Date.now() - this.startDragAt;

		if (!this.hasMoved || timeDiff < 250) {
			this.setState({
				isPressed: false,
				lastPress: null,
				mouseCircleDelta: [0, 0],
				mouseXY: [0, 0]
			});
			return;
		}
		this.hasMoved = false;
		this.startDragAt = null;

		const {
			lastPress,
			isPressed,
			mouseCircleDelta: [dx, dy]
		} = this.state;

		if (lastPress !== null) {
			let coords = this.radar.normalizeCoordinates(
				this.props.height,
				this.props.height,
				this.props.offsetX,
				this.props.offsetY,
				pageX,
				pageY
			);
			coords[0] = Math.max(-125, coords[0]);
			coords[0] = Math.min(125, coords[0]);
			coords[1] = Math.max(-125, coords[1]);
			coords[1] = Math.min(125, coords[1]);

			this.props.onUpdateItem(
				this.props.children[this.state.lastPress].id,
				{
					x: coords[0],
					y: coords[1]
				}
			);
		}

		this.setState({
			isPressed: false,
			lastPress: null,
			mouseCircleDelta: [0, 0],
			mouseXY: [0, 0]
		});
	};

	handleMouseMove = ({ pageX, pageY }) => {
		const {
			lastPress,
			isPressed,
			mouseCircleDelta: [dx, dy]
		} = this.state;

		if (isPressed) {
			this.hasMoved = true;
			//console.log('handleMouseMove', { pageX, pageY }, this.state.mouseCircleDelta)
			const mouseXY = [pageX - dx, pageY - dy];
			this.setState({ mouseXY, isAmphiVisible: false });
		}
	};

	handleMouseDown = (key, { pageX, pageY }) => {
		//console.log('handleMouseDown', pageX, pageY)

		// Current denormalized coordinates
		this.props.select(this.props.children[key]);
		if (!this.props.mode || this.props.mode.name != 'initial') return;

		//console.log(this.props.select);

		let [dx, dy] = this.radar.denormalizeCoordinates(
			this.props.height,
			this.props.height,
			this.props.offsetX,
			this.props.offsetY,
			this.props.children[key].x,
			this.props.children[key].y
		);
		this.hasMoved = false;
		this.startDragAt = Date.now();

		const mouseXY = [pageX, pageY];
		const mouseCircleDelta = [dx - pageX, dy - pageY];

		//console.log('handleMouseDown', key, pressX, pressY, pageX, pageY);
		this.setState({
			lastPress: key,
			isPressed: true,
			mouseCircleDelta,
			mouseXY: mouseXY
		});
	};

	render() {

		const itemsCount = (this.props.children || []).length;
		const itemScale = this.radar.item_scale(itemsCount)
		const { lastPress, isPressed } = this.state;
		const { height, t } = this.props;
		const indexedChildren = keyBy(this.props.children, 'id');

		let shouldTransition = !this.state.isResizing && this.props.isTransitioning && !isPressed;

		const targetLinks = this.props.selection
			? flatten(this.props.children.map((c) => c.links)).filter((l) => {
				return (
					l.originId == this.props.selection.id ||
					l.targetId == this.props.selection.id
				);
			})
			: this.props.displayAllLinks
				? flatten(this.props.children.map((c) => c.links))
				: [];

		let highlightedItems = {};
		if (this.props.selection) {
			highlightedItems[this.props.selection.id] = true;

			targetLinks.forEach((l) => {
				highlightedItems[l.targetId] = true;
				highlightedItems[l.originId] = true;
			});
		}

		//console.log('Radar children', this.props.children)

		let amphiCoordinates = this.props.selection
			? this.radar.denormalizeCoordinates(
				this.props.height,
				this.props.height,
				0,
				0,
				this.props.selection?.x,
				this.props.selection?.y
			)
			: [null, null];

		return (
			<div
				id="MainRadar"
				className="noselection"
				style={{ width: height + 'px', height: height + 'px' }}
			>
				<Amphi
					key={this.props.selection?.id || 'empty'}
					x={amphiCoordinates[0]}
					y={amphiCoordinates[1]}
					visible={this.state.isAmphiVisible}
					onChange={this.handleAmphiClick}
					onDismiss={() => {
						//console.log('dismiss')
						this.setState({ isAmphiVisible: false });
					}}
					selection={this.props.selection}
				></Amphi>
				<svg
					id="vectorialRadar"
					viewBox="0 0 560 560"
					width={height + 'px'}
					height={height + 'px'}
					onClick={() =>
						this.props.deselect(() => {
							this.setState({ isAmphiVisible: false });
						})
					}
				>
					<defs>
						<mask id="positive">
							<rect
								width="100%"
								height="50%"
								x="0"
								y="0"
								fill="white"
							></rect>
						</mask>
						<mask id="negative">
							<rect
								width="100%"
								height="50%"
								x="0"
								y="280"
								fill="white"
							></rect>
						</mask>

						<path
							id="externalTopPath"
							d="M115,222 a115,115 0 0,1 330,0"
						></path>
						<path
							id="externalBottomPath"
							d="M115,388 a115,115 0 0,0 330,0"
						></path>
						<path
							id="internalTopPath"
							d="M115,280 a115,115 0 0,1 330,0"
						></path>
						<path
							id="internalBottomPath"
							d="M115,280 a115,115 0 0,0 330,0"
						></path>
						<linearGradient
							id="greenToRed"
							gradientUnits="userSpaceOnUse"
							x1="0" y1="0" x2="0" y2="1"
						>
							<stop
								offset="49.49999%"
								style={{ stopColor: '#24CCB8' }}
							/>
							<stop
								offset="49.49999%"
								style={{ stopColor: '#E70549' }}
							/>
						</linearGradient>
					</defs>

					{this.props.isLegendVisible && (
						<g
							className="Legend noselection"
							transform={'translate(50, 0 )'}
						>
							<g
								className="positive"
								transform={
									'translate(-' +
									(560 / 2 + 20) +
									', ' +
									560 / 2 +
									' ) rotate(-90 0 0)'
								}
							>
								<text x="20" y={560 / 2 - 35}>
									Favorable
								</text>
								<text x="20" y={560 / 2 + 2}>
									Zone
								</text>
							</g>
							<g
								className="neutral"
								transform={
									'translate(-' + 166 + ', ' + 46 + ' ) '
								}
							>
								<text x="80" y={560 / 2 - 40}>
									Neutral
								</text>
							</g>
							<g
								className="negative"
								transform={
									'translate(-' +
									(560 / 2 + 20) +
									', ' +
									560 / 2 +
									' ) rotate(-90 0 0)'
								}
							>
								<text x="-20" y={560 / 2 - 35}>
									Hostile
								</text>
								<text x="-20" y={560 / 2 + 2}>
									Zone
								</text>
							</g>
						</g>
					)}

					<circle
						className="circle2"
						r="165"
						cx="280"
						cy="280"
					></circle>
					<circle
						className="circle1"
						r="100"
						cx="280"
						cy="280"
					></circle>

					<g className="circle3">

						<g transform={`scale(${560 / 747}) translate(${747 / 2}, ${747 / 2})`}>
							<path
								strokeWidth={'0'}
								strokeOpacity={0}
								fill="url(#greenToRed)"
								d="M308,3c0-165.8-132.3-300.6-297.1-304.9c0,0,0,0,0,0c0,0-7.9-10.3-7.9-10.3s-6.7,9-7.9,10.3
			c0,0,0,0,0,0C-169.7-297.6-302-162.8-302,3c0,165.8,132.3,300.7,297.1,304.9L3,318.2l7.9-10.3C175.7,303.7,308,168.8,308,3z
			 M9.4,304.4l-2.7,3.5l0,0l-3.6,4.8l-3.6-4.8l0,0l-2.7-3.5C-166.9,301-298.5,167.4-298.5,3S-167-295-3.4-298.4l2.7-3.5l0,0l3.7-4.8
			l3.7,4.8l0,0l2.7,3.5C173-295,304.5-161.4,304.5,3S172.9,301,9.4,304.4z"/>

						</g>
					</g>

					<text
						dy="-18px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="1px"
					>
						<textPath
							className="noselection"
							xlinkHref="#externalBottomPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.external_inner_negative'
							)}
						</textPath>
					</text>
					<text
						dy="-18px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="1px"
					>
						<textPath
							className="noselection"
							xlinkHref="#externalBottomLeftPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.external_inner_negative'
							)}
						</textPath>
					</text>
					<text
						dy="-18px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="1px"
					>
						<textPath
							className="noselection"
							xlinkHref="#externalBottomRightPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.external_inner_negative'
							)}
						</textPath>
					</text>

					<text
						dy="-21.5px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="-0.6px"
					>
						<textPath
							className="noselection"
							xlinkHref="#externalTopPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.external_inner_positive'
							)}
						</textPath>
					</text>
					<text
						dy="-21.5px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="-0.6px"
					>
						<textPath
							className="noselection"
							xlinkHref="#internalTopPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.internal_low_positive'
							)}
						</textPath>
					</text>
					<text
						dy="39.5px"
						style={{ fill: 'rgba(255,255,255,0.15)' }}
						letterSpacing="2.5px"
					>
						<textPath
							className="noselection"
							xlinkHref="#internalTopPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.internal_medium_positive'
							)}
						</textPath>
					</text>
					<text
						className="noselection"
						dy={this.props.isDecisions ? '-40' : '-20'}
						x="280"
						y="280"
						style={{ fill: 'rgba(255,255,255,0.16)' }}
					>
						{t(
							'models.item.activeness_zones.internal_high_positive'
						)}
					</text>
					<text
						dy="35px"
						style={{ fill: 'rgba(255,255,255,0.14)' }}
						letterSpacing="-0.7px"
					>
						<textPath
							className="noselection"
							xlinkHref="#internalBottomPath"
							startOffset="50%"
						>
							{t(
								'models.item.activeness_zones.internal_low_negative'
							)}
						</textPath>
					</text>
					<text
						dy="-26.5px"
						style={{ fill: 'rgba(255,255,255,0.15)' }}
						letterSpacing="3px"
					>
						<textPath
							className="noselection"
							xlinkHref="#internalBottomPath"
							startOffset="50%"
							letterSpacing="2.4px"
						>
							{t(
								'models.item.activeness_zones.internal_medium_negative'
							)}
						</textPath>
					</text>
					<text
						className="noselection"
						dy={this.props.isDecisions ? '50' : '30'}
						x="280"
						y="280"
						style={{ fill: 'rgba(255,255,255,0.16)' }}
					>
						{t(
							'models.item.activeness_zones.internal_high_negative'
						)}
					</text>
					<line
						className="horizon"
						x1="51"
						x2="509"
						y1="280"
						y2="280"
					></line>
					<line
						className="horizon outside"
						x1="40"
						x2="51"
						y1="280"
						y2="280"
					></line>
					<line
						className="horizon outside"
						x1="509"
						x2="520"
						y1="280"
						y2="280"
					></line>

					{targetLinks
						? targetLinks.map((l, index) => {
							if (
								!indexedChildren[l.originId] ||
								!indexedChildren[l.targetId]
							)
								return null;

							let reversed =
								this.props.selection &&
								l.originId !== this.props.selection.id;
							let originCoords = [
								indexedChildren[l.originId].x,
								indexedChildren[l.originId].y
							];
							let targetCoords = [
								indexedChildren[l.targetId].x,
								indexedChildren[l.targetId].y
							];

							if (
								this.props.selection &&
								this.state.lastPress !== null &&
								(l.originId === this.props.selection.id ||
									l.targetId ===
									this.props.selection.id) &&
								this.state.isPressed
							) {
								let [x, y] = this.state.mouseXY;

								x += this.state.mouseCircleDelta[0];
								y += this.state.mouseCircleDelta[1];

								if (reversed) {
									targetCoords =
										this.radar.normalizeCoordinates(
											this.props.height,
											this.props.height,
											this.props.offsetX,
											this.props.offsetY,
											x,
											y
										);
								} else {
									originCoords =
										this.radar.normalizeCoordinates(
											this.props.height,
											this.props.height,
											this.props.offsetX,
											this.props.offsetY,
											x,
											y
										);
								}
							}

							let coords1 = this.radar.denormalizeCoordinates(
								radarOriginalSize,
								radarOriginalSize,
								0,
								0,
								originCoords[0],
								originCoords[1]
							);

							let coords2 = this.radar.denormalizeCoordinates(
								radarOriginalSize,
								radarOriginalSize,
								0,
								0,
								targetCoords[0],
								targetCoords[1]
							);

							const lineStyle = {
								x1: shouldTransition
									? spring(coords1[0], springSettings)
									: coords1[0],
								x2: shouldTransition
									? spring(coords2[0], springSettings)
									: coords2[0],
								y1: shouldTransition
									? spring(coords1[1], springSettings)
									: coords1[1],
								y2: shouldTransition
									? spring(coords2[1], springSettings)
									: coords2[1]
							};

							return [
								<Motion key={l.id + 'l1'} style={lineStyle}>
									{({ x1, x2, y1, y2 }) => (
										<line
											className={[
												'linkBackground',
												originCoords[1] > 5
													? 'positive'
													: '',
												originCoords[1] < -5
													? 'negative'
													: '',
												originCoords[1] <= 5 &&
													originCoords[1] >= -5
													? 'neutral'
													: '',
												this.state.isResizing ? "resizing" : ""
											].join(' ')}
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												this.props.onClickLink(
													l,
													l.strength >= 3 ? 1 : (l.strength + 1)
												)
											}}
											onDoubleClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
											}}
											strokeWidth={l.strength}
											x1={x1}
											x2={x2}
											y1={y1}
											y2={y2}
										></line>
									)}
								</Motion>,
								<Motion key={l.id + 'l2'} style={lineStyle}>
									{({ x1, x2, y1, y2 }) => {
										const isInactive = isItemInactive({
											x: originCoords[0],
											y: originCoords[1]
										});
										return (
											<line
												key={index + 'f'}
												className={[
													'link',
													originCoords[1] > 5 &&
														!isInactive
														? 'positive'
														: '',
													originCoords[1] < -5 &&
														!isInactive
														? 'negative'
														: '',
													isInactive
														? 'neutral'
														: '',
													this.state.isResizing ? "resizing" : ""
												].join(' ')}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													this.props.onClickLink(
														l,
														l.strength >= 3 ? 1 : (l.strength + 1)
													)
												}}
												onDoubleClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
												}}
												stroke={'rgb(0, 192, 255)'}
												strokeWidth={l.strength}
												strokeDasharray={
													[
														'',
														'2, 8',
														'5, 4',
														'10 1'
													][l.strength]
												}
												x1={x1}
												x2={x2}
												y1={y1}
												y2={y2}
											></line>
										);
									}}
								</Motion>
							];
						})
						: null}
				</svg>
				<div
					className="Items"
					style={{
						height: '0px',
						width: '0px'
					}}
				>
					{(this.props.children || []).map((c, index) => {
						let style;
						let x = c.x;
						let y = c.y;
						let selected = c.id == this.props.selection?.id;

						if (index === lastPress && isPressed) {
							let [x, y] = this.state.mouseXY;

							selected = true;
							x += this.state.mouseCircleDelta[0];
							y += this.state.mouseCircleDelta[1];
							style = {
								translateX: x - this.props.offsetX,
								translateY: y - this.props.offsetY,
								scale: spring(1.2 * itemScale, springSetting1)
							};
						} else {
							let coords = this.radar.denormalizeCoordinates(
								this.props.height,
								this.props.height,
								0,
								0,
								x,
								y
							);
							x = coords[0];
							y = coords[1];

							style = {
								translateX: shouldTransition
									? spring(x, springSettings)
									: x,
								translateY: shouldTransition
									? spring(y, springSettings)
									: y,
								scale: spring(itemScale, springSetting1)
							};
						}

						return (
							<Motion key={c.id} style={style}>
								{({ translateX, translateY, scale }) => (
									<Item
										key={c.id}
										{...c}
										selected={selected}
										isResizing={this.state.isResizing}
										isExternallyCreated={(this.props.recentCreations || {})[c.id]}
										fade={
											this.props.selection &&
											!highlightedItems[c.id]
										}
										onMouseDown={this.handleMouseDown.bind(
											this,
											index
										)}
										onTouchStart={this.handleTouchStart.bind(
											this,
											index
										)}
										onDoubleClick={this.handleDoubleClick.bind(
											this,
											index
										)}
										onClick={this.handleMouseDown.bind(
											this,
											index
										)}

										style={{
											WebkitTransform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
											transform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
											zIndex: index === lastPress ? 99 : 1
										}}
									></Item>
								)
								}
							</Motion>

						);
					})}
				</div>
			</div >
		);
	}
}

export default withTranslation()(MainRadar);
