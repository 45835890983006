import { defaults as StakeholderDefaults } from './StakeholderModel';
import { defaults as StakeholderLinkDefaults } from './StakeholderLinkModel';
import { defaults as ScenarioDefaults } from './ScenarioModel';
import { defaults as TagDefaults } from './TagModel';
import { defaults as RiskDefaults } from './RiskModel';
import { defaults as ConstraintDefaults } from './ConstraintModel';
import { defaults as IndicatorDefaults } from './IndicatorModel';

export const defaults = {
	Stakeholder: StakeholderDefaults,
	StakeholderLink: StakeholderLinkDefaults,
	Scenario: ScenarioDefaults,
	Tag: TagDefaults,
	Risk: RiskDefaults,
	Constraint: ConstraintDefaults,
	Indicator: IndicatorDefaults
};
