import React, { useState, useEffect, useRef } from 'react';


/**
 * Convertit une chaîne "hh:mm:ss,ms" en secondes.
 * Exemple : "00:01:02,500" → 62.5 secondes
 */
function parseTime(timeString) {
    const [time, ms] = timeString.split(',');
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds + parseInt(ms, 10) / 1000;
}

/**
 * Parse le contenu d’un fichier SRT en un tableau d’objets.
 * Chaque objet contient : startTime, endTime et text.
 */
function parseSRT(data) {
    const subtitles = [];
    // Découpage par blocs (séparés par deux retours à la ligne)
    const blocks = data.split(/\r?\n\r?\n/);
    blocks.forEach(block => {
        const lines = block.trim().split(/\r?\n/);
        if (lines.length >= 3) {
            const timeRange = lines[1];
            const [startTimeStr, endTimeStr] = timeRange.split(' --> ');
            const startTime = parseTime(startTimeStr);
            const endTime = parseTime(endTimeStr);
            const text = lines.slice(2).join('\n');
            subtitles.push({ startTime, endTime, text });
        }
    });
    return subtitles;
}

const extractActions = (text) => {
    const actions = text.match(/\[.*?\]/g);

    if (!actions) return [];

    const actionsList = actions.map(action => action.slice(1, -1));
    // Extract each action as an object name (eg createStakeholder) and details (eg {id: "John", name: "Doe"}) from something like [updateStakeholder id=mary impact="medium"]
    return actionsList.map(action => {

        // Action name is first word
        const actionName = action.split(' ', 2)[0];
        const actionParameters = action.replace(actionName, '').trim();



        // Each parameter is in the form of key="..." or key=...
        const isolatedParameters = actionParameters.match(/(?:[^\s"]+|"[^"]*")+/g) || [];

        const actionDetails = {}
        isolatedParameters.forEach(detail => {
            const [key, value] = detail.split('=');
            actionDetails[key] = value && value[0] == '"' ? value.slice(1, -1) : value;
        })

        return { name: actionName, details: actionDetails };
    });
}

const filterActions = (text) => {
    return text.replace(/\[.*?\]/g, '');
}

/**
 * Composant React qui lit un fichier SRT et affiche le sous-titre "le plus ancien"
 * correspondant au temps actuel.
 *
 * Props :
 * - url   : l’URL du fichier SRT à charger.
 * - start : booléen indiquant si le timer doit démarrer.
 */
const Subtitles = ({ url, time, callActions, setSubtitles, script }) => {
    const [subtitles, setLocalSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState('');
    const [lastActionTime, setLastActionTime] = useState('');

    useEffect(() => {
        const parsedSubtitles = parseSRT(script);
        setLocalSubtitles(parsedSubtitles);
        setSubtitles(parsedSubtitles);

        return () => { }
    }, [url]);

    // Mise à jour du sous-titre courant en fonction du temps actuel
    useEffect(() => {
        // Récupérer tous les sous-titres dont l'intervalle inclut currentTime
        const matchingSubtitles = subtitles.find(
            s => time > s.startTime && time < s.endTime
        );

        // Sélectionner le sous-titre le plus ancien (celui avec le startTime le plus bas)
        if (matchingSubtitles) {
            setCurrentSubtitle(matchingSubtitles);

            // Execute and update actions
            const actions = extractActions(matchingSubtitles.text);
            //console.log("actions", actions)
            if (actions && matchingSubtitles.startTime !== lastActionTime) {
                callActions(actions);
                setLastActionTime(matchingSubtitles.startTime);
            }
        } else {
            setCurrentSubtitle('');
        }
    }, [time, subtitles]);

    return (null);
};

export default Subtitles;
