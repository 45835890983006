import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { Button } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-light-svg-icons';

const generateImage = () => {
    const node = document.querySelector('#MainRadar');

    const calculatedHeight = node.clientHeight * 2 + 30

    domtoimage.toBlob(node, {
        bgcolor: "#1C2333",
        height: calculatedHeight,
        width: node.clientWidth * 2 * 1.1,
        quality: 1,
        style: {
            transform: "scale(" + 2 + ")",
            marginTop: "0px",
            transformOrigin: `-10% 0%`,

            height: calculatedHeight * 1.2,
            width: node.clientWidth * 2 * 1.1,

            backgroundImage: "url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTU3LjQgMTQuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTU3LjQgMTQuMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNGRkZGRkY7fQoJLnN0MXtmb250LWZhbWlseTonQXJpYWxNVCc7fQoJLnN0Mntmb250LXNpemU6MTJweDt9Cjwvc3R5bGU+Cjx0ZXh0IHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIDEgMS44MzEwNTVlLTA0IDEwLjI5OCkiIGNsYXNzPSJzdDAgc3QxIHN0MiI+R2Vyb3NpdHVzIC0gQWxsIHJpZ2h0cyByZXNlcnZlZDwvdGV4dD4KPC9zdmc+Cg==)",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '167.5px 167.5px',
            backgroundPositionX: `0px`,
            backgroundPositionY: `${calculatedHeight / 2 - 110}px`,
        }
    })
        .then(function (blob) {
            // Current date
            saveAs(blob, `Gerositus-${new Date().toLocaleDateString().replace(/\//g, '-') + '-' +
                new Date().toLocaleTimeString().replace(/:/g, '-')
                }.png`);
        });
}

export const ExportTab = (props: any) => {



    return (

        <div className="d-flex flex-column flex-grow mt-3">
            <Button variant="text" onClick={generateImage}>
                <FontAwesomeIcon icon={faFileImage} />
                Export radar as image
            </Button>
        </div>
    );
}