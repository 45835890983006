import React from 'react';

import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '../';
import {
	faChevronLeft,
	faChevronRight,
	faClone,
	faEdit,
	faHome,
	faHouseFlag,
	faPlus
} from '@fortawesome/pro-light-svg-icons';
import ProjectModal from 'components/Modal/ProjectModal';
import ScenarioEditionModal from 'components/Modal/ScenarioEditionModal';

import { Button, Dropdown, Skeleton, Tooltip } from 'antd';

import ConfirmModal from 'components/Modal/ConfirmModal';
import { keys, sortBy } from 'lodash';

import { useProjectController } from 'hooks/useProjectController';
import { useScenarioController } from 'hooks/useScenarioController';

import './ProjectNavigation.sass';
import PromoteAsBaselineModal from 'components/Modal/PromoteAsBaselineModal';

function ProjectNavigation(props: any) {
	let history = useHistory();
	const params: any = useParams();
	const { scenarios = [] } = props;
	const { t } = useTranslation();

	const [editedItem, setEditedItem] = React.useState<any | null>(null);
	const [editedScenario, setEditedScenario] = React.useState<any | null>(
		null
	);

	const sortedScenarios = sortBy(scenarios, 'name');

	const { createScenario, updateScenario, deleteScenario, promoteScenarioAsBaseline } = useScenarioController();

	const { updateProject } = useProjectController();


	const handleAskToCreateScenario = async (referenceScenario: any) => {
		let scenarioCount = scenarios.length;

		props.pushModal(
			<ScenarioEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) =>
					createScenario(
						params.id,
						props,
						referenceScenario,
						values,
						async () => {
							props.popModal();

							//await props.refetch();
							props.onGoToScenario(scenarioCount);
						}
					)
				}
				id={false}
				values={{
					name:
						referenceScenario && referenceScenario.name !== null
							? referenceScenario.name + ' - Copy'
							: '',
					description:
						referenceScenario &&
							referenceScenario.description !== null
							? referenceScenario.description
							: '',
					impacts:
						referenceScenario && referenceScenario.impacts !== null
							? referenceScenario.impacts
							: ''
				}}
			></ScenarioEditionModal>
		);
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.scenario.messages.delete_confirmation')}
				t={t}
				onConfirm={() => handleDelete(id)}
				message={t(
					'models.scenario.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const askPromoteAsBaselineConfirmation = async (scenario) => {

		props.pushModal(
			<PromoteAsBaselineModal
				t={t}
				onSave={async (values) => {
					await promoteScenarioAsBaseline(props, scenario, values.keepCurrentBaseline, values.name, values.keepScenario);
					setEditedScenario(null);
					props.onGoToScenario(null);

				}}
				onCancel={() => {
					setEditedScenario(null);
				}}
				message={t(
					'models.scenario.messages.promote_as_baseline_explanation'
				)}
				values={{
					keepCurrentBaseline: true,
					keepScenario: true
				}}
				scenario={scenario}
			></PromoteAsBaselineModal>
		);
	};

	// Delete
	const handleDelete = async (id: string | undefined) => {

		if (id == null) {
			return;
		}
		props.onGoToPreviousScenario();
		await deleteScenario(params.id, id);
	};

	const fishboneIsActive = useRouteMatch(
		'/projects/' + params?.id + '/fishbone'
	);
	const indicatorsIsActive = useRouteMatch(
		'/projects/' + params?.id + '/' + t('pages.indicators.url')
	);
	const constraintsIsActive = useRouteMatch(
		'/projects/' + params?.id + '/' + t('pages.constraints.url')
	);
	const scenariosIsActive = useRouteMatch(
		'/projects/' + params?.id + '/scenarios'
	);
	const risksIsActive = useRouteMatch('/projects/' + params?.id + '/risks') || useRouteMatch('/projects/' + params?.id + '/opportunities') || useRouteMatch('/projects/' + params?.id + '/summary');
	const navigatorIsActive =
		useRouteMatch('/projects/' + params?.id) &&
		!scenariosIsActive &&
		!fishboneIsActive &&
		!indicatorsIsActive &&
		!constraintsIsActive &&
		!risksIsActive;

	if (props.loading) return <div className="ProjectNavigation noselection">
		<Skeleton style={{ width: "25%" }} className="mt-3 mr-1" active={true} title paragraph={false}></Skeleton>
		<Skeleton style={{ width: "60%" }} className="mt-3 mr-1" active={true} title paragraph={false}></Skeleton>
		<Skeleton className="mt-3" active={true} title paragraph={false}></Skeleton>
	</div>

	const scenarioItems = [{
		key: 0,
		index: 0,
		label: t("models.scenario.baseline"),
		disabled: props.scenarioIndex === null
	}].concat(sortedScenarios.map((s: any, index: number) => {
		return {
			key: index + 1,
			index: index + 1,
			label: s.name,
			description: s.description,
			disabled: props.scenarioIndex === index
		};
	}))

	return (
		<div className="ProjectNavigation noselection">

			<Tooltip title={t("pages.projects.actions.back_to_this")}>
				<Button className="mr-1 discreet" shape="circle" onClick={() => {
					props.setExpanded(false);
					history.push('/');
				}}>
					<FontAwesomeIcon icon={faHome} />
				</Button>
			</Tooltip>

			<div className="title text-nowrap d-flex align-items-center">
				{props.project?.name}{' '}

				<Tooltip title={t("models.project.actions.edit")}>
					<Button className="ml-1 mini discreet" shape="circle" onClick={() => setEditedItem(props.project)}>
						<FontAwesomeIcon
							style={{
								cursor: 'pointer'
							}}
							icon={faEdit}

						/>
					</Button>
				</Tooltip>

			</div>
			<div className="separator"></div>

			{props.displayConstraints && (
				<>
					<a
						className={[
							constraintsIsActive ? 'selected' : '',
							'mr-2'
						].join(' ')}
						onClick={() => {
							history.push(
								'/projects/' + params?.id + '/' + t('pages.constraints.url')
							);
						}}
					>
						{t('pages.constraints.title')}
					</a>
				</>
			)}

			{props.displayIndicators && (
				<>
					<a
						className={[
							indicatorsIsActive ? 'selected' : '',
							'mr-2'
						].join(' ')}
						onClick={() => {
							history.push(
								'/projects/' + params?.id + '/' + t('pages.indicators.url')
							);
						}}
					>
						{t('pages.indicators.title')}
					</a>
				</>
			)}

			{props.displayRisks && (
				<>
					<a
						className={[
							risksIsActive ? 'selected' : '',
							'mr-2'
						].join(' ')}
						onClick={() => {
							history.push('/projects/' + params?.id + '/risks');
						}}
					>
						{t('pages.risks.title')}
					</a>
				</>
			)}

			{(props.displayAdvancedScenarios || props.displayRisks) && (
				<>
					<a
						className={[
							navigatorIsActive ? 'selected' : '',
							'mr-2'
						].join(' ')}
						onClick={() => {
							history.push('/projects/' + params?.id);
						}}
					>
						{t('pages.navigator.title')}
					</a>
				</>
			)}

			{(props.displayAdvancedScenarios) && (
				<>
					<a
						className={[
							scenariosIsActive ? 'selected' : '',
							'mr-2'
						].join(' ')}
						onClick={() => {
							history.push(
								'/projects/' + params?.id + '/scenarios'
							);
						}}
					>
						{t('pages.scenarios.title')}
					</a>
				</>
			)}

			{props.displayFishbone && (
				<>
					<a
						className={[fishboneIsActive ? 'selected' : ''].join(
							' '
						)}
						onClick={() => {
							history.push(
								'/projects/' + params?.id + '/fishbone'
							);
						}}
					>
						{t('pages.fishbone.title')}
					</a>
				</>
			)}
			{props.displayAdvancedScenarios && (
				<div className="separator"></div>
			)}

			<div className="scenario">
				<div className="navigation mr-2">

					<Tooltip title={props.scenarioIndex + 2 > 2 ? t('models.scenario.actions.previous') : t('models.scenario.actions.previous_baseline')}>
						<Button
							shape="circle"
							className={`mini mr-05`}
							onClick={props.onGoToPreviousScenario}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</Button>
					</Tooltip>

					{props.scenarioIndex !== null
						? props.scenarioIndex + 2
						: 1}{' '}
					/
					{props.scenarioCount !== undefined
						? props.scenarioCount + 1
						: 1}

					<Tooltip title={t('models.scenario.actions.next')}>
						<Button
							shape="circle"
							className="mini ml-05"
							onClick={props.onGoToNextScenario}
						>
							<FontAwesomeIcon icon={faChevronRight} />
						</Button>
					</Tooltip>

				</div>


				<Tooltip title={t("models.scenario.messages.switch_scenario")}>
					<div className="name">

						<Dropdown menu={{
							items: scenarioItems,
							selectable: true,
							defaultSelectedKeys: props.scenarioIndex || 0,
							onClick: (item: any) => {
								props.onGoToScenario(parseInt(item.key) > 0 ? parseInt(item.key) - 1 : null);
							}

						}}
							trigger={['click']}>
							<div className="cursor-pointer">
								{props.scenario?.name || t("models.scenario.baseline")}
								<small className="ml-1">
									(
									{props.scenarioIndex === null
										? 'B'
										: 'S' + (props.scenarioIndex + 1)}
									)
								</small>

							</div>
						</Dropdown>


					</div></Tooltip>



				<div className="ml-2 mr-2 actions">
					{props.scenarioIndex !== null && (
						<Tooltip title={t('models.scenario.actions.clone')}>
							<Button
								shape="circle"
								className="createScenario mini discreet"
								onClick={() => {
									handleAskToCreateScenario(
										props.scenario
									);
								}}
							>
								<FontAwesomeIcon
									icon={faClone}
								></FontAwesomeIcon>
							</Button>{' '}
						</Tooltip>
					)}

					{props.scenarioIndex === null && (
						<Tooltip
							title={t('models.scenario.actions.create_new')}
						>
							<Button className="createScenario mini discreet" shape="circle"
								onClick={() => handleAskToCreateScenario()}
							>
								<FontAwesomeIcon
									icon={faPlus}
								></FontAwesomeIcon>
							</Button>{' '}
						</Tooltip>
					)}

					<Tooltip title={t('models.scenario.actions.edit')}>
						<Button
							shape="circle"
							className="mini mr-05 ml-05 discreet"
							onClick={() => {
								setEditedScenario(props.scenario || { id: null });
							}}
						>
							<FontAwesomeIcon
								icon={faEdit}
							></FontAwesomeIcon>
						</Button>
					</Tooltip>

					{props.scenarioIndex !== null && (
						<Tooltip title={t('models.scenario.messages.promote_as_baseline.title')}>
							<Button
								shape="circle"
								className="mini discreet"
								onClick={() => {
									askPromoteAsBaselineConfirmation(props.scenario);
									setEditedScenario(null);
								}}
							>
								<FontAwesomeIcon
									icon={faHouseFlag}
								></FontAwesomeIcon>
							</Button>{' '}
						</Tooltip>
					)}
				</div>
			</div>

			{editedItem && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null);
					}}
					onSave={(values: Object) => {
						props.onUpdate(editedItem.id, values);
						setEditedItem(null);
					}}
					id={editedItem?.id}
					key={editedItem?.id}
					values={{
						name: editedItem?.name,
						reference: editedItem?.reference,
						description: editedItem?.description,
						descriptionLong: editedItem?.descriptionLong,
						tags: editedItem?.tags,
						riskCategories: editedItem?.riskCategories,
						likelihoods: editedItem?.likelihoods,
						impacts: editedItem?.impacts,
						type: editedItem?.type,
					}}
					authorizedTypes={props.authorizedProjectTypes}
				></ProjectModal>
			)}
			{editedScenario && (
				<ScenarioEditionModal
					onHide={() => {
						setEditedScenario(null);
					}}
					onDelete={() => {
						askDeleteConfirmation(editedScenario.id);
						setEditedScenario(null);
					}}
					onSave={(values: Object) => {
						let variables = {}
						if (values.description !== undefined) variables['baselineScenarioDescription'] = values.description
						if (values.impacts !== undefined) variables['baselineScenarioImpacts'] = values.impacts

						if (!editedScenario.id) {
							if (keys(variables).length > 0) {
								updateProject(params.id, variables);
							}
						} else {
							updateScenario(editedScenario.id, scenarios, values);
						}
						setEditedScenario(null);
					}}
					id={editedScenario?.id}
					values={{
						name: editedScenario?.id ? editedScenario?.name : t("models.scenario.baseline"),
						description: editedScenario?.id ? editedScenario?.description || '' : props?.baselineScenarioDescription || '',
						impacts: editedScenario?.id ? editedScenario?.impacts || '' : props?.baselineScenarioImpacts || ''
					}}
				></ScenarioEditionModal>
			)}
		</div>
	);
}

export default ProjectNavigation;
