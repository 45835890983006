import React, { useState, useEffect } from 'react';
import SubscriptionContext from './SubscriptionContext';

import {
	useGetSubscriptionStatusQuery
} from 'graph/dist/generated';

const SubscriptionProvider = ({ children }) => {
	const [subscriptionDetails, setSubscriptionDetailsState] = useState(null);
	const [isSubscriber, setIsSubscriber] = useState(false);
	const [maxProjectsCount, setMaxProjectsCount] = useState(0);
	const [features, setFeatures] = useState({});
	const [authorizedProjectTypes, setAuthorizedProjectTypes] = useState({
		pro: false,
		risks: false,
		stakeholders: false
	});

	const avoidSubscriptions = import.meta.env.VITE_APP_MODE !== 'stakeholder';

	const getSubscription = useGetSubscriptionStatusQuery();

	const setSubscriptionDetails = (details) => {
		const isFree =
			import.meta.env.VITE_APP_MODE === 'insiderisk' ||
			import.meta.env.VITE_APP_MODE == 'corporate';

		setSubscriptionDetailsState(details);
		setIsSubscriber(isFree ? true : details && details.status === 'active');
		setMaxProjectsCount(
			isFree ? 10000 : details && details.projects ? details.projects : 0
		);

		setFeatures({
			constraints: isFree ? true : details && details.projects == 1000 || details?.features?.includes("constraints"),
			indicators: isFree ? true : details && details.projects == 1000 || details?.features?.includes("indicators"),
			fishbone: isFree ? true : details && details.projects == 1000 || details?.features?.includes("fishbone"),
			displayAdvancedScenarios: isFree
				? true
				: (details && details.projects == 1000) || details?.features?.includes("risks"),
			risks: isFree ? true : details && details.projects == 1000 || details?.features?.includes("risks"),
		});

		const allFeatures = details?.features?.includes("constraints") &&
			details?.features?.includes("indicators") &&
			details?.features?.includes("fishbone") &&
			details?.features?.includes("risks");

		setAuthorizedProjectTypes({
			pro: isFree || details?.projects == 1000 || allFeatures,
			risks: isFree || details?.projects == 1000 || details?.features?.includes("risks"),
			stakeholders: isFree || details?.projects == 1000 || details?.features?.includes("stakeholders") || allFeatures
		});
	};

	const refreshSubscription = async () => {
		if (subscriptionDetails) {
			return;
		}
		const details = await getSubscription.refetch();
		setSubscriptionDetails(details.data?.getSubscriptionStatus);
	};

	useEffect(() => {
		refreshSubscription();
	}, []);

	return (
		<SubscriptionContext.Provider
			value={{
				subscriptionDetails,
				isSubscriber,
				setSubscriptionDetails,
				maxProjectsCount,
				features,
				subscriptionIsLoading: !avoidSubscriptions && getSubscription.loading,
				subscriptionsAreDisabled: avoidSubscriptions,
				refreshSubscription,
				authorizedProjectTypes
			}}
		>
			{children}
		</SubscriptionContext.Provider>
	);
};

export default SubscriptionProvider;
