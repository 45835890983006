import { faRobot, faSparkles, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Markdown from 'react-markdown'
import Loader from "components/Loading/Loader";

import './BotMessage.sass';

export const BotMessage = (props) => {

    const content = props?.content || props.children;
    const role = props.role || "bot";
    const loading = props.loading || false;

    return (
        <div className="BotMessage d-flex">
            {role === "assistant" && <div className="bot">
                <FontAwesomeIcon icon={faSparkles} />
            </div>
            }
            <div className="message w-100">
                {role === "assistant" && content && !props.children ? <Markdown>{content}</Markdown> : content}
                {loading && <div style={{ height: "50px" }}><Loader size={30} style={{ minHeight: "50px" }}></Loader></div>}
            </div>
            {role === "user" && <div className="user">
                <FontAwesomeIcon icon={faUser} />
            </div>}
        </div>
    );
}