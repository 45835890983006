import "./MaskableComponent.sass";

export const MaskableComponent = ({ masked, children }) => {
    return (
        <div className={[
            "MaskableComponent",
            masked ? "masked" : ""
        ].join(" ")
        }>
            {children}
        </div>
    );
}