import { ApolloClient, gql } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { defaults } from 'model/';

let savedData = {};

export const createObject = (cacheClient, parentId, type, details) => {
	console.log('createObject', cacheClient, parentId, type, details);

	const preparedDetails = {
		...defaults[type],
		__typename: type,
		...details
	};

	console.log('preparedDetails', preparedDetails);

	const newElement = cacheClient.cache.writeFragment({
		id: cacheClient.cache.identify(preparedDetails),
		data: preparedDetails,
		fragment: gql`
        fragment ${type}Fragment on ${type} {
             ${['id', ...Object.keys(preparedDetails).filter((k) => k != 'id')].join('\n')}
        }
    `
	});

	console.log(
		'newElement',
		cacheClient.cache.identify(preparedDetails),
		preparedDetails,
		`
        fragment ${type}Fragment on ${type} {
            ${['id', ...Object.keys(preparedDetails).filter((k) => k != 'id')].join('\n')}
        }
    `
	);

	if (
		[
			'Stakeholder',
			'Risk',
			'Indicator',
			'Constraint',
			'Scenario',
			'Tag',
			'FishboneBranch'
		].includes(type)
	) {
		console.log(type, 'from', parentId);
		cacheClient.cache.modify({
			id: 'Project:' + parentId,
			fields: {
				[type.toLowerCase() + 's']: (values = []) => {
					return [...values.map((v) => cloneDeep(v)), newElement];
				}
			}
		});
	} else if (['StakeholderLink'].includes(type)) {
		console.log('StakeholderLink from ', parentId);
		cacheClient.cache.modify({
			id: 'Stakeholder:' + parentId,
			fields: {
				['links']: (values = []) => {
					return [...values.map((v) => cloneDeep(v)), newElement];
				}
			}
		});
	}
};

export const updateObject = (cacheClient, projectId, type, details) => {
	console.log('updateObject', {
		projectId,
		type,
		details
	});

	const preparedDetails = {
		projectId: projectId,
		__typename: type,
		...details
	};

	const changeFunctions = {};

	for (const key in details) {
		if (details.hasOwnProperty(key) && typeof details[key] !== 'object') {
			const element = details[key];
			console.log(key, element);

			changeFunctions[key] = () => {
				return details[key];
			};
		}
	}

	console.log(
		'updateObject',
		cacheClient,
		projectId,
		type,
		details,
		preparedDetails,
		changeFunctions
	);
	cacheClient.cache.modify({
		id: cacheClient.cache.identify(preparedDetails),
		fields: changeFunctions
	});

	cacheClient.refetchQueries({});
};

export const clearObjects = (cacheClient, projectId, type, details) => {
	savedData = cloneDeep(cacheClient.extract());

	console.log('savedData', savedData);
	cacheClient.cache.modify({
		id: 'Project:' + projectId,
		fields: {
			stakeholders: () => [],
			risks: () => [],
			indicators: () => [],
			constraints: () => [],
			scenarios: () => [],
			tags: () => [],
			fishboneBranchs: () => []
		}
	});
};

export const deleteObject = (cacheClient, projectId, type, details) => {
	cacheClient.cache.modify({
		id: 'Project:' + projectId,
		fields: {
			[type.toLowerCase() + 's']: (values = []) => {
				return values.filter((v) => v.id !== details.id);
			}
		}
	});
};

export const resetObjects = (cacheClient, projectId) => {
	console.log('resetObjects restoring', savedData, cacheClient);
	cacheClient.restore(savedData);

	cacheClient.cache.modify({
		id: 'Project:' + projectId,
		fields: {}
	});

	console.log(cacheClient);
};

export const getObjectType = (actionName: string) => {
	// Replace create, update, delete
	return actionName.replace(/(create|update|delete)/, '');
};

export const getAction = (actionName: string) => {
	console.log('getAction', actionName);
	// Get function based on action name, eg createStakeholder => createObject
	const fiteredActionName = actionName.replace(
		/(create|update|clear|delete|reset)/,
		''
	);
	const actionType = actionName.replace(fiteredActionName, '');

	return {
		create: createObject,
		update: updateObject,
		delete: deleteObject,
		clear: clearObjects,
		reset: resetObjects
	}[actionType];
};
