import gql from 'graphql-tag';
import { findIndex, get, isFunction, pick } from 'lodash';

import { defaults as allDefaults } from 'model';
import {
	useCreateConstraintMutation,
	useUpdateConstraintMutation,
	useUpdateConstraintScenarioMutation,
	useDeleteConstraintMutation
} from 'graph/dist/generated';
import {
	getFieldFunctions,
	getFragmentFields,
	optimisticlyUpdateScenario
} from 'utils/graphql';

export const useConstraintController = () => {
	const [createConstraint] = useCreateConstraintMutation();
	const [updateConstraint] = useUpdateConstraintMutation();
	const [updateConstraintScenario] = useUpdateConstraintScenarioMutation();
	const [deleteConstraint] = useDeleteConstraintMutation();

	const handleCreateConstraint = async (
		projectId: string,
		values: Object,
		defaults = {},
		scenario = null
	) => {
		let appliedDefaults = {
			...allDefaults.Constraint,
			...defaults
		};

		let variables = {
			projectId: projectId,
			name: values.name,
			weight: values.weight || appliedDefaults.weight,
			trend:
				values.trend !== null && values.trend !== undefined
					? values.trend
					: appliedDefaults.trend,
			description: values.description || appliedDefaults.description,
			reference: values.reference || appliedDefaults.reference,
			evaluatedAt: values.evaluatedAt || appliedDefaults.evaluatedAt
		};

		let result = await createConstraint({
			variables,
			update(cache, { data: { createConstraint } }) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						constraints(constraints = []) {
							const newConstraint = cache.writeFragment({
								id: 'Constraint:' + createConstraint.id,
								data: createConstraint,
								fragment: gql`
									fragment ConstraintFragment on Constraint {
										${getFragmentFields(variables)}
									}
								`
							});

							return [...constraints, newConstraint];
						}
					}
				});
			},
			optimisticResponse: {
				createConstraint: {
					id: 'temp-id',
					...variables,
					createdAt: new Date().toISOString(),
					scenarios: []
				}
			}
		});
		
		// Si un scénario est actif, associons les valeurs à ce scénario
		if (scenario && result.data?.createConstraint?.id) {
			const constraint = result.data.createConstraint;
			const scenarioValues = {
				trend: variables.trend || '50'
			};
			
			await optimisticlyUpdateScenario(
				constraint,
				'Constraint',
				scenario.id,
				scenarioValues,
				updateConstraintScenario
			);
		}
		
		return result;
	};

	const handleUpdateConstraint = async (
		id,
		constraints,
		scenario,
		values
	) => {
		let index = findIndex(constraints, (s: any) => s.id === id);

		let newConstraint = {
			...pick(constraints[index], [
				'name',
				'reference',
				'weight',
				'trend',
				'description',
				'reference',
				'evaluatedAt'
			]),
			...pick(values, [
				'name',
				'reference',
				'weight',
				'trend',
				'description',
				'reference',
				'evaluatedAt'
			])
		};

		if (values.evaluatedAt !== undefined)
			newConstraint.evaluatedAt =
				newConstraint.evaluatedAt === null ||
				!isFunction(newConstraint.evaluatedAt?.toISOString)
					? ''
					: newConstraint.evaluatedAt.toISOString();

		if (scenario) {
			let scenarioId = scenario.id;
			let risk = constraints[index];
			let scenarioValues =
				risk.scenarios.find((s) => s.id === scenarioId) || {};

			scenarioValues = {
				...scenarioValues,
				...pick(values, ['trend'])
			};

			await optimisticlyUpdateScenario(
				risk,
				'Constraint',
				scenarioId,
				scenarioValues,
				updateConstraintScenario
			);
		} else {
			const result = await updateConstraint({
				variables: {
					id: id,
					...newConstraint
				},
				update(cache) {
					cache.modify({
						id: `Constraint:${id}`,
						fields: getFieldFunctions(values)
					});
				},
				optimisticResponse: {
					updateConstraint: true
				}
			});
		}
	};

	const handleDelete = async (projectId: string, id: string | undefined) => {
		if (id == null) {
			return;
		}

		await deleteConstraint({
			variables: { id: id },
			update(cache) {
				cache.modify({
					id: `Project:${projectId}`,
					fields: {
						constraints(existingConstraintsRef, { readField }) {
							return existingConstraintsRef.filter(
								(ref: string) => id !== readField('id', ref)
							);
						}
					}
				});
			},

			optimisticResponse: {
				deleteConstraint: true
			}
		});
	};

	return {
		createConstraint: handleCreateConstraint,
		updateConstraint: handleUpdateConstraint,
		deleteConstraint: handleDelete
	};
};
