import React from 'react';
import { Button, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { values } from 'lodash';
import './FoldableTagList.sass';

interface FoldableTagListProps {
  title: string;
  tags: Array<{ id: string; name: string, content?: React.ReactNode }>;
  usedTags?: { [key: string]: boolean };
  selectedTags?: string[];
  emptyMessage: string;
  className?: string;
  onTagClick?: (tagId: string) => void;
}

const FoldableTagList: React.FC<FoldableTagListProps> = ({
  title,
  tags,
  usedTags = {},
  selectedTags = [],
  emptyMessage,
  className = '',
  onTagClick = () => { }
}) => {
  const [showAllTags, setShowAllTags] = React.useState<boolean>(false);

  // Determine if we need a toggle button (if there are unused tags)
  const hasUnusedTags = tags.length > 0 && (values(usedTags).length !== tags.length);

  return (
    <>
      <div className="text-left mt-4 d-flex align-items-center" style={{ marginBottom: '0.5rem' }}>
        <span className="text-nowrap" style={{ marginRight: '1rem' }}>
          {title}
        </span>

        {tags && tags.length > 0 && hasUnusedTags && (
          <div className="ml-auto">
            <Button
              shape="circle"
              className="mini discreet"
              onClick={() => setShowAllTags(!showAllTags)}
            >
              <FontAwesomeIcon
                icon={!showAllTags ? faPlus : faMinus}
              />
            </Button>
          </div>
        )}
      </div>

      <div className={`text-left ${className}`}>
        {((tags && tags.length > 0 && showAllTags) || values(usedTags).length > 0) ? (
          tags.map((tag, index) => {
            // Only show if tag is used or showAllTags is true
            return (showAllTags || usedTags[tag.id]) && (
              <Tag
                key={`tag-${index}`}
                onClick={() => onTagClick && onTagClick(tag.id)}
                className={[
                  'tag',
                  usedTags[tag.id] ? "used" : "unused",
                  selectedTags.includes(tag.id) ? '' : 'unselected'
                ].join(' ')}
              >
                {tag.name || tag.content}
              </Tag>
            );
          })
        ) : (
          <div className="text-center" style={{ color: 'rgba(46, 160, 223, 0.3)' }}>
            {emptyMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default FoldableTagList;