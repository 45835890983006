import { ConsentModal } from 'components/Modal/ConsentModal';
import React, { useEffect } from 'react';

export const Agreement = (props: any) => {



    let url = new URL(window.location.href);
    useEffect(() => {
        if (props.setExpanded) props.setExpanded(true);

    }, []);
    return <ConsentModal onConfirm={() =>
        window.location = "https://" + props.auth0Domain + '/continue?state=' + (url.searchParams && url.searchParams.get('state'))
    }></ConsentModal >
}