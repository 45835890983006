import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectItem from './ProjectItem';
import { ConfirmModal } from 'components/Modal/ConfirmModal';
import ProjectCloneModal from 'components/Modal/ProjectCloneModal';
import { sortBy } from 'lodash';

interface ProjectListProps {
	list: [],
	onSelectProject: (project: any) => {},
	onClone: (id: string, values: any) => {},
	onDelete: (id: string) => {},
	canClone: false,
	refetch: () => {},
	pushModal: (modal: any) => {},
	authorizedProjectTypes: {
		pro: boolean,
		stakeholders: boolean,
		risks: boolean
	}
};

export const ProjectsList = (props: ProjectListProps) => {
	const { t } = useTranslation();

	const askDeleteConfirmation = async (id?: string, name?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.project.messages.delete_confirmation')}
				t={t}
				onConfirm={() => props.onDelete(id)}
				message={t(
					'models.project.messages.delete_confirmation_explanation', {
					name
				}
				)}
			></ConfirmModal>
		);
	};

	const askCloneConfirmation = async (id, values) => {
		props.pushModal(
			<ProjectCloneModal
				onSave={(values: Object) => {
					props.onClone(id, {
						name: values.name,
						type: values.type,
						reference: values.reference,
					});
				}}
				id={values?.id}
				key={values?.id || 'new'}
				values={{
					name: values?.name + ' - Copy',
					reference: "",
					type: values?.type && props.authorizedProjectTypes && props.authorizedProjectTypes[values?.type] ? values?.type : null
				}}
				authorizedTypes={props.authorizedProjectTypes}
			></ProjectCloneModal>
		)
	}

	return (
		<>
			{
				sortBy((props.list || []), ["name", "reference", "createdAt"]).map((value: any, index: Number) => (
					<ProjectItem
						onDelete={() => {
							askDeleteConfirmation(value?.id, value?.name);
						}}
						onClick={props.onSelectProject}
						onClone={
							props.canClone
								? (e: any) => {
									askCloneConfirmation(
										value?.id,
										value
									);
									return false;
								}
								: null
						}
						key={value?.id}
						details={value}
					></ProjectItem>
				))
			}
		</>
	);
};