import { useEffect, useRef, useState } from "react";
import Subtitles from "./Subtitles";

import "./Tutorial.sass"
import { Button, Modal } from "antd";
import { compact, flatten, set } from "lodash";

import stakes from "./stakes.srt";
import stakeholders from "./stakeholders.srt";

const scripts = {
    stakes,
    stakeholders
}

export default ({ type, projectId, executeAction, context }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioTime, setAudioTime] = useState(0);
    const [images, setImages] = useState([]);
    const [texts, setTexts] = useState([]);
    const [imagesAreLoaded, setImagesAreLoaded] = useState(false);
    const [subtitles, setSubtitles] = useState([]);

    const audio = useRef(null);

    const applyActions = (actions) => {
        actions.forEach(applyAction)
    }

    const confirmExit = () => {
        if (isPlaying) {
            context.pushModal(
                <Modal
                    title="Exit tutorial"
                    onOk={() => {

                        context.popModal()
                        tearDownTutorial();
                        setTimeout(() => setIsPlaying(false), 100)
                    }}
                    onCancel={() => context.popModal()}
                >Are you sure you want to exit the tutorial?</Modal >
            )
        }
    }

    const loadAllImages = () => {
        if (imagesAreLoaded) return;

        let allImages = listImagesInScript();

        allImages.forEach((image) => {
            const img = new Image();
            img.src = image;
        })

        return Promise.all(allImages.map((image) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = image;
                img.onload = resolve;
            })
        }))
    }

    const listImagesInScript = () => {
        const images = compact(flatten(subtitles.map(s => {
            let matches = s ? s.match(/\[image url="(.*)"/) : [];
            return matches && matches[1]
        })))

        return images;
    }

    const prepareTutorial = async () => {

        executeAction(projectId, "clear", {});

        await loadAllImages();
        setImagesAreLoaded(true);

        context.setMaskedZones({
            "header": true,
            "sidemenu": true,
            "summary": true,
            "add": true,
            "main": true,
        });
        if (!context?.areAllLinksVisible && document.querySelector("#SideMenu .EditableListItem .Weight")) {
            document.querySelector("#SideMenu .EditableListItem .Weight").click();
        }
    }

    const tearDownTutorial = () => {
        context.deselect();
        audio.current?.pause();
        context.setMaskedZones({});
        if (audio.current) audio.current.currentTime = 0;
        executeAction(projectId, "reset", {});
        setImages([]);
        setIsPlaying(false);
    }


    const applyAction = async (action) => {
        if (action.name == "click") {


            const mouseClick = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': true
            });

            if (action.details?.target && document.querySelector(action.details.target))
                document.querySelector(action.details.target).dispatchEvent(mouseClick);

        } else if (action.name == "hover") {


            if (action.details?.delay) {
                setTimeout(() => {
                    const mouseoverEvent = new MouseEvent('mouseover', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                    });
                    if (action.details?.target && document.querySelector(action.details.target))
                        document.querySelector(action.details.target).dispatchEvent(mouseoverEvent);
                }, action.details.delay)
            } else {
                const mouseoverEvent = new MouseEvent('mouseover', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': true
                });
                if (action.details?.target && document.querySelector(action.details.target))
                    document.querySelector(action.details.target).dispatchEvent(mouseoverEvent);
            }

        } else if (action.name == "blur") {


            if (action.details?.delay) {
                setTimeout(() => {
                    const mouseoutEvent = new MouseEvent('mouseout', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                    });

                    if (action.details?.target && document.querySelector(action.details.target))
                        document.querySelector(action.details.target).dispatchEvent(mouseoutEvent);

                }, action.details.delay)
            } else {
                const mouseoutEvent = new MouseEvent('mouseout', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': true
                });

                if (action.details?.target && document.querySelector(action.details.target))
                    document.querySelector(action.details.target).dispatchEvent(mouseoutEvent);
            }

        } else if (action.name == "select") {
            // Find the item
            const type = action.details.type.toLowerCase() + "s";
            const item = context[type].find((i) => i.id == action.details.id);

            context.select(item)

        } else if (action.name == "deselect") {
            context.deselect()

        } else if (action.name == "unmask") {
            let oldMaskedZones = context.maskedZones;
            delete oldMaskedZones[action.details.target];
            context.setMaskedZones({
                ...context.maskedZones
            })

        } else if (action.name == "mask") {
            let oldMaskedZones = context.maskedZones;
            context.setMaskedZones({
                ...context.maskedZones,
                [action.details.target]: true
            })

        } else if (action.name == "text") {


            if (action.details.content) {
                let preparedStyle = (action.details.style || "").split(";").map((i) => i.split(":")).reduce((acc, i) => {
                    acc[i[0]] = ((i[1] + ""));
                    return acc;
                }, {})
                delete preparedStyle[""]



                const currentTexts = [...texts];
                setTimeout(() => {
                    setTexts([...currentTexts, {
                        content: action.details.content,
                        details: {
                            ...action.details,
                            style: {
                                ...preparedStyle,
                                opacity: 0
                            },
                            animation: action.details?.initial || "scale(1) transform(0, 0)",
                        }
                    }])
                }, action.details?.delay || 1)

                setTimeout(() => {
                    setTexts([...currentTexts.slice(0, -1), {
                        content: action.details.content,
                        details: {
                            className: "appear",
                            ...action.details,
                            style: preparedStyle,
                            animation: action.details?.final || "",
                        }
                    }])
                }, action.details?.delay + 20 || 20)
            } else {
                setTexts([{
                    content: texts[texts.length - 1].content,
                    details: {
                        ...texts[texts.length - 1].details,
                        className: "fade",
                    }
                }])
                setTimeout(() => { setTexts([]); console.log("removed") }, 2000)
            }

        } else if (action.name == "image") {
            if (action.details.url && (images.length == 0 || images[images.length - 1].url != action.details.url)) {
                const currentImages = [...images];
                setImages([...currentImages, {
                    url: action.details.url,
                    details: {
                        ...action.details,
                        animation: action.details.initial || "scale(1) transform(0, 0)",
                    }
                }])

                setTimeout(() => {
                    setImages([...currentImages.slice(0, -1), {
                        url: action.details.url,
                        details: {
                            className: "appear",
                            ...action.details,
                            animation: action.details.final || "",
                        }
                    }])
                }, 10)

            } else {
                console.log("removing image", [{
                    url: images[images.length - 1].url,
                    details: {
                        ...images[images.length - 1].details,
                        className: "fade",
                    }
                }]);
                setImages([{
                    url: images[images.length - 1].url,
                    details: {
                        ...images[images.length - 1].details,
                        className: "fade",
                    }
                }])
                setTimeout(() => { setImages([]); console.log("removed") }, 2000)
            }

        } else {
            if (action.details?.delay) {
                setTimeout(() => {
                    executeAction(action?.details?.originId || action?.details?.stakeholderId || action?.details?.riskId || action?.details?.indicatorId || projectId, action.name, action.details);
                }, action.details.delay)
            } else {
                executeAction(action?.details?.originId || action?.details?.stakeholderId || action?.details?.riskId || action?.details?.indicatorId || projectId, action.name, action.details);
            }
        }
    }

    const onClick = async () => {
        if (!isPlaying) {
            await prepareTutorial();

            if (audio.current) audio.current.currentTime = 0;
            // Change speed
            //audio.current.playbackRate = 2;

            audio.current?.play();
            setIsPlaying(!isPlaying)
        } else {
            confirmExit();
        }


    }



    useEffect(() => {
        const audioCurrent = audio.current;
        if (audioCurrent) {
            const handleTimeUpdate = () => setAudioTime(audioCurrent.currentTime);
            audioCurrent.addEventListener('timeupdate', handleTimeUpdate);

            audioCurrent.addEventListener('ended', tearDownTutorial);
            return () => {
                audioCurrent.removeEventListener('timeupdate', handleTimeUpdate);
                audioCurrent.removeEventListener('ended', tearDownTutorial);
            }
        }

    }, []);

    return <div id="Tutorial" className={
        isPlaying ? "playing" : ""
    }>
        {isPlaying && <Subtitles setSubtitles={setSubtitles} callActions={applyActions} time={audioTime} start={isPlaying} url={type} script={scripts[type]}></Subtitles>}

        {isPlaying && false ? <div className="controls">
            <div className="dot" onClick={() => audio.current.currentTime = 409} />
            <div className="dot" onClick={() => audio.current.currentTime += 10} />
            <div className="dot" onClick={() => audio.current.currentTime = 87} />
            <div className="dot" onClick={() => audio.current.currentTime = 143} />
            <div className="dot" onClick={() => audio.current.currentTime = 301} />
            <div className="dot" onClick={() => audio.current.currentTime = 421} />
            <div className="dot" onClick={() => audio.current.currentTime = 503} />
        </div> : null}
        <Button className="button" onClick={onClick}>{isPlaying ? "Exit tutorial" : "Tutorial"}</Button>
        <audio style={{ flexShrink: 0 }} ref={audio} src={"https://app.gerositus.com/tutorials/" + type + "-intro/audio.mp4"} />

        {isPlaying && texts ? texts.map((i, index) => < div key={index} className={`text-backdrop ${i.details?.className}`} style={{
            backgroundColor: i.details?.backgroundColor || "rgba(0,0,0, 0.2)"
        }}>
            <div className="text" style={{
                ...(i.details?.animation ? { transform: i.details?.animation, transitionDuration: i.details?.duration } : {}),
                ...(i.details?.style || {})
            }}>{i.content}</div>
        </div>) : null
        }

        {
            isPlaying && images ? images.map((i, index) => <div key={i.url} className={`image ${i.details?.className}`}>
                <img src={i.url} style={i.details?.animation ? { transform: i.details?.animation, transitionDuration: i.details?.duration } : {}} />
            </div>) : null
        }
    </div >

}