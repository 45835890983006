import React, { useEffect } from 'react';

import RealtimeContext from './RealtimeContext';
import AppSyncConfig from '../../app-sync-config.json';
import { gql, useApolloClient } from '@apollo/client';
import { subscribeToProjectChanges } from 'graph/queries';
import { getAction } from 'utils/cache';

let recentUpdates = {};
let recentCreations = {};

// @ts-ignore
let subdomain: string = document.location.hostname.replace(/\.gerositus.*/, '');
const config =
	import.meta.env.VITE_APP_MODE === 'corporate'
		? AppSyncConfig[import.meta.env.VITE_APP_MODE][import.meta.env.VITE_APP_CLIENT || subdomain][
		import.meta.env.VITE_APP_ENV
		]
		: AppSyncConfig[import.meta.env.VITE_APP_MODE][
		import.meta.env.VITE_APP_ENV
		];


export const RealtimeProvider = ({ children }) => {

	const apolloClient = useApolloClient();

	// Using Graphql subscription
	const subscribeToProject = async (projectId: string) => {
		const subscription = apolloClient.subscribe({
			query: subscribeToProjectChanges,
			variables: {
				projectId
			}
		}).subscribe({
			next: (data) => {
				if (!data?.data?.onProjectChange?.changes) return null;

				const changes = JSON.parse(data.data.onProjectChange.changes);
				const defaultDetails = {
					description: "",
					scenarios: [],
					comments: [],
					links: [],
					taggings: [],
					icon: null,
					trend: null,
					impact: "low",
					impacts: [],
				}
				const details = {
					...defaultDetails,
					projectId: data.data.onProjectChange.projectId,
					id: data.data.onProjectChange.objectId,
					__typename: data.data.onProjectChange.type,
					...changes
				}
				const type = data.data.onProjectChange.type
				const changeFunctions = {}

				for (const key in changes) {
					if (details.hasOwnProperty(key) && typeof details[key] !== 'object') {
						const element = details[key];
						changeFunctions[key] = () => {
							return details[key];
						}
					}
				}

				if (data.data?.onProjectChange?.operation == "INSERT") {
					apolloClient.cache.modify({
						id: "Project:" + data.data?.onProjectChange?.projectId,
						fields: {
							[type.toLowerCase() + "s"]: (values = []) => {
								const newElement = apolloClient.cache.writeFragment({
									id: apolloClient.cache.identify(details),
									data: details,
									fragment: gql`
										fragment ConstraintFragment on ${type} {
											${["id", ...Object.keys(changes)].join("\n")}
										}
									`
								});

								return [...values, newElement];
							}
						},
						broadcast: false
					});

					recentCreations[data.data.onProjectChange.objectId] = true;
					setTimeout(() => {
						delete recentCreations[data.data.onProjectChange.objectId];
					}, 6000)

				} else {
					apolloClient.cache.modify({
						id: apolloClient.cache.identify(details),
						fields: changeFunctions,
						broadcast: false
					});
				}
			},
			error: (error) => {
				console.error('subscription error', error);
			}
		});
	}

	const subscribeToProjects = async (projectId: string) => {
		const subscriptionCreate = apolloClient.subscribe({
			query: gql`
				subscription onCreateProject {
					onCreateProject {
						id
						name
						description
					}
				}
			`
		}).subscribe({
			next: (data) => {
				// Process subscription data
			},
			error: (error) => {
				console.error('subscription error', error);
			}
		});
	}

	const state = {
		subscribeToProjects,
		subscribeToProject,
		recentCreations,
		recentUpdates,
		unsubscribeFromProject: () => { },
		unsubscribeFromProjects: () => { },
		executeAction: (projectId, actionName, details) => {
			const fiteredActionName = actionName.replace(/(create|update|delete|clear|reset)/, '');
			const actionType = actionName.replace(fiteredActionName, '');
			delete details.delay;

			if (actionType == "create") {
				recentCreations[details.id] = true;
				setTimeout(() => {
					delete recentCreations[details.id];
				}, 6000)
			}

			const action = getAction(actionName);
			if (action) {
				action(apolloClient, projectId, fiteredActionName, details);
			}
		}
	};

	return (
		<RealtimeContext.Provider value={state}>
			{children}
		</RealtimeContext.Provider>
	);
}