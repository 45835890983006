import React from 'react';

import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
//import ReactECharts from 'echarts-for-react';

import { sortBy, keyBy, intersection, uniq, compact } from 'lodash';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEdit, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { Button, Popover, Tabs, Tag, Tooltip } from 'antd';

import { useRiskController } from 'hooks/useRiskController';
import { getItemAlteredByScenario, isRiskOrOpportunityImpactBetter, isRiskOrOpportunityImpactWorse, isRiskOrOpportunityLikelihoodBetter, isRiskOrOpportunityLikelihoodWorse } from 'utils/rules';

import AddButton from 'components/action/AddButton';
import RiskEditionModal from 'components/Modal/RiskEditionModal';
import HeaderPortal from 'layout/header/HeaderPortal';
import { ConfirmModal } from 'components/Modal/ConfirmModal';

import { RiskScatterFullChart } from 'components/charts/RiskScatterFullChart';
import Table from 'components/Table';
import RiskPopover from './RiskPopover';
import RiskTooltipContent from './RiskTooltipContent';

import { StakeholderModel } from 'model/StakeholderModel';
import { StakeholderRiskImpactModel } from 'model/StakeholderRiskImpactModel';
import { RiskModel } from 'model/RiskModel';

import { FiltersTab } from './Tabs/FiltersTab';
import { OptionsTab } from './Tabs/OptionsTab';
import { RiskGeneralTab } from './Tabs/RiskGeneralTab';
import { IndicatorModel } from 'model/IndicatorModel';

import { StakeholdersTab } from './Tabs/StakeholdersTab';
import { useStakeholderController } from 'hooks/useStakeholderController';
import { IndicatorGeneralTab } from '../Indicators/Tabs/IndicatorGeneralTab';
import { useAuth0 } from '../../../react-auth0-spa';
import BotTab from 'components/tabs/BotTab';
import GeneralTab from '../Navigator/Tabs/GeneralTab';

import './Risks.sass';
import { SideMenu } from 'layout/SideMenu';
import { ExportTab } from './Tabs/ExportTab';

type FilterObject = {
	name: string,
	riskFilter: string,
	categoryFilter: string[] | null[],
	impactFilter: number[],
	likelihoodFilter: number[],
	tagFilter: string[],
	stakeholderFilter: string[],
	indicatorFilter: string[],
	indexedStakeholdersWithRisks: {
		[key: string]: string[]
	},
}

const selectableElementTypes = ['Risk', 'Indicator', 'Stakeholder']

const filterRisks = (isRiskMode: boolean, risks: any[], filters: FilterObject) => {

	let limitedRisks = risks.filter(r => (!!r.isOpportunity) != isRiskMode)
	limitedRisks = limitedRisks.map((r, index) => ({ ...r, index }))

	if (filters && filters.riskFilter) {
		limitedRisks = limitedRisks.filter(r => r.id == filters.riskFilter)
	}

	if (filters && filters.categoryFilter && filters.categoryFilter.length > 0) {
		limitedRisks = limitedRisks.filter(r => filters.categoryFilter.includes(r.riskCategoryId) || (!r.riskCategoryId && filters.categoryFilter.includes(null)))
	}

	if (filters && filters.impactFilter && filters.impactFilter.length > 0) {
		limitedRisks = limitedRisks.filter(r => filters.impactFilter.includes("" + parseInt(r.impact)))
	}

	if (filters && filters.likelihoodFilter && filters.likelihoodFilter.length > 0) {
		limitedRisks = limitedRisks.filter(r => filters.likelihoodFilter.includes("" + parseInt(r.likelihood)))
	}

	if (filters && filters.tagFilter && filters.tagFilter.length > 0) {
		limitedRisks = limitedRisks.filter(r => r.taggings && r.taggings.length > 0)
		// Keep only risks that have at least one of the tags
		limitedRisks = limitedRisks.filter(r => intersection(r.taggings.map(t => t.tagId), filters.tagFilter).length > 0)

	}

	if (filters && filters.indicatorFilter && filters.indicatorFilter.length > 0) {
		// Keep only risks that have at least one of the indicators
		filters.indicatorFilter.forEach((i: string) => {
			limitedRisks = limitedRisks.filter(r => {
				return r.impactedIndicators.map(ri => ri.indicatorId).includes(i)
			})
		})
	}

	if (filters && filters.stakeholderFilter && filters.stakeholderFilter.length > 0) {
		let allRisksIds: string[] = []
		filters.stakeholderFilter.forEach((s: string) => {
			if (filters.indexedStakeholdersWithRisks[s] && filters.indexedStakeholdersWithRisks[s].length > 0) {
				allRisksIds = allRisksIds.concat(filters.indexedStakeholdersWithRisks[s])
			}
		})
		// Keep only risks that have at least one of the stakeholders
		limitedRisks = limitedRisks.filter(r => {
			return allRisksIds.includes(r.id)
		})
	}

	if (filters && filters.name) {
		limitedRisks = limitedRisks.filter(r => {
			return r.name.toLowerCase().includes(filters.name.toLowerCase())
		})
	}

	return limitedRisks
}

const defaultValuesBasedOnFilters = (filters: FilterObject) => {

	let defaults = {}

	//console.log('defaultValuesBasedOnFilters', filters)

	// Apply new values based on filters
	if (filters && filters.categoryFilter && filters.categoryFilter.length > 0) {
		defaults.riskCategoryId = filters.categoryFilter[0]
	}

	if (filters && filters.impactFilter && filters.impactFilter.length > 0) {
		defaults.impact = filters.impactFilter[0]
	}

	if (filters && filters.likelihoodFilter && filters.likelihoodFilter.length > 0) {
		defaults.likelihood = filters.likelihoodFilter[0]
	}

	if (filters && filters.tagFilter && filters.tagFilter.length > 0) {
		defaults.taggings = filters.tagFilter
	}

	return defaults
}


export const Risks = (props: any) => {

	const { user } = useAuth0();

	let history = useHistory();
	const params: any = useParams();
	const location = useLocation();
	const isRiskMode = location.pathname.includes('risks');
	const isSummaryMode = location.pathname.includes('summary');

	const { risks = [], stakeholders = [], indicators = [], likelihoods = [], impacts = [], tags = [], riskCategories = [], scenarios = [] } = props;

	const selection = props.selection?.__typename === "Risk" ? risks.find((r: any) => r.id === props.selection?.id) : props.selection?.__typename === "Indicator" ? indicators.find((r: any) => r.id === props.selection?.id) : props.selection?.__typename === "Stakeholder" ? stakeholders.find((r: any) => r.id === props.selection?.id) : null;

	const [editedItem, setEditedItem] = React.useState<any | null>(null);

	const { createRisk, updateRisk, deleteRisk, createIndicatorImpact, deleteIndicatorImpact, updateIndicatorImpactStrength } = useRiskController();
	const { createRiskImpact, updateRiskImpactStrength, deleteRiskImpact } = useStakeholderController();
	const indexedStakeholders = keyBy(stakeholders, 'id');

	const { t } = useTranslation();

	const isEvaluatedAtVisible = props.preferences?.pages?.risks?.evaluatedAt !== undefined ? props.preferences?.pages?.risks?.evaluatedAt : true
	const isRiskCategoryVisible = props.preferences?.pages?.risks?.riskCategory !== undefined ? props.preferences?.pages?.risks?.riskCategory : true
	const isStakeholdersVisible = props.preferences?.pages?.risks?.stakeholders !== undefined ? props.preferences?.pages?.risks?.stakeholders : false
	const isMatchingROVisible = props.preferences?.pages?.risks?.matchingRO !== undefined ? props.preferences?.pages?.risks?.matchingRO : false
	const areLinksVisible = props.preferences?.pages?.risks?.allLinks !== undefined ? props.preferences?.pages?.risks?.allLinks : false
	const showStakes = props.preferences?.pages?.risks?.stakesChart !== undefined ? props.preferences?.pages?.risks?.stakesChart : true
	const showRisks = props.preferences?.pages?.risks?.risksChart !== undefined ? props.preferences?.pages?.risks?.risksChart : true
	const showOpportunities = props.preferences?.pages?.risks?.opportunitiesChart !== undefined ? props.preferences?.pages?.risks?.opportunitiesChart : true
	const showStakeholders = props.preferences?.pages?.risks?.stakeholdersChart !== undefined ? props.preferences?.pages?.risks?.stakeholdersChart : true

	let impactedStakesholders = stakeholders.filter(
		(s) => s.impactedRisks.some((ir) => ir.riskId === selection?.id)
	);

	//console.log('Risks screen', props)

	const unfilteredRisks = (props.scenario?.id ? risks.map(r => getItemAlteredByScenario(r, props.scenario)) : risks)
	const alteredRisks = unfilteredRisks.filter(r => isSummaryMode || isRiskMode == !r.isOpportunity);

	// Filters - Categories
	const allCategories = ([{ id: null, name: "Unassigned" }]).concat(sortBy(riskCategories, "name"));
	const [categoryFilter, setCategoryFilter] = React.useState<string[]>([]);

	// Filters - Impacts
	const [impactFilter, setImpactFilter] = React.useState<number[]>([]);

	// Filters - Likelihoods
	const [likelihoodFilter, setLikelihoodFilter] = React.useState<number[]>([]);

	// Filters - Tags
	let usedTags = uniq(alteredRisks.map((r: RiskModel) => r.taggings.map((t: any) => t.tagId)).flat());
	const allTags: any = sortBy(tags, 'name');

	const [tagFilter, setTagFilter] = React.useState<string[]>([]);

	// Filters - Stakeholders
	const indexedStakeholdersWithRisks = {};
	const groupedStakeholdersByRisk = {};
	const indexedRisks = keyBy((alteredRisks || []), 'id');

	stakeholders.forEach((s: StakeholderModel) => {
		s.impactedRisks.filter(r => isRiskMode !== r.isOpportunity).forEach((ir: StakeholderRiskImpactModel) => {

			if (indexedRisks[ir.riskId]) {

				if (!indexedStakeholdersWithRisks[s.id]) {
					indexedStakeholdersWithRisks[s.id] = []
				}

				if (!groupedStakeholdersByRisk[ir.riskId]) {
					groupedStakeholdersByRisk[ir.riskId] = []
				}

				groupedStakeholdersByRisk[ir.riskId].push(s)
				indexedStakeholdersWithRisks[s.id].push(ir.riskId)
			}
		})
	})
	const allStakeholders: StakeholderModel[] = sortBy(stakeholders, 'name').filter((s: StakeholderModel) => !!indexedStakeholdersWithRisks[s.id]);
	const [stakeholderFilter, setStakeholderFilter] = React.useState<string[]>([]);

	// Filters - Stakes
	const indexedIndicatorsWithRisks = {};
	const groupedIndicatorsByRisk = {};

	risks.forEach((r: RiskModel) => {
		r.impactedIndicators.forEach((ii: StakeholderRiskImpactModel) => {

			if (indexedRisks[ii.riskId]) {

				if (!indexedIndicatorsWithRisks[ii.indicatorId]) {
					indexedIndicatorsWithRisks[ii.indicatorId] = []
				}

				if (!groupedIndicatorsByRisk[ii.riskId]) {
					groupedIndicatorsByRisk[ii.riskId] = []
				}

				groupedIndicatorsByRisk[ii.riskId].push(r)
				indexedIndicatorsWithRisks[ii.indicatorId].push(ii.riskId)
			}
		})
	})
	const allIndicators: IndicatorModel[] = sortBy(indicators, 'name').filter((i: IndicatorModel) => !!indexedIndicatorsWithRisks[i.id]);
	const [indicatorFilter, setIndicatorFilter] = React.useState<string[]>([]);


	const getAggregatedFilters = (): FilterObject => {
		return {
			name: props.searchTerm,
			riskFilter: location?.state?.riskFilter,
			categoryFilter: categoryFilter,
			impactFilter: impactFilter,
			likelihoodFilter: likelihoodFilter,
			stakeholderFilter: stakeholderFilter,
			indicatorFilter: indicatorFilter,
			indexedStakeholdersWithRisks,
			tagFilter: tagFilter
		}
	}

	const filteredElements = filterRisks(isRiskMode, alteredRisks, getAggregatedFilters())
	const filteredRisks = filterRisks(true, unfilteredRisks, getAggregatedFilters())
	const filteredOpportunities = filterRisks(false, unfilteredRisks, getAggregatedFilters())

	const indexedLikelihoods = likelihoods ? keyBy(likelihoods, "id") : {}
	const indexedImpacts = impacts ? keyBy(impacts, "id") : {}


	const goToNavigatorWithRiskFilter = (riskId: string) => {
		history.push('/projects/' + params.id, {
			riskFilter: riskId
		});
	}

	const goToRisks = (mode: string, riskId: string) => {
		let allFilters = {}
		if (riskId) allFilters = { riskFilter: riskId }
		props.deselect();

		history.push('/projects/' + params.id + '/' + mode, allFilters);
	}

	// Create
	const handleCreateRisk = async () => {
		props.pushModal(
			<RiskEditionModal
				onHide={() => {
					props.popModal();
				}}
				onSave={async (values: any) => {
					await createRisk(params.id, risks, values);
					props.popModal();
				}}
				tags={tags}
				id={false}
				values={{
					...defaultValuesBasedOnFilters(getAggregatedFilters()),
					isOpportunity: isRiskMode ? false : true,
					impactedIndicators: [],
					riskIds: [],
					evaluatedAt: null
				}}
				riskCategories={riskCategories}
				likelihoods={likelihoods || []}
				impacts={impacts || []}
				risks={risks}
				indicators={indicators}
			></RiskEditionModal>
		);
	};

	const askDeleteConfirmation = async (id?: string) => {
		props.pushModal(
			<ConfirmModal
				title={t('models.risk.messages.delete_confirmation')}
				t={t}
				onConfirm={() => deleteRisk(params.id, id)}
				message={t(
					'models.risk.messages.delete_confirmation_explanation'
				)}
			></ConfirmModal>
		);
	};

	const columns = [

		{
			title: '',
			dataIndex: 'edit',
			key: 'edit',
			width: 50,
			render: (text, record) => (
				<Tooltip title={props.scenario ? null : 'Edit'}>
					<Button
						shape="circle"
						disabled={props.scenario ? true : false}
						style={{ opacity: props.scenario ? 0.2 : 1 }}
						onClick={
							props.disabled
								? () => { }
								: () => {
									setEditedItem(record);
								}
						}
					>
						<FontAwesomeIcon
							icon={faEdit}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial',
								opacity: props.disabled ? 0.5 : 1
							}}
						/>
					</Button>
				</Tooltip>
			)
		},
		{
			title: '#',
			dataIndex: 'id',
			key: 'id',
			defaultSortOrder: 'ascend',
			width: 60,
			sortable: (a, b) => {
				const scoreA = (a.reference || (a.isOpportunity ? "O" : "R") + ((a.index + 1 + "").padStart(2, "0"))).toLocaleLowerCase()
				const scoreB = (b.reference || (b.isOpportunity ? "O" : "R") + ((b.index + 1 + "").padStart(2, "0"))).toLocaleLowerCase()

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text, record, index) => {

				return record.reference || (record.isOpportunity ? "O" : "R") + ((record.index + 1 + "").padStart(2, "0"))
			}
		},
		isEvaluatedAtVisible && {
			title: t("models.risk.fields.evaluated_at"),
			dataIndex: 'evaluatedAt',
			key: 'evaluatedAt',
			width: 120,
			className: 'nowrap',
			sortable: (a, b) => {
				if (!b.evaluatedAt) return -1
				if (!a.evaluatedAt) return 1
				if (a.evaluatedAt && b.evaluatedAt && a.evaluatedAt < b.evaluatedAt) return -1;
				if (a.evaluatedAt && b.evaluatedAt > b.evaluatedAt) return 1;
				return 0
			},
			render: (text, record, index) => {

				return <Tooltip title={record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY HH[h]mm') : ''}>
					<div>
						{record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''}
					</div>
				</Tooltip>
				return record.evaluatedAt ? dayjs(record.evaluatedAt).format('DD/MM/YYYY') : ''
			}
		},
		{
			title: t("models.risk.fields.name"),
			dataIndex: 'name',
			key: 'name',
			className: 'w-100',
			sortable: true,
			ellipsis: true,
			render: (text: string, record) => {
				return (
					<><span className={[
						"name cursor-pointer",
						record.id === selection?.id ? "selected" : ""
					].join(" ")}
						onClick={() => record.id === selection?.id ? props.deselect() : props.select(record)}>
						{record.name}

					</span>
						{record.description ? (
							<div onClick={() => props.select(record)} className="description">
								{record.description}
							</div>
						) : (
							''
						)}</>
				);
			}
		},
		isRiskCategoryVisible && {
			title: 'Category',
			dataIndex: 'riskCategoryId',
			key: 'riskCategoryId',
			sortable: (a: object, b: object) => {
				const valueForA = a.riskCategoryId ? riskCategories.find(c => c.id === a.riskCategoryId) : null;
				const valueForB = b.riskCategoryId ? riskCategories.find(c => c.id === b.riskCategoryId) : null;

				if (!valueForA && !valueForB) return 0
				if (!valueForA) return -1
				if (!valueForB) return 1

				if (valueForA.name < valueForB.name) return -1;
				if (valueForA.name > valueForB.name) return 1;
				return 0
			},
			width: 120,
			render: (text: string, record) => {
				const category = riskCategories.find(
					(c: any) => c.id === record.riskCategoryId
				);
				return category ? category.name : '';
			}
		},
		{
			title: t("models.risk.fields.impact"),
			dataIndex: 'impact',
			key: 'impact',
			sortable: true,
			width: 90,
			render: (text: string, record) => {

				let isBetter = isRiskOrOpportunityImpactBetter(record);
				let isWorse = isRiskOrOpportunityImpactWorse(record);

				return <Popover
					trigger="click"
					className='cursor-pointer'
					content={<RiskPopover
						onChange={(value) => {
							updateRisk(
								record.id,
								alteredRisks,
								props.scenario,
								{ impact: value }
							);
						}}
						value={record.impact}
						values={impacts}>
					</RiskPopover>}>
					<Tooltip
						overlayStyle={{ width: "550px" }}
						title={<RiskTooltipContent
							risk={record}
							attribute={"impact"}
							scenario={props.scenario}
							scenarios={scenarios}
							impacts={impacts}
						></RiskTooltipContent>}>
						<div className={[isBetter ? "positive" : "", isWorse ? "negative" : ""].join(" ")}>
							{record.impact}
						</div>
					</Tooltip>

				</Popover>
			}
		},
		{
			title: t("models.risk.fields.likelihood"),
			dataIndex: 'likelihood',
			key: 'Likelihood',
			sortable: true,
			width: 90,
			render: (text: string, record) => {

				let isBetter = isRiskOrOpportunityLikelihoodBetter(record);
				let isWorse = isRiskOrOpportunityLikelihoodWorse(record);

				return <Popover
					trigger="click"
					className='cursor-pointer'
					content={<RiskPopover
						onChange={(value) => {
							updateRisk(
								record.id,
								alteredRisks,
								props.scenario,
								{ likelihood: value }
							);
						}}
						value={record.likelihood}
						values={likelihoods}>
					</RiskPopover>}>
					<Tooltip
						overlayStyle={{ width: "550px" }}
						title={
							<RiskTooltipContent
								risk={record}
								attribute={"likelihood"}
								scenario={props.scenario}
								scenarios={scenarios}
								likelihoods={likelihoods}
							></RiskTooltipContent>
						}>
						<div className={[isBetter ? "positive" : "", isWorse ? "negative" : ""].join(" ")}>
							{record.likelihood}
						</div>
					</Tooltip>

				</Popover>
			}
		},
		,
		{
			title: isRiskMode ? t("models.risk.fields.exposure") : t("models.risk.fields.exposure_positive"),
			dataIndex: 'exposure',
			key: 'exposure',
			width: 90,
			sortable: (a: object, b: object) => {
				const exposureA = a.likelihood * a.impact
				const exposureB = b.likelihood * b.impact

				if (exposureA < exposureB) return -1;
				if (exposureA > exposureB) return 1;
				return 0
			},
			render: (text: string, record) => {

				let isBetter = false;
				let isWorse = false;
				let lessIsBetter = isRiskMode
				let baselineExposure = record?.o?.likelihood * record?.o?.impact
				let scenarioExposure = record?.likelihood * record?.impact
				if (baselineExposure > scenarioExposure) {
					if (lessIsBetter)
						isBetter = true
					else
						isWorse = true
				}
				if (baselineExposure < scenarioExposure) {
					if (lessIsBetter)
						isWorse = true
					else
						isBetter = true
				}

				return <Tooltip
					overlayStyle={{ width: "550px" }}
					title={
						<RiskTooltipContent
							risk={record}
							attribute={"exposure"}
							scenario={props.scenario}
							scenarios={scenarios}
							likelihoods={likelihoods}
						></RiskTooltipContent>
					}>
					<div className={[isBetter ? "positive" : "", isWorse ? "negative" : ""].join(" ")}>
						{record.likelihood * record.impact}
					</div>
				</Tooltip>
			}
		},
		isStakeholdersVisible && {
			title: t("models.item.name_plural"),
			dataIndex: 'Stakeholders',
			key: 'Stakeholders',
			width: 110,
			sortable: (a: object, b: object) => {
				const scoreA = groupedStakeholdersByRisk[a.id]?.length || 0
				const scoreB = groupedStakeholdersByRisk[b.id]?.length || 0

				if (scoreA < scoreB) return -1;
				if (scoreA > scoreB) return 1;
				return 0
			},
			render: (text: string, record) => {
				return groupedStakeholdersByRisk[record.id]?.length > 0 && <>
					{groupedStakeholdersByRisk[record.id]?.length || 0}
					<Tooltip title={'View stakeholders'}>
						<Button
							shape="circle"
							className='ml-1'
							onClick={() => goToNavigatorWithRiskFilter(record.id)}
						>
							<FontAwesomeIcon
								icon={faSearch}
								style={{
									cursor: props.disabled
										? 'not-allowed'
										: 'initial',
									opacity: props.disabled ? 0.5 : 1
								}}
							/>
						</Button>
					</Tooltip>
				</>


			}
		},
		isMatchingROVisible && isRiskMode && {
			title: t("models.risk.fields.matching_opportunity"),
			dataIndex: 'matching_opportunity',
			key: 'matching_opportunity',
			width: 180,
			render: (text: string, record) => {
				const matching = risks.find(
					(c: any) => c.id === record.matchingOpportunityId
				)

				if (!matching) return null;

				return <>
					<div>
						<span style={{ textOverflow: "ellipsis", maxWidth: "90%" }}>{matching.name}</span>
						<Button
							shape="circle"
							className='mr-2 mini-button'
							onClick={() => goToRisks('opportunities', matching.id)}
						>
							<FontAwesomeIcon
								icon={faSearch}
								style={{
									cursor: props.disabled
										? 'not-allowed'
										: 'initial',
									opacity: props.disabled ? 0.5 : 1
								}}
							/>
						</Button>
					</div>
				</>
			}
		},
		isMatchingROVisible && !isRiskMode && {
			title: t("models.risk.fields.matching_risks"),
			dataIndex: 'matching_risks',
			key: 'matching_risks',
			width: 180,
			render: (text: string, record) => {
				const matching = risks.filter(
					(c: any) => c.matchingOpportunityId === record.id
				)

				if (!matching.length) return null;

				return <>
					{matching.map((oneMatch, index) => {
						return <span key={index}>
							<span style={{ textOverflow: "ellipsis", maxWidth: "90%" }}>{oneMatch.name}</span>
							<Button
								shape="circle"
								className='mr-1 mini-button'
								onClick={() => goToRisks('risks', oneMatch.id)}
							>
								<FontAwesomeIcon
									icon={faSearch}
									style={{
										cursor: props.disabled
											? 'not-allowed'
											: 'initial',
										opacity: props.disabled ? 0.5 : 1
									}}
								/>
							</Button>
						</span>
					})}

				</>
			}
		},
		{
			title: '',
			dataIndex: 'select',
			key: 'select',
			width: 50,
			render: (text, record) => (

				selection?.id !== record.id ? <Tooltip title={t("actions.view")} placement='left'>
					<Button
						shape="circle"
						className={selection && selection.__typename === "Risk" ? "discreet" : ""}
						onClick={() => props.select(record)}
					>
						<FontAwesomeIcon
							icon={faChevronRight}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial'
							}}
						/>
					</Button>
				</Tooltip> : <Tooltip title={t("actions.close")} placement='left'>
					<Button
						shape="circle"
						onClick={() => props.deselect()}
					>
						<FontAwesomeIcon
							icon={faTimes}
							style={{
								cursor: props.disabled
									? 'not-allowed'
									: 'initial',
								opacity: props.disabled ? 0.5 : 1
							}}
						/>
					</Button>
				</Tooltip>

			)
		}
	];

	return (
		<div className="Risks pb-1">
			<HeaderPortal>{props.portalContent} </HeaderPortal>

			<div className="Body pr-2">

				<div className="content h-100 pb-1 pt-2 pr-1 pl-1">
					<Tabs
						type="card"
						className="m-auto w-100"
						style={{ maxWidth: '1200px' }}
						activeKey={location.pathname?.includes("opportunities") ? "opportunities" : location.pathname?.includes("summary") ? "summary" : "risks"}
						onChange={(key) => {
							goToRisks(key)
						}}
						items={[
							{
								key: "risks",
								label: t("models.risk.name_plural"),
								children: (
									<>
										<div style={{ height: "25px" }}>
											{
												location?.state?.riskFilter && (
													<Tag
														className="mb-3"
														color={'#FFFFFF00'}
														style={{
															color: '#FFFFFF',
															border: '0.5px solid #FFFFFF',
														}}
														onClick={() => goToRisks(isRiskMode ? 'risks' : 'opportunities')}
													>
														<FontAwesomeIcon icon={faTimes} style={{ marginRight: "0.5rem" }}></FontAwesomeIcon>
														{isRiskMode ? "Risk" : "Opportunity"}: {filteredElements[0]?.name || 'Unknown'}
													</Tag>
												)
											}
										</div>
										<div
											className="mb-3 w-100"
											style={{
												maxWidth: '1200px',
												overflowY: 'auto',
												maxHeight: 'calc(100vh - 372px)',
											}}
										>
											<Table
												pagination={false}
												dataSource={sortBy(filteredElements, "name")}
												columns={columns}
												size="small"
												rowKey="id"
												className="mr-1"
											/>
										</div>
										<div className="w-100 d-flex justify-content-center align-items-center">
											<div
												className="w-100 d-flex justify-content-center align-items-center"
											>
												<AddButton
													popover={
														<>
															Add a risk
														</>
													}
													onClick={handleCreateRisk}
												></AddButton>
											</div>
										</div>
									</>
								)
							},
							{
								key: "opportunities",
								label: t("models.risk.name_plural_positive"),
								children: (
									<>
										<div style={{ height: "25px" }}>
											{
												location?.state?.riskFilter && (
													<Tag
														className="mb-3"
														color={'#FFFFFF00'}
														style={{
															color: '#FFFFFF',
															border: '0.5px solid #FFFFFF',
														}}
														onClick={() => goToRisks(isRiskMode ? 'risks' : 'opportunities')}
													>
														<FontAwesomeIcon icon={faTimes} style={{ marginRight: "0.5rem" }}></FontAwesomeIcon>
														{isRiskMode ? "Risk" : "Opportunity"}: {filteredElements[0]?.name || 'Unknown'}
													</Tag>
												)
											}
										</div>
										<div
											className="mb-3 w-100"
											style={{
												maxWidth: '1200px',
												overflowY: 'auto',
												maxHeight: 'calc(100vh - 372px)',
											}}
										>
											<Table
												pagination={false}
												dataSource={sortBy(filteredElements, "name")}
												columns={columns}
												size="small"
												rowKey="id"
												className="mr-1"
											/>
										</div>
										<div className="w-100 d-flex justify-content-center align-items-center">
											<div
												className="w-100 d-flex justify-content-center align-items-center"
											>
												<AddButton
													popover={
														<>
															Add a risk
														</>
													}
													onClick={handleCreateRisk}
												></AddButton>
											</div>
										</div>
									</>
								)
							},
							{
								key: "summary",
								label: "Visualization",
								children: (
									<div style={{
										margin: 'auto',
										height: '700px',
										width: '100%'
									}}>
										<RiskScatterFullChart
											isRiskView={isRiskMode}
											projectId={params.id}
											onRiskClick={(risk) => {
												// Edit risk
												setEditedItem(risk);
											}}
											likelihoods={indexedLikelihoods}
											impacts={indexedImpacts}
											style={{
												margin: 'auto',
												height: '700px',
												width: '100%',
												maxWidth: '1200px'
											}}
											width={1200}
											height={700}
											onSelect={props.select}
											selection={selection}
											risks={filteredRisks}
											opportunities={filteredOpportunities}
											indicators={indicators}
											stakeholders={stakeholders}
											scenario={props.scenario}
											showAllLinks={areLinksVisible}
											showStakes={showStakes}
											showRisks={showRisks}
											showOpportunities={showOpportunities}
											showStakeholders={showStakeholders}
										/>
									</div>
								)
							}
						]}
					/>
				</div>
			</div>

			<SideMenu defaultTab={selection?.__typename && selectableElementTypes.includes(selection?.__typename) ? "general" : "options"} tabs={[
				selection?.__typename && selectableElementTypes.includes(selection?.__typename) && {
					id: 'general',
					title: t('pages.risks.tabs.general.title'),
				},
				!selection && {
					id: 'options',
					title: t('pages.risks.tabs.options.title')
				},
				selection?.__typename == "Risk" && {
					id: 'stakeholders',
					title: t('pages.risks.tabs.stakeholders.title')
				},
				!selection && {
					id: 'bot',
					title: t('pages.navigator.tabs.bot.title')
				},
				{
					id: 'export',
					title: t('pages.navigator.tabs.export.title')
				}
			]}>
				{function (selectedTab) {

					if (selectedTab == "export")
						return <ExportTab risks={risks} riskCategories={riskCategories} height={700 + 100}>

						</ExportTab>

					return <>
						{(!selection || (selection?.__typename != "Risk" && selection?.__typename != "Indicator" && selection?.__typename != "Stakeholder")) && (selectedTab !== "options" && selectedTab !== "bot") && <FiltersTab
							displayColumns={!location.pathname?.includes('summary')}
							setSearchTerm={props.setSearchTerm}
							searchTerm={props.searchTerm}

							categories={allCategories}
							categoryFilter={categoryFilter}
							setCategoryFilter={setCategoryFilter}

							impacts={impacts}
							setImpactFilter={setImpactFilter}
							impactFilter={impactFilter}

							likelihoods={likelihoods}
							setLikelihoodFilter={setLikelihoodFilter}
							likelihoodFilter={likelihoodFilter}

							tags={allTags}
							tagFilter={tagFilter}
							setTagFilter={setTagFilter}
							usedTags={usedTags}

							indicators={allIndicators}
							indicatorFilter={indicatorFilter}
							setIndicatorFilter={setIndicatorFilter}

							stakeholders={allStakeholders}
							stakeholderFilter={stakeholderFilter}
							setStakeholderFilter={setStakeholderFilter}
						></FiltersTab>}

						{(!selection || (selection?.__typename != "Risk" && selection?.__typename != "Indicator" && selection?.__typename != "Stakeholder")) && selectedTab == "options" && <OptionsTab
							preferences={props.preferences?.pages?.risks}
							setPreferences={(key, value) => {
								props.setPreferences("pages", "risks." + key, value)
							}}
						></OptionsTab>}

						{(!selection || (selection?.__typename != "Risk" && selection?.__typename != "Indicator" && selection?.__typename != "Stakeholder")) && selectedTab == "bot" && <>
							<BotTab
								consent={props.aiConsent}
								projectId={params.id}
								messages={props.botThread?.messages || []}
								onResetThread={props.resetBotThread}
								onSendMessage={(message, callback) => props.addBotMessage(params.id, props.aiConsent === "FULL" ? props.scenario?.id : null, "risks", message, callback)}
							>

							</BotTab></>}

						{(selection && selection?.__typename === "Risk" && selectedTab !== "stakeholders") && <RiskGeneralTab

							scenario={props.scenario}
							selection={selection ? getItemAlteredByScenario(selection, props.scenario) : null}
							onEdit={() => {
								setEditedItem(selection)
							}}
							key={"general" + selection.id}
							categories={allCategories}
							likelihoods={indexedLikelihoods}
							impacts={indexedImpacts}
							opportunities={filteredOpportunities}
							onClose={() => { props.deselect() }}
							onSetRiskOpportunity={(riskId, matchingOpportunityId) => {
								updateRisk(
									riskId,
									risks,
									null,
									{ matchingOpportunityId }
								);
							}}
							onDelete={() => {

								if (selection) {
									askDeleteConfirmation(selection?.id);
								}
							}}

							indicators={props.indicators}
							createIndicatorImpact={async (a, b, c, d, e, f) => {
								let newImpact = await createIndicatorImpact(a, b, c, d, e, f);
								props.select({
									...selection,
									impactedIndicators: [
										...selection.impactedIndicators,
										newImpact
									]
								})
							}}
							updateImpactStrength={async (impact, strength) => {
								await updateIndicatorImpactStrength(impact, strength);

								props.select({
									...selection,
									impactedIndicators: selection?.impactedIndicators?.map(ii => {
										if (ii.id == impact.id) {
											return {
												...ii,
												strength
											}
										} else {
											return {
												...ii
											}

										}
									})
								})
							}}
							deleteIndicatorImpact={(id, riskId) => {
								deleteIndicatorImpact(id, riskId);
								props.select({
									...selection,
									impactedIndicators: selection?.impactedIndicators?.filter(ii => ii.id != id)

								})
							}}
						></RiskGeneralTab>}

						{(selection && selection?.__typename === "Indicator") && <IndicatorGeneralTab
							scenario={props.scenario}
							selection={selection}
							onEdit={() => {
								setEditedItem(selection)
							}}
							risks={risks}
							key={"general" + selection.id}
							categories={allCategories}
							onClose={() => { props.deselect() }}
							onDelete={() => {
								if (selection) {
									askDeleteConfirmation(selection?.id);
								}
							}}

							indicators={props.indicators}
							createIndicatorImpact={async (riskId, indicatorId) => {
								let newImpact = await createIndicatorImpact(riskId, indicatorId);
								props.select({
									...selection
								})
							}}
							updateImpactStrength={async (impact, strength) => {
								await updateIndicatorImpactStrength(impact, strength);

								props.select({
									...selection
								})
							}}
							deleteIndicatorImpact={async (id, riskId) => {
								await deleteIndicatorImpact(id, riskId);
								props.select({
									...selection

								})
							}}
						></IndicatorGeneralTab>}

						{(selection && selection?.__typename === "Stakeholder") && <GeneralTab

							displayTitle={true}
							displayTabs={false}
							selection={selection}
							indicators={indicators}
							scenario={props.scenario}
							displayIndicators={true}
							stakeholders={stakeholders}
							indexedStakeholders={indexedStakeholders}

							risks={risks}
							key={"general" + selection.id}
							categories={allCategories}
							onClose={() => { props.deselect() }}

							preferences={props.preferences?.pages?.risks}

							createIndicatorImpact={async (riskId, indicatorId) => {
								let newImpact = await createIndicatorImpact(riskId, indicatorId);
								props.select({
									...selection
								})
							}}
							updateImpactStrength={async (impact, strength) => {
								await updateIndicatorImpactStrength(impact, strength);

								props.select({
									...selection
								})
							}}
							deleteIndicatorImpact={async (id, riskId) => {
								await deleteIndicatorImpact(id, riskId);
								props.select({
									...selection

								})
							}}

							areIndicatorsVisible={true}
							areConstraintsVisible={false}
							areRisksVisible={true}
							areOpportunitiesVisible={true}
						></GeneralTab>}

						{(selection && selection?.__typename === "Risk" && selectedTab === "stakeholders") &&
							<StakeholdersTab
								selection={selection}
								stakeholders={stakeholders}
								onClose={() => { props.deselect() }}

								createRiskImpact={async (a, b, c, d, e, f) => {
									let newImpact = await createRiskImpact(a, b, c, d, e, f);
									props.select({
										...selection
									})
								}}
								updateImpactStrength={async (impact, strength) => {
									await updateRiskImpactStrength(impact, strength);

									props.select({
										...selection
									})
								}}
								deleteRiskImpact={(id, riskId) => {
									deleteRiskImpact(id, riskId);
									props.select({
										...selection
									})
								}}
							>

							</StakeholdersTab>}

					</>
				}}
			</SideMenu>

			{
				editedItem && (
					<RiskEditionModal
						onHide={() => {
							setEditedItem(null);
						}}
						onDelete={() => {
							askDeleteConfirmation(editedItem.id);
							setEditedItem(null);
						}}
						onSave={async (values: Object) => {
							await updateRisk(
								editedItem.id,
								risks,
								props.scenario,
								values
							);
							setEditedItem(null);

						}}
						tags={props.tags}
						id={editedItem?.id}
						likelihoods={likelihoods || []}
						impacts={impacts || []}
						values={{
							name: editedItem?.name,
							reference: editedItem?.reference,
							impact: editedItem?.impact || 1,
							likelihood: editedItem?.likelihood || 1,
							description: editedItem?.description || '',
							riskCategoryId: editedItem?.riskCategoryId,
							isOpportunity: editedItem?.isOpportunity || false,
							matchingOpportunityId: editedItem?.matchingOpportunityId || null,
							taggings: editedItem?.taggings?.map(t => t.tagId) || [],
							evaluatedAt: editedItem?.evaluatedAt ? dayjs(editedItem?.evaluatedAt) : null,
							impactedIndicators: (editedItem?.impactedIndicators || []).map(ii => ii.indicatorId),
						}}
						riskCategories={riskCategories}
						risks={risks.filter(r => r.id != editedItem?.id)}
						indicators={indicators}
					></RiskEditionModal>
				)
			}
		</div >
	);
};
